import { createSlice } from '@reduxjs/toolkit'


const registration_step = {
      otp_verify: false,
      otp_verify_email: false,
      update_region: false,
      update_categories: false,
      update_email_preferences: false,
      mobile_number_validation : null,
      existing_register_number : ""
}
const initialState = {
      ct_otp_modal_status:"",
      step : "step1",
      registration_step : registration_step,
      isRegister : false
}

const slice = createSlice({
      name: 'register',
      initialState: initialState,
      reducers: {
            updateRegistrationState(state, { payload }){
                  state.ct_otp_modal_status = payload.ct_otp_modal_status
                  state.step = payload.step
                  state.registration_step = payload.registration_step || registration_step
            },
            setRegistrationStep (state, { payload }) {
                  state.registration_step = payload.registration_step
            },
            updateMobileNumberValidation (state, { payload }) {
                  state.mobile_number_validation = payload.mobile_number_validation
            },
            setExistingRegisterNumber (state, { payload }) {
                  state.existing_register_number = payload.existing_register_number
            },
            setIsRegister (state, { payload }) {
                  state.isRegister = payload.isRegister;
            },
            reset: ()=> initialState
           
      },
      
})



export const { updateRegistrationState, setRegistrationStep, setIsRegister, updateMobileNumberValidation, setExistingRegisterNumber, reset } = slice.actions

export default slice.reducer
