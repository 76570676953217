import React, { useEffect, useState } from 'react'

// external css
import "./GerFeedbackReport.css"

import ticketFaqBigHeading1Red from "../../assets/images/tickets/ticket-faq-big-heading1-red.svg";
import ticketFaqBigHeading1RedSm from "../../assets/images/tickets/ticket-faq-big-heading1-red-sm.svg";
import ticketAquamarine from "../../assets/images/tickets/ticket-current-listings-big-heading1-aquamarine.svg";
import ticketAquamarineSm from "../../assets/images/tickets/ticket-current-listings-big-heading1-aquamarine-sm.svg";
import ticketGreen from "../../assets/images/tickets/ticket-current-listings-big-heading1-green.svg";
import ticketGreenSm from "../../assets/images/tickets/ticket-current-listings-big-heading1-green-sm.svg";
import ticketYellow from "../../assets/images/tickets/ticket-current-listings-big-heading1-yellow.svg";
import ticketYellowSm from "../../assets/images/tickets/ticket-current-listings-big-heading1-yellow-sm.svg";
import ticketBlue from "../../assets/images/tickets/ticket-home-big-heading3-blue.svg";
import ticketBlueSm from "../../assets/images/tickets/ticket-home-big-heading3-blue-sm.svg";
import ticketgreen1 from "../../assets/images/tickets/ticket-uptake-venues-green2.svg";
// import ticketgreen1Sm from "../../assets/images/tickets/ticket-uptake-venues-green2-sm.svg";


// redux and react hooks
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// child component
import Footer from '../../Layouts/Footer/Footer'
import ArticalQuestions from './ArticalQuestions';
import ArticalQuestionsDummy from './ArticalQuestionsDummy';
import SliderForText from './SliderForText';
// packages
// Actions & RTK query or mutations
import { useLazyGetFeedbackReportQuery } from '../../Services/modules/Others';
import { setAlert, updateLocoScroll } from '../../Store/UI';
// Constants
import { errors, messages } from '../../resources/en';

let ResultArray={
      'q1':{'A':0,'B':0,"C":0,"D":0,"E":0,"F":0},
      'q2':{'A':0,'B':0,"C":0,"D":0,"E":0,"F":0,"G":0,"H":0,"I":0,"J":0,"K":0,"L":0,"M":0},
      'q3':{'A':0,'B':0,"C":0,"D":0,"E":0},
      'q4':{'A':0,'B':0,"C":0},
      'q5':{'A':0,'B':0,"C":0,"D":0,"E":0},
      'q6':{'A':0,'B':0,"C":0,"D":0,"E":0},
      'q8':{'A':0,'B':0,'C':0},
      'q9':"0",
};

const questionsList = [
      {key: "q1", "question" :  "Prior to attending this event, did you?", options  : [
                  {key : "A", opt:"Read a preview (i.e an article about the upcoming event)"},
                  {key : "B", opt:"Read a review by a professional critic"},
                  {key : "C", opt:"Read comments from friends or family members on social media"},
                  {key : "D", opt:"Hear about the event by word of mouth"},
                  {key : "E", opt:"See an advert promoting the event"},
                  {key : "F", opt:"I hadn't heard about it, I learned of it through Central Tickets"},
            ]
      },
      {
            key: "q2", 
            "question" :  "Select the reasons you chose to attend this event? (choose as many as you like) *", 
            options  : [
                  {key : "A", opt:"To be entertained"},
                  {key : "B", opt:"To spend quality time with family or friends"},
                  {key : "C", opt:"To energise your own creativity"},
                  {key : "D", opt:"To expose yourself or others to the arts"},
                  {key : "E", opt:"To enjoy the work of a specific composer or playwright?"},
                  {key : "F", opt:"To revisit a familiar story or style of event"},
                  {key : "G", opt:"see the work of a specific performer/event organiser"},
                  {key : "H", opt:"To experience a new type of event"},
                  {key : "I", opt:"To celebrate or observe your cultural heritage"},
                  {key : "J", opt:"To be emotionally moved or inspired"},
                  {key : "K", opt:"For work or educational purposes"},
                  {key : "L", opt:"For reasons of personal development"},
            ]
      },
      {
            key: "q3", 
            "question" :  "During the event, how strong was your emotional response to the subject matter?", 
            options  : [
                  {key : "A", opt:"Very strong"},
                  {key : "B", opt:"Quite strong"},
                  {key : "C", opt:"Neutral"},
                  {key : "D", opt:"Somewhat weak"},
                  {key : "E", opt:"Very Weak"},
                 
            ]
      },
      {
            key: "q4", 
            "question" :  "Afterwards, did you discuss the event with others who attended?", 
            options  : [
                  {key : "A", opt:"No"},
                  {key : "B", opt:"Yes - casually"},
                  {key : "C", opt:"Yes - actively"},
                 
            ]
      },
      {
            key: "q5", 
            "question" :  "How likely are you to recommend this event to people you know?", 
            options  : [
                  {key : "A", opt:"Very likely"},
                  {key : "B", opt:"Quite strongly"},
                  {key : "C", opt:"Neutral"},
                  {key : "D", opt:"Somewhat unlikely"},
                  {key : "E", opt:"Very unlikely"},
                 
            ]
      },
      {
            key: "q6", 
            "question" :  "Overall, how would you rate your experience at this event?", 
            options  : [
                  {key : "A", opt:"1 star"},
                  {key : "B", opt:"2 stars"},
                  {key : "C", opt:"3 stars"},
                  {key : "D", opt:"4 stars"},
                  {key : "E", opt:"5 stars"},
                 
            ]
      },
      {
            key: "q7"
      },
      {
            key: "q8", 
            "question" :  "Did you buy anything at the venue?", 
            options  : [
                  {key : "A", opt:"Yes"},
                  {key : "B", opt:"No"},
                  {key : "C", opt:"Not Applicable - Online Event"},
            ]
      },

]

const GetFeedbackReport = () => {

      
      
      const [state,setState]                    =     useState(ResultArray)
      const [scroll,setScroll]                  =     useState(false);

      const [eventMessage,setEventMessage]      =     useState([])
      const [spendPerHead,setSpendPerHead]      =     useState(0)
      const [spendCount,setSpendCount]          =     useState(0)
      const [feedbackCount,setFeedbackCount]    =     useState(0)
      const [load,setLoad]                      =     useState(false)
      
      const [getFeedback, { data, error, isSuccess }] = useLazyGetFeedbackReportQuery();

      const { feedbackId }                      =     useParams();
      const dispatch                            =     useDispatch()


      useEffect(()=>{
            getFeedback({booking_id:feedbackId});
      },[getFeedback,feedbackId])

      useEffect(()=>{
            if(scroll){
                  dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
                  setScroll(false)
            }
      },[scroll, dispatch])



      useEffect(()=>{

            if(isSuccess){
                  if(data.status === "1") {
                        feedbackReport(data.data)
                  } else {
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.message || data.error || errors.NotFound404 }))
                  }
            } 
            
            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404 }))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[data,error,isSuccess,dispatch])


      const feedbackReport = (cloneData) => {
            const dataArray = cloneData;
            const feedbackArray = dataArray.jsonarray;
            let ResultArray = { ...state };
        
            for (let i in feedbackArray) {
                  const feedBackIndependent = { ...feedbackArray[i] };
        
                  feedBackIndependent['q9'] = parseFloat(feedBackIndependent['q9']) || 0;
        
                  for (let j in feedBackIndependent) {
                        if (j === "q10") {
                              continue;
                        }
        
                        if (Array.isArray(feedBackIndependent[j])) {
                              const setMultiValue = feedBackIndependent[j];
        
                              for (let a in setMultiValue) {
                                    ResultArray = {
                                          ...ResultArray,
                                          [j]: {
                                                ...ResultArray[j],
                                                [setMultiValue[a]]: (ResultArray[j][setMultiValue[a]] || 0) + 1
                                          }
                                    };
                              }
                        } else {
                              if (j === 'q9') {
                                    ResultArray = {
                                          ...ResultArray,
                                          [j]: (parseFloat(ResultArray[j]) || 0) + (typeof feedBackIndependent[j] === "number" ? parseFloat(feedBackIndependent[j]) : 0)
                                    };
                              } else {
                                    ResultArray = {
                                          ...ResultArray,
                                          [j]: {
                                                ...ResultArray[j],
                                                [feedBackIndependent[j]]: (ResultArray[j][feedBackIndependent[j]] || 0) + 1
                                          }
                                    };
                              }
                        }
                  }
            }
        
            let countSpendQuestionResp;
            for (let i in ResultArray) {
                if (i !== "q9") {
                    let sumResponseOfKey = countQ1Occurrences(feedbackArray, i);
                    ResultArray = {
                        ...ResultArray,
                        [i]: {
                            ...ResultArray[i],
                            sum: sumValues(ResultArray[i]),
                            response: sumResponseOfKey
                        }
                    };
                } else {
                    countSpendQuestionResp = countSpendQuestion(feedbackArray, i);
                }
            }
        
            setState(prevState => ({
                ...prevState,
                ...ResultArray
            }));
        
            setEventMessage(prevState => ([
                ...dataArray.messages
            ]));
        
            setFeedbackCount(dataArray.feedback_count);
        
            setSpendPerHead(prevState => (
                dataArray.spent_per_head
            ));
            setSpendCount(countSpendQuestionResp);
            setLoad(true);
            setScroll(true);
        }
        


      const countQ1Occurrences = (array, key) => {
            return array.reduce((count, obj) => {
                if (obj[key] !== undefined) {
                    count++;
                }
                return count;
            }, 0);
        };

      const countSpendQuestion = (array, i) => {
            return array.reduce((count, obj) => {
                if (obj[i] !== undefined) {
                    count++;
                }
                return count;
            }, 0);
      };


      function sumValues(obj) {
            let sum = 0;
            for (let key in obj) {
                if (obj.hasOwnProperty(key)) {
                    sum += obj[key];
                }
            }
            return sum;
      }

      return (
            <div className="scroll-animations position-relative" data-scroll-container>
                  <main>
                        <section data-scroll-section className="section__feedback-report py-0">
                             
                              <div className="container-fluid container__block container__feedback-report">
                                    <div className="row">
                                          <div className="col-md-12">
                                                <div className="content content__feedback-report mx-auto">
                                                      <div className="content__header content__header--template text-center overflow-hidden">
                                                            <div className="big-heading">
                                                                  <h1 className="h2 mb-0">Member <br/> Feedback</h1>
                                                                  
                                                                  <picture>
                                                                        <source media="(min-width: 992px)" srcSet={ticketFaqBigHeading1Red}/>
                                                                        <img data-scroll="" className="ticket ticket-red" src={ticketFaqBigHeading1RedSm} alt=""/>
                                                                  </picture>
                                                                  <picture>
                                                                        <source media="(min-width: 992px)" srcSet={ticketAquamarine}/>
                                                                        <img data-scroll="" className="ticket ticket-aquamarine" src={ticketAquamarineSm} alt=""/>
                                                                  </picture>
                                                                  <picture>
                                                                        <source media="(min-width: 992px)" srcSet={ticketGreen}/>
                                                                        <img data-scroll="" className="ticket ticket-green" src={ticketGreenSm} alt=""/>
                                                                  </picture>
                                                                  <picture>
                                                                        <source media="(min-width: 992px)" srcSet={ticketYellow}/>
                                                                        <img data-scroll="" className="ticket ticket-yellow" src={ticketYellowSm} alt=""/>
                                                                  </picture>

                                                                  <picture>
                                                                        <source media="(min-width: 992px)" srcSet={ticketBlue}/>
                                                                        <img data-scroll="" className="ticket ticket-blue" src={ticketBlueSm} alt=""/>
                                                                  </picture>

                                                                  <picture>
                                                                        <source media="(min-width: 992px)" srcSet={ticketgreen1}/>
                                                                        <img data-scroll="" className="ticket ticket-green1" src={ticketgreen1} alt=""/>
                                                                  </picture>
                                                                 
                                                            </div>
                                                            {load ? <h2>A Neuroscientist's Guide To Positive Change</h2> : 
                                                            <h2 className='dummy'></h2>}
                                                            {load && <h3 className="header_subtitle">{feedbackCount} {feedbackCount > 1 ? "responses" : "response"} </h3>}
                                                            {!load && <h3 className="header_subtitle dummy"></h3>}
                                                      </div>

                                                      <div className="main_body">
                                                            <div className="report_section">
                                                                  {load && questionsList.map((el, index)=> {
                                                                        if(el.key !== "q7"){
                                                                              return <ArticalQuestions key={`${el.key} ${index} ${el.question} ${el.options.length}`} element={el} state={state[el.key]} feedbackCount={feedbackCount}/>
                                                                        } else {
                                                                              return  (
                                                                                    <SliderForText slides={eventMessage}/> 
                                                                              )
                                                                        }
                                                                  })}
                                                                  {!load && <>
                                                                        <ArticalQuestionsDummy />
                                                                        <ArticalQuestionsDummy />  
                                                                        </>
                                                                  }
                                                                  {load && <article className="report_article">
                                                                        <div className="ct_report_title">
                                                                              <div className="ct_report_sub_title">
                                                                                    <h2 className="ct_rt_text"><strong>Average spend in total?</strong> </h2>
                                                                                    <h3 className="ct_rt_sub_text"><span className="out_of">{spendCount}</span> <span>out of <span className="out_of">{feedbackCount}</span> answered</span></h3>
                                                                              </div>
                                                                        </div>
                                                                        <div className="ct-chart">
                                                                              <div className="ct-chart-section setval_q8">
                                                                                    <div className="ct_bar">
                                                                                          <div className="ct_bar_section">£</div>
                                                                                          <mark className="ct_report_question" style={{"position": "absolute","marginLeft": "60px","marginTop": "11px"}}>£{parseFloat(spendPerHead ? parseFloat(spendPerHead) : 0)} spent per ticket booked</mark>
                                                                                          <div className="ct_percentage" style={{ color: "white"}}>
                                                                                                <span className="per_q8_A">0%</span> 
                                                                                                <mark className="ct_report_question_sub" style={{ color: "white"}}> 
                                                                                                      / <span className="total_review  review_q8_A">0</span> resp.
                                                                                                </mark>
                                                                                          </div>
                                                                                    </div>
                                                                              </div>
                                                                        </div>
                                                                  </article>}
                                                            </div>
                                                      </div>
                                                     
                                                </div>
                                          </div>
                                    </div>
                              </div>

                        </section>
                  </main>

                  <Footer />
            </div>
      )
}

export default GetFeedbackReport
