import React, { useEffect } from 'react';

// external css

// redux and react hooks
import { Link, useLocation, useNavigate  } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// child component
import SectionHomeHearo from "./HomeHearo/SectionHomeHearo.js";
import SectionWhatWeDo from "./WhatWeDo/SectionWhatWeDo.js";
import TicketBookingSteps from "./TicketBookingSteps/TicketBookingSteps.js"
import ShowsTicker from "./showTicker";
import Footer from "../../Layouts/Footer/Footer";

// packages
import { TickerText } from '../../Components';
import { Helmet } from 'react-helmet';

// Actions & RTK query or mutations
import { useVerifyEmailMutation } from "./../../Services/modules/users";
import { setAlert } from '../../Store/UI/index.js';

// Constants
import { errors, messages } from '../../resources/en.js';
import { routesName } from '../../resources/RoutesName.js';
import { catsOneText, catsTwoText, locText } from '../../resources/TextRollerConstants';
import { MetaData } from '../../resources/MetaData.js';

const Home = () => {

      const { pathname }           =     useLocation();
      const dispatch               =     useDispatch();
      const navigate               =     useNavigate();

      const [verifyEmail,{ data , isSuccess, isLoading , isFetching , error  }]  =    useVerifyEmailMutation();

      useEffect(()=>{
            document.title = "Home";
            navigate('',{ replace : true})
            
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

      // Verify Email If Pathname Contain verifyEmail
      useEffect(()=>{
            if(pathname.includes("verifyEmail")) {
                  const verification_code = pathname.split("/")[2];
                  verifyEmail({verification_code:verification_code})
            }

      },[pathname,verifyEmail])

      
      // Verify Email OTP Using appVerifyEmail - Handle API response
      useEffect(()=>{

            if(isSuccess){
                  if(data.status === "1" || data.status === "success"){
                        dispatch(setAlert({model:true,title:messages.modalSuccessTitle,message: data.message || errors.passwordUpdated}));
                  } else if(data.status === "0" || data.status === "error"){
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.message || errors.NotFound404}));
                  }
            }
            
            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}));
            }

      },[data , isSuccess, isLoading , isFetching , error , dispatch])
      
      return (
            
            <React.Fragment>
                  {/* <!-- REQUIRED wrapper div for parallax --> */}
                  <div className="scroll-animations position-relative" data-scroll-container>
                        <Helmet>
                              <title>{MetaData.Home.title}</title>
                              <meta name="description" content={MetaData.Home.description} />
                              <meta name="keywords" content={MetaData.Home.keywords} />
                        </Helmet>
                        <main className="main__home trustpilot-home-header">
                              {/* <div data-scroll-section className="container-fluid container__notification container__notification--alert d-flex align-items-center justify-content-center">
                                    <div data-scroll>
                                          <div className="fw-bold sticky-header-link">
                                                25% free wallet credit with first top up (up to £20)
                                          </div>
                                    </div>
                              </div> */}
          
                              <SectionHomeHearo/>
                              {/* <!-- /.section__home-hero --> */}
          
                              <div data-scroll-section className="border-bottom">
                                    <TickerText id="tickerShowCatsOne" textData={catsOneText}/>
                                    
                                    {/* <!-- /.ticker --> */}
                              </div>
          
                              <SectionWhatWeDo/>
                              {/* <!-- /.section__what-we-do --> */}
          
                              <TicketBookingSteps/>
                              {/* <!-- /.section__ticket-booking-steps --> */}
          
                              <div data-scroll-section className="border-top py-0">
                                    <TickerText id="tickerLoc" textData={locText}/>
                                    {/* <!-- /.ticker --> */}
                              </div>

                              <ShowsTicker />
                              {/* <!-- /.showsTicker --> */}
          
                              <div data-scroll-section className="section__cta section__cta--huge bg-red border-bottom">
                                    <div className="container-fluid container__block">
                                          <div className="row">
                                                <div className="col-md-12">
                                                      <div className="data-scroll-wrapper">
                                                            <Link data-scroll className="btn btn-huge btn-outline-primary btn-outline-white" to={routesName.Register}>
                                                                  Join us
                                                            </Link>
                                                      </div>
                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}
                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </div>
                              {/* <!-- /.section__cta --> */}
          
                              <div data-scroll-section className="py-0 border-bottom">
                                    <TickerText id="tickerShowCatsTwo" textData={catsTwoText}/>
                                   
                                    {/* <!-- /.ticker --> */}
                              </div>
                        </main>

                        <Footer/>
                  </div>
                  {/* <!-- /div[data-scroll-container] --> */}
            </React.Fragment>
      )
}

export default Home;
