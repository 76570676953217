import React, { useEffect, useState } from  'react';

// external css
// redux and react hooks
import { useDispatch } from 'react-redux';

// child component
import { HeadingPictureComponent, CurrentListingItem, TickerText, CurrentListingItemDummy } from '../../Components';
import Footer from "../../Layouts/Footer/Footer";

// packages
import { Helmet } from 'react-helmet';

// Actions & RTK query or mutations
import { useGetCurrentListingMutation } from '../../Services/modules/currentlisting';
import { setAlert, updateLocoScroll } from '../../Store/UI';

// Constants
import { errors, messages } from '../../resources/en';
import { catsOneText, likedShowsText } from '../../resources/TextRollerConstants';
import { Link } from 'react-router-dom';
import { routesName } from '../../resources/RoutesName';
import { MetaData } from '../../resources/MetaData';

const MyFavourites = () => {

      const [scroll,setScroll]      =     useState(false);
      const dispatch                =     useDispatch();
      const [fetchAllFavorites, { data, isSuccess, isLoading, isFetching, error }]  =    useGetCurrentListingMutation();

      useEffect(()=>{
            fetchAllFavorites({favorite:true})
      },[fetchAllFavorites])

      useEffect(()=>{
            document.title = "My Favourites";
      },[])

      useEffect(()=>{
            if(scroll){
                  dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
                  setScroll(false)
            }
      },[scroll, dispatch])

      useEffect(()=>{
            if(isSuccess){
                  if(data.status === "1"){
                        // dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
                        
                        // dispatch(setEventsData(data))
                  } else {
                        if(data.data.length === 0){
                              setScroll(true);
                        }
                  }
            }
            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || errors.NotFound404}))
            } 

      },[data, isSuccess, isLoading, isFetching, error, dispatch ])

      const reFetchFavourites = ()=>{
            fetchAllFavorites({favorite:true})
      }

      const afterLoadHandler = ()=>{
            dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
      }
    
      return (
                  <div className={["scroll-animations position-relative"].join(" ")} data-scroll-container>
                        <Helmet>
                              <title>{MetaData.Favourites.title}</title>
                              <meta name="description" content={MetaData.Favourites.description} />
                              <meta name="keywords" content={MetaData.Favourites.keywords} />
                        </Helmet>
                        <main>
                              <section data-scroll-section className="section__my-favourites py-0">
                                    <div className="container-fluid container__block">
                                          <div className="row">
                                                <div className="col-md-12">
                                                      <div className="content content__my-favourites mx-auto">
                                                            <div className="content__header content__header--template border-bottom text-center overflow-hidden">
                                                                  <div className="big-heading">
                                                                        <h1 className="mb-0">Your Favourites</h1>
                                                                        <HeadingPictureComponent 
                                                                        dataScroll
                                                                        className="ticket-aquamarine"
                                                                        srcSet="ticket-my-favourites-big-heading1-aquamarine.svg"
                                                                        src="ticket-my-favourites-big-heading1-blue-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent 
                                                                        dataScroll
                                                                        className="ticket-red"
                                                                        srcSet="ticket-my-favourites-big-heading1-red.svg"
                                                                        src="ticket-my-favourites-big-heading1-red-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent 
                                                                        dataScroll
                                                                        className="ticket-green"
                                                                        srcSet="ticket-my-favourites-big-heading1-green.svg"
                                                                        src="ticket-my-favourites-big-heading1-green-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent 
                                                                        dataScroll
                                                                        className="ticket-blue"
                                                                        srcSet="ticket-my-favourites-big-heading1-blue.svg"
                                                                        src="ticket-my-favourites-big-heading1-aquamarine-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent 
                                                                        dataScroll
                                                                        className="ticket-orange"
                                                                        srcSet="ticket-my-favourites-big-heading1-orange.svg"
                                                                        src="ticket-my-favourites-big-heading1-yellow-sm.svg"
                                                                        />
                                                                       
                                                                  </div>
                                                            </div>
                                                            {/* <!-- .content__header --> */}

                                                            <div className="content__body">

                                                                  <div className="d-none d-lg-block">
                                                                        <TickerText id="tickerLikedShows" textData={likedShowsText}/>
                                                                        {/* <!-- /.ticker --> */}
                                                                  </div>

                                                                  <div className="current-listings flex-md-row align-items-md-stretch flex-md-wrap two-cols-md three-cols-xl" >
                                                                        {!data && 
                                                                        <>
                                                                              <CurrentListingItemDummy/>
                                                                              <CurrentListingItemDummy/>
                                                                              <CurrentListingItemDummy/>
                                                                        </>}
                                                                        {
                                                                              (isSuccess && data.data.length > 0) && data.data.map((item,index)=>{
                                                                                    return (
                                                                                          <CurrentListingItem key={item.id} {...item} type="fav"
                                                                                          is_favorite={true} 
                                                                                          reFetchFavourites={reFetchFavourites}
                                                                                          afterLoad={afterLoadHandler}
                                                                                          />
                                                                                    )
                                                                              })
                                                                        }
                                                                        {(isSuccess && !isLoading && !isFetching && data.data.length === 0) && <div className='section__no-my-favourites'>
                                                                              <h1>No favourites added. <br/>Click <Link to={routesName.CurrentListing}>here</Link> for listings.</h1>
                                                                        </div>}
                                                                  </div>
                                                                  {/* <!-- /.current-listings --> */}
                                                            </div>
                                                            {/* <!-- .content__body --> */}
                                                      </div>
                                                      {/* <!-- .content.content__my-favourites --> */}

                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}
                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>
                              {/* <!-- /.section__my-favourites --> */}

                              <div data-scroll-section>
                                    <TickerText id="tickerShowCatsOne" textData={catsOneText}/>
                              </div>

                        </main>

                        <Footer/>
                  </div>
      )
}

export default MyFavourites;
