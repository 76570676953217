export const MetaData = {
      About : {
            title : "About Central Tickets | London | Central Tickets",
            keywords : "About Us, Central Tickets, Events, Concerts, Shows, Theatre, Music, Performances, Social Impact, Working in support of NHS Charities Together, Seat-filling agency, papering agency, audience agency, seat-fillers, seat-filling, cheap theatre tickets, London theatre, seat-fillers in London, theatre enthusiasts, discounted tickets, London shows, theatre lovers, affordable entertainment, live performances, ticket bargains, Central Tickets, theatre seat-fillers, theatre ticket discounts, ticket deals, cultural events, reliable, trusted, secure, social impact, social responsibility, climate change crisis, supporting the NHS",
            description : "Learn about our company history, values, commitment to supporting the NHS and social responsibility as a climate positive seat-filling agency based in London"
      },
      AccessBooking : {
            title : "Access Booking Information | London | Central Tickets",
            keywords : "disabled access, theatre ticket bookings, accessibility needs, access needs, inclusive theatre, hearing impaired, visually impaired",
            description : "Central Tickets is totally inclusive and welcomes access bookings.  Our team will reach out to venues to make sure that your access needs can be accommodated."
      },
      Contact : {
            title : "Contact Us | London | Central Tickets",
            keywords : "Contact us, Contact information, Get in touch, Reach out, Customer support, Contact details, Contact form, Contact page, Contact us today",
            description : "Contact us for any inquiries or assistance. Our dedicated team is here to help. Reach out today via our contact form and our team will get back to you asap."
      },
      Shows : {
            title : "All Show Listings | London | Central Tickets",
            keywords : "seat-filling opportunities, discounted tickets, movie tickets, classical music concerts, music concerts, comedy shows, theatre plays, musicals, West End theatre, cabaret shows, talks, guided walks, London events, seat-fillers, cheap tickets, affordable prices, last-minute deals, discounted entertainment, London shows, theatre discounts, concert tickets.",
            description : "Discover exciting events and book tickets easily. Explore a wide range of shows, concerts, and performances. Explore new venues shows for £4-15 per ticket."
      },
      Book : {
            title : "Book Tickets | London | Central Tickets",
            keywords : "Booking widget, book Seat-filling tickets, availability calendar, performance dates and times, promo codes, charity donations via pennies, flexi-ticket options, ticket types, booking summary, Book show tickets, Reserve seats, Online ticket booking, Book event tickets, London seat fillers, Affordable seats, London show tickets",
            description : "Book show tickets. Pay securely with debit/credit card, wallet credit, or Apple Pay.  Streamlined ticket reservations and optional flexi-ticket add-ons."
      },
      Faqs : {
            title : "FAQs | London | Central Tickets",
            keywords : "FAQs, Frequently asked questions, Help, Support, Troubleshooting, Common issues, Solutions, Tips, Problems, Answers, Booking enquiries, queries about bookings, how to filter listings, access needs bookings, login support, admin fee questions, online events and recordings, account queries, comments and suggestions",
            description : "Find answers to your questions about seat-filling on our FAQs page. Discover information on managing bookings, seating, access bookings, refunds and more. "
      },
      FlexiTerms : {
            title : "Flexi Terms & Conditions | London | Central Tickets",
            keywords : "Flexi-theatre tickets, Flexi-booking premium, terms and conditions, ticketing policies, ticket purchase, refund policy, wallet credit refunds, cancellation policy, notice period requirements, terms of use, booking terms, terms agreement, terms and conditions, ticket terms of use, ticketing terms, ticketing guidelines.",
            description : "Explore our flexi-ticket booking add-on for extra flexibility around cancellations. Learn about our Terms and Conditions and account credit refund policy."
      },
      Home : {
            title : "Home Page | London | Central Tickets",
            keywords : "Central Tickets, Seat-filler, Seat-filling opportunities, Cheap Theatre Tickets, Central Tickets App, Working in support of NHS Charities Together, supporting the NHS, Climate Positive, What we do, Social Impact, Social responsibility, Seat-filling services for event organisers, serving underserved groups, Membership information, Registration for Central Tickets, Creating an account, booking tickets, Seat-filling agency, papering agency, audience agency, seat-fillers, seat-filling, cheap theatre tickets, London theatre, seat-fillers in London, theatre enthusiasts, discounted tickets, London shows, theatre lovers, affordable entertainment, live performances,",
            description : "Discover exclusive shows in London with free membership as a seat-filler. Register now and enjoy affordable seat-filling tickets ranging from £4 to £15 "
      },
      AccountStatus : {
            title : "Account Status | London | Central Tickets",
            keywords : "Account paused, account blocked, account suspended, temporarily paused",
            description : "Your account has been temporarily paused by the administrator. Please contact our support team for further assistance and they'll be happy to assist you."
      },
      LeaveFeedback : {
            title : "Audience Feedback | London | Central Tickets",
            keywords : "feedback, reviews, show feedback, audience opinions, show ratings, user testimonials, audience feedback, performance reviews, event feedback, feedback opportunity, audience response, show recommendations",
            description : "Share your thoughts on the shows you've attended! Leave valuable feedback and reviews to help others discover events via Central. Your opinion matters!"
      },
      Guidelines : {
            title : "Membership Guidelines | London | Central Tickets",
            keywords : "Membership guidelines, code of conduct, things to to know about seat-fillling, Central Tickets rules, ticketing partner expecations, discretion required, account sharing policy, cancellation information, collecting tickets, refund policy, flexi-ticket information, personal conduct",
            description : "Read our membership guidelines to understand what we offer. Follow our guidelines to enjoy free membership, unlock discounts & priority access to show tickets. "
      },
      Account  : {
            title : "Member Account Area | London | Central Tickets",
            keywords : "Member Account area, Manage Bookings, Edit Contact Details, Theatre Ticket Bookings, Manage Bookings, Contact Preferences, View tickets, Ticket management, Current Bookings, Past Bookings, Download Tickets, Add Tickets, Reduce Tickets, Update Personal Details, Modify bookings, Account Settings.",
            description : "Easily manage current seat-filling bookings, view past bookings, manage account settings, email preferences and favourite shows from within your Account."
      },
      Bookings  : {
            title : "My Bookings | London | Central Tickets",
            keywords : "Current Bookings, Past Bookings, Add Tickets, Reduce Tickets, Manage Booking, View Collection Instructions, Download Booking Confirmation, My Bookings",
            description : "Manage current bookings by adding or reducing tickets, cancelling. bookings, or downloading booking confirmations and tickets. Leave feedback for past events."
      },
      Favourites  : {
            title : "My Favourites | London | Central Tickets",
            keywords : "Favourites, Favourited Events, Event tracker, Event calendar, Event schedule, Personalised events, Event wish-list, Saved events, Manage favourite events, Add to favourites, Event reminders, Event notifications, Upcoming events, Explore favourite events, Bookmarked events, Favourite live shows,",
            description : "Book tickets for shows you've marked as favourites for a personalised experienced of Central Tickets with all of your favourite shows in a separate area."
      },
      Wallet  : {
            title : "Credit Wallet | London | Central Tickets",
            keywords : "Credit wallet, Top-up credit, Prepaid credit, Account balance, Advance payment, Virtual wallet, Payment credit, Online wallet, Store credit, E-wallet, Account funds, Credit balance, Credit top-up, Future purchases, Prepaid balance, Deposit funds, Convenient payments, Instant top-up, Easy transactions, Secure credit storage, Flexible spending, Prepaid payment option",
            description : "You can save time checking out seat-filling bookings, by paying for your tickets with account credit, which you can top up at any time via your account wallet."
      },
      NewLook  : {
            title : "Central Tickets Rebrand | London | Central Tickets",
            keywords : "Rebranded website, New website design, Updated website style, Redesigned web page, Refreshed website look, Enhanced user experience, Modern website layout, Revamped website branding, Fresh website appearance, Improved visual design, Updated site aesthetics, Restyled website graphics, Streamlined website navigation, Updated colour scheme, New Logo, Contemporary website design, Upgraded website features, Revamped site structure, Enhanced website usability",
            description : "Take a look at our newly rebranded website for Central Tickets.  We've improved the design and enhanced the user experience so it's easier to navigate."
      },
      Error  : {
            title : "Error | London | Central Tickets",
            keywords : "Error page, Page not found, 404 error, Page cannot be loaded, Website error, Broken link, Missing page, Invalid URL, Site error, Page error, Unable to load page, Page not available, Broken URL, Site not found, Error 404, Invalid page, Page not located, Website issue, Broken webpage, Page error message, Missing content, Link not working, Broken navigation",
            description : "The page you have visited cannot be displayed.  It's possible it's an old link or that there's a problem at our end.  We're looking into it to resolve it asap."
      },
      PrivacyPolicy  : {
            title : "Privacy Policy| London | Central Tickets",
            keywords : "Privacy policy, Data protection, Personal information, User privacy, Confidentiality, Information security, Data collection, Data usage, Data storage, Data retention, Consent, Cookies, Tracking technologies, Third-party disclosure, Opt-out options, User rights, Privacy compliance, GDPR (General Data Protection Regulation), Data sharing, Privacy statement, Privacy practices, Data safeguards, Privacy controls, Privacy updates",
            description : "Your privacy matters to us. Read our privacy policy to understand how we protect your personal information. Trust and transparency are our top priorities."
      },
      Reactivate  : {
            title : "Reactivate Account | London | Central Tickets",
            keywords : "Account reactivation, Reactivate account, Resume account, Account reinstatement, Account reactivation process, Reactivation after pause, Account suspension, Reactivate suspended account, Account reactivation steps, Reactivate user account, Account reactivation instructions, Account reactivation request, Reactivation after administrator suspension, Resume access to account, Reactivation support, Account recovery, Reactivate inactive account, Account reactivation policy, Reactivate locked account, Account reactivation assistance, Reactivation after temporary pause, Reactivate blocked account, Account reactivation guidelines, Reactivate restricted account, Account reactivation option.",
            description : "Welcome back! Rediscover the benefits of membership by reactivating your account.  Reactivate your account today.  We look forward to having you back with us."
      },
      Referrals  : {
            title : "Refer Friends | London | Central Tickets",
            keywords : "Refer a friend, Recommend to friends, Referral program, Invite friends, Free membership, Earn wallet credit, Friend referral, Family referral, Share with friends, Register for free, Membership benefits, Wallet credit rewards, Referral rewards, Earn £12 credit, Recommend and earn, Invite and earn, Referral bonus, Join our community, Sign up and earn, Membership incentives, Refer for rewards, Recommend for savings, Free registration, Earn credit for referrals, Grow your wallet.",
            description : "Spread the word and invite your friends to join Central Tickets for free membership. Earn £12 of free wallet credit for every qualifying person who signs up."
      },
      Register  : {
            title : "Register for Membership | London | Central Tickets",
            keywords : "Seat-fillers, Join as a seat-filler, Free membership, Become a seat-filler, Seat-filling opportunities, Seat-Filler Registration, Seat-Filling Community, Seat-filling network, Join for free, Seat-filler benefits, Free seat-filling membership, Seat-filler vacancies, Seat-filler application, Seat-filler roles, Seat-filler gigs, Seat-filler events, Seat-filler perks, Seat-filler experience, Join the seat-filling community, Seat-filler registration process, Free seat-filler sign-up, Seat-filler opportunities near me, Seat-filling for shows/events, Seat-filler membership benefits.",
            description : "Join Central Tickets! Register using our convenient and secure registration form. Don't miss out on exciting seat-filling opportunities. Sign up today for free."
      },
      ResetPassword  : {
            title : "Reset Password | London | Central Tickets",
            keywords : "Reset password, Password recovery, Forgot password, Account security, Password reset form, Password recovery process, Reset password instructions, Secure password reset, Change password, Account access, Password update, Password recovery page, Password reset request, Account authentication, Reset password link, Password reset confirmation, Account recovery, Forgotten password, Reset password steps, Password management",
            description : "Forgot your password? No worries. Recover access to your account with our password reset page. Reset your password now so that you book seat-filling tickets."
      },
      TermsAndConditions  : {
            title : "Terms & Conditions | London | Central Tickets",
            keywords : "Theatre ticket terms and conditions, Ticket purchase terms, Terms of service for ticket sales, Ticketing terms and conditions, Terms of use for theatre ticket website, Ticket booking policies, Terms and conditions for ticket reservations, Theatre ticket sale agreement, Ticket refund and cancellation policies, Terms of ticket delivery, Ticket exchange policies, Terms and conditions for ticket transfers, Ticket usage guidelines, Terms of ticket availability, Theatre ticket pricing terms, Terms and conditions for seat selection, Ticket purchase eligibility requirements, Terms of ticket reselling, Privacy policy and data usage terms, Customer support and contact information.",
            description : "Understand the Terms & Conditions of Central Tickets. Read our guidelines to ensure a smooth experience. Stay informed and make the most of your membership."
      },
      Uptake  : {
            title : "Sales Uptake | London | Central Tickets",
            keywords : "Tickets allocated, Tickets booked, Ticket Sales, Audience Feedback, Waiting List, Waiting List Numbers, Ticket Inventory, Waiting List Updates, Uptake Figures, Tracking Link, Dynamic Updates, Track in real time",
            description : "Track uptake in real time, dynamically. Monitor sales, waiting list numbers, gather valuable audience feedback, and easily export provisional guest lists."
      },
      UptakeVenue  : {
            title : "Venue Uptake | London | Central Tickets",
            keywords : "All Shows at venue, Venue Sales, List of shows at the Venue, Tickets allocated, Tickets booked, Ticket Sales, Audience Feedback, Waiting List, Waiting List Numbers, Ticket Inventory, Waiting List Updates, Uptake Figures, Tracking Link, Dynamic Updates, Track in real time",
            description : "Track all shows for one venue, dynamically. Monitor sales, waiting list numbers, gather valuable audience feedback, and easily export provisional guest lists."
      }

}
