import React, { useEffect } from 'react';

// external css
// redux and react hooks
import { Link, useLocation } from 'react-router-dom';

// child component
import { HeadingPictureComponent } from '../../Components';
import { ImageComponent } from '../../Components';
import Footer from "../../Layouts/Footer/Footer";

// packages
import { Helmet } from 'react-helmet';
// Actions & RTK query or mutations
// Constants
import { routesName } from '../../resources/RoutesName';
import { MetaData } from '../../resources/MetaData';

const MembershipGuidelines = () => {

      const { pathname } = useLocation()

      useEffect(()=>{
            document.title = "Membership Guidelines";
      },[])

      return (
            
            <React.Fragment>
                  {/* <!-- REQUIRED wrapper div for parallax --> */}
                  <div className="scroll-animations position-relative" data-scroll-container>
                        <Helmet>
                              <title>{MetaData.Guidelines.title}</title>
                              <meta name="description" content={MetaData.Guidelines.description} />
                              <meta name="keywords" content={MetaData.Guidelines.keywords} />
                        </Helmet>
                        {/* <!-- Please mind the class --> */}
                        <main className="main__membership-guidelines">

                              {/* <!-- #mgSection1 --> */}
                              <section data-scroll-section id="mgSection1" className="mg-screen screen01">
                                    <div data-scroll data-scroll-repeat data-inview="mgSection1" data-next-screen="#mgSection2" className="container-fluid">
                                          <div className="row">
                                                <div className="col-md-12">
                                                      <div className="content content__screen01 text-center mx-auto position-relative">
                                                            <a className="btn__scrollTo-next-screen" href="#mgSection2" data-scroll-to> </a>
                                                            <div className="content__header">
                                                                  <div className="big-heading">
                                                                        <h1 className="mb-0">Membership<br/>Guidelines</h1>
                                                                        <HeadingPictureComponent 
                                                                        dataScroll 
                                                                        className="ticket-orange" srcSet="ticket-guidelines-big-heading1-orange.svg" src="ticket-guidelines-big-heading1-orange-sm.svg"/>

                                                                        <HeadingPictureComponent 
                                                                        dataScroll 
                                                                        className="ticket-blue" srcSet="ticket-guidelines-big-heading1-blue.svg" src="ticket-guidelines-big-heading1-blue-sm.svg"/>

                                                                        <HeadingPictureComponent 
                                                                        dataScroll 
                                                                        className="ticket-green" srcSet="ticket-guidelines-big-heading1-green.svg" src="ticket-guidelines-big-heading1-green-sm.svg"/>

                                                                        <HeadingPictureComponent 
                                                                        dataScroll 
                                                                        className="ticket-aquamarine" srcSet="ticket-guidelines-big-heading1-aquamarine.svg" src="ticket-guidelines-big-heading1-aquamarine-sm.svg"/>

                                                                        <HeadingPictureComponent 
                                                                        dataScroll 
                                                                        className="ticket-yellow" srcSet="ticket-guidelines-big-heading1-yellow.svg" src="ticket-guidelines-big-heading1-yellow-sm.svg"/>
                                                                       
                                                                  </div>
                                                            </div>
                                                            {/* <!-- .content__header --> */}
                                                            <div className="content__body">
                                                                  <p className="lead fw-bold mb-0">
                                                                        There are some important things you need to know about membership.
                                                                  </p>
                                                            </div>
                                                            {/* <!-- .content__body --> */}
                                                            <div className="content__cta">
                                                                  <a className="btn btn-icon p-0" href="#mgSection2" data-scroll-to>
                                                                        <ImageComponent src="icon-arrow-down-bordered-circle-black.svg" type="images"/>
                                                                  </a>
                                                            </div>
                                                            {/* <!-- .content__cta --> */}
                                                      </div>
                                                      {/* <!-- .content --> */}

                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}

                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>
                              {/* <!-- /#mgSection2 --> */}

                              {/* <!-- #mgSection2 --> */}
                              <section data-scroll-section id="mgSection2" className="mg-screen screen02">
                                    <div data-scroll data-scroll-repeat data-inview="mgSection2" className="container-fluid">
                                          <div className="row">
                                                <div className="col-md-12">

                                                      <div className="content content__screen02 text-center mx-auto position-relative">
                                                            <a className="btn__scrollTo-prev-screen" href="#mgSection1" data-scroll-to> </a>
                                                            <a className="btn__scrollTo-next-screen" href="#mgSection3" data-scroll-to> </a>
                                                            <div className="content__header">
                                                                  <div className="big-heading">
                                                                        <h2 className="mb-0">Sssshh…<br/>Keep our secret</h2>

                                                                  </div>
                                                            </div>
                                                            {/* <!-- .content__header --> */}
                                                            <div className="vr"></div>
                                                            <div className="content__body">
                                                                  {/* <p className="lead fw-bold mb-0">Don’t mention Central <br className="d-lg-none"/>Ticket on your <br className="d-none d-xl-block"/>social <br className="d-lg-none"/>media when you are <br className="d-lg-none"/>at the venue</p> */}
                                                                  <p className="lead fw-bold mb-0">Enjoy cut-price tickets, but don’t talk about them on social media or at the venue.  Our ticketing  partners are relying on you to be discreet in exchange for the discounted tickets.</p>
                                                            </div>
                                                            {/* <!-- .content__body --> */}
                                                            <div className="content__cta">
                                                                  <a className="btn btn-icon p-0" href="#mgSection3" data-scroll-to>
                                                                        {/* <img src={iconArrowDownBorderedCircleBlack} alt=""/> */}
                                                                        <ImageComponent src="icon-arrow-down-bordered-circle-black.svg" type="images"/>
                                                                  </a>
                                                            </div>
                                                            {/* <!-- .content__cta --> */}
                                                      </div>
                                                      {/* <!-- .content --> */}

                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}

                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>
                              {/* <!-- /#mgSection2 --> */}

                              {/* <!-- #mgSection3 --> */}
                              <section data-scroll-section id="mgSection3" className="mg-screen screen03 bg-re">
                                    <div className="container-fluid">
                                          <div className="row">
                                                <div className="col-md-12">
                                                      <div className="content content__screen03 text-center mx-auto position-relative">
                                                            <a className="btn__scrollTo-prev-screen" href="#mgSection2" data-scroll-to> </a>
                                                            <a className="btn__scrollTo-next-screen" href="#mgSection4" data-scroll-to> </a>
                                                            <div className="content__header">
                                                                  <div data-scroll data-scroll-repeat data-inview="mgSection3" className="big-heading">
                                                                  {/* <h2 className="mb-0">Only one user <br className="d-none d-sm-block"/>per account</h2> */}
                                                                  <h2 className="mb-0">No account sharing<br className="d-none d-sm-block"/></h2>
                                                                  </div>
                                                            </div>
                                                            {/* <!-- .content__header --> */}
                                                            <div className="vr"></div>
                                                            <div className="content__body">
                                                                  {/* <p className="lead fw-bold mb-0">Do not forward our emails to <br className="d-none d-md-block d-lg-none"/>anyone and do not allow <br className="d-none d-md-block d-lg-none"/>anyone to use your account <br className="d-lg-none"/>& to
                                                                        collect
                                                                        tickets on your <br className="d-none d-md-block d-lg-none"/>behalf,
                                                                        passing along tickets <br className="d-none d-md-block d-lg-none"/>to anyone else is strictly <br className="d-none d-md-block d-lg-none"/>forbidden.
                                                                  </p> */}
                                                                  <p className="lead fw-bold mb-0">Don’t forward our emails or allow others to use your account.  You can only book for yourself (and guests).  We ask that you make at least one booking per year and not cancel any of your first three bookings.
                                                                  </p>
                                                                  
                                                            </div>
                                                            {/* <!-- .content__body --> */}
                                                            <div className="content__cta">
                                                                  <a className="btn btn-icon p-0" href="#mgSection4" data-scroll-to>
                                                                        {/* <img src={iconArrowDownBorderedCircleWhite} alt=""/> */}
                                                                        <ImageComponent src="icon-arrow-down-bordered-circle-white.svg" type="images"/>
                                                                  </a>
                                                            </div>
                                                            {/* <!-- .content__cta --> */}
                                                      </div>
                                                      {/* <!-- .content --> */}

                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}

                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>
                              {/* <!-- /#mgSection3 --> */}

                              {/* <!-- #mgSection4 --> */}
                              <section data-scroll-section id="mgSection4" className="mg-screen screen04 position-relative">
                                    <div className="container-fluid">
                                          <div className="row">
                                                <div className="col-md-12">
                                                      <div className="content content__screen04 text-center mx-auto">
                                                            <a className="btn__scrollTo-prev-screen" href="#mgSection3" data-scroll-to> </a>
                                                            <a className="btn__scrollTo-next-screen" href="#mgSection5" data-scroll-to> </a>
                                                            <div className="content__header">
                                                                  <div className="big-heading">
                                                                        {/* <h2 className="mb-0">Follow the <br className="d-none d-sm-block"/>instructions <br className="d-none d-md-block d-xl-none"/>& come <br/>with a smile</h2> */}
                                                                        <h2 className="mb-0">Read the collection instructions</h2>
                                                                  </div>
                                                            </div>
                                                            {/* <!-- .content__header --> */}
                                                            <div className="vr"></div>
                                                            <div data-scroll data-scroll-repeat data-inview="mgSection4" className="content__body">
                                                                  {/* <p className="lead fw-bold mb-0">Follow the instructions on <br className="d-lg-none"/>the PDF we send you once <br className="d-lg-none"/>you <br className="d-none d-xl-block"/>booked your ticket. And <br className="d-lg-none"/>remember to be friendly and
                                                                  <br className="d-lg-none"/>polite <br className="d-none d-xl-block"/>to Box Office staff and <br className="d-lg-none"/>make
                                                                  no mention of free or <br className="d-lg-none d-xl-block"/>complimentary tickets at the <br className="d-none d-md-block d-lg-none"/>venue.
                                                                  </p> */}
                                                                  <p className="lead fw-bold mb-0">
                                                                        They can differ from show to show, so it’s important to read them each time you book.
                                                                  </p>

                                                            </div>
                                                            {/* <!-- .content__body --> */}
                                                            <div className="content__cta">
                                                                  <a className="btn btn-icon p-0" href="#mgSection5" data-scroll-to>
                                                                        {/* <img src={iconArrowDownBorderedCircleBlack} alt=""/> */}
                                                                        <ImageComponent src="icon-arrow-down-bordered-circle-black.svg" type="images"/>
                                                                  </a>
                                                            </div>
                                                            {/* <!-- .content__cta --> */}
                                                      </div>
                                                      {/* <!-- .content --> */}

                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}

                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>
                              {/* <!-- /#mgSection4 --> */}

                              {/* <!-- #mgSection5 --> */}
                              <section data-scroll-section id="mgSection5" className="mg-screen screen05 position-relative">
                                    <div className="container-fluid">
                                          <div className="row">
                                                <div className="col-md-12">

                                                      <div className="content content__screen05 text-center mx-auto">
                                                            <a className="btn__scrollTo-prev-screen" href="#mgSection4" data-scroll-to> </a>
                                                            <a className="btn__scrollTo-next-screen" href="#mgSection6" data-scroll-to> </a>
                                                            <div className="content__header">
                                                                  <div className="big-heading">
                                                                        <h2 className="mb-0">Cancellations & refunds<br className="d-sm-none"/></h2>
                                                                  </div>
                                                            </div>
                                                            {/* <!-- .content__header --> */}
                                                            <div className="vr"></div>
                                                            <div data-scroll data-scroll-repeat data-inview="mgSection5" className="content__body">
                                                                  <p className="lead fw-bold mb-0">
                                                                        Cancelled tickets are non-refundable unless you’ve booked <Link to={routesName.FlexiTerms} style={{color:"inherit"}}>Flexi-Tickets</Link> which allow some flexibility around cancellations.
                                                                  </p>
                                                            </div>
                                                            {/* <!-- .content__body --> */}
                                                            <div className="content__cta">
                                                                  <a className="btn btn-icon p-0" href="#mgSection6" data-scroll-to>
                                                                        {/* <img src={iconArrowDownBorderedCircleWhite} alt=""/> */}
                                                                        <ImageComponent src="icon-arrow-down-bordered-circle-white.svg" type="images"/>
                                                                  </a>
                                                            </div>
                                                            {/* <!-- .content__cta --> */}
                                                      </div>
                                                      {/* <!-- .content --> */}

                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}

                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>
                              {/* <!-- /#mgSection5 --> */}

                              {/* <!-- #mgSection6 --> */}
                              <section data-scroll-section id="mgSection6" className="mg-screen screen06 position-relative">
                                    <div className="container-fluid">
                                          <div className="row">
                                                <div className="col-md-12">

                                                      <div className="content content__screen06 text-center mx-auto">
                                                            <a className="btn__scrollTo-prev-screen" href="#mgSection5" data-scroll-to> </a>
                                                            <a className="btn__scrollTo-next-screen" href="#mgSection7" data-scroll-to> </a>
                                                            <div className="content__header">
                                                                  <div className="big-heading">
                                                                        <h2 className="mb-0">Be friendly <br className="d-sm-none"/>& reasonable</h2>
                                                                  </div>
                                                            </div>
                                                            {/* <!-- .content__header --> */}
                                                            <div className="vr"></div>
                                                            <div data-scroll data-scroll-repeat data-inview="mgSection6" className="content__body">
                                                                  <p className="lead fw-bold mb-0">
                                                                        We’ll always be friendly and respectful towards you and we ask you to be the same towards our team and the venue staff we work with.
                                                                  </p>
                                                            </div>
                                                            {/* <!-- .content__body --> */}
                                                            <div className="content__cta">
                                                                  <a className="btn btn-icon p-0" href="#mgSection7" data-scroll-to>
                                                                        <ImageComponent src="icon-arrow-down-bordered-circle-black.svg" type="images"/>
                                                                  </a>
                                                            </div>
                                                            {/* <!-- .content__cta --> */}
                                                      </div>
                                                      {/* <!-- .content --> */}

                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}

                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>
                              {/* <!-- /#mgSection6 --> */}

                              {/* <!-- #mgSection7 --> */}
                              <section data-scroll-section id="mgSection7" className="mg-screen screen07 position-relative">
                                    <div className="container-fluid">
                                          <div className="row">
                                                <div className="col-md-12">
                                                      <div className="content content__screen07 text-center mx-auto">
                                                            <div className="content__body">
                                                                  <div data-scroll data-scroll-repeat data-inview="mgSection7" className="big-heading">
                                                                        {/* <h2>Our service is born as an exchange between <br className="d-md-none"/>venues and <br className="d-md-none"/>people.</h2> */}
                                                                        <p className="h2 mb-0">
                                                                              Our members get cut price tickets in exchange for
                                                                              filling empty seats.
                                                                        </p>

                                                                        <HeadingPictureComponent
                                                                        dataScroll
                                                                        className="ticket-orange1"
                                                                        srcSet="ticket-guidelines-screen06-orange1.svg"
                                                                        src="ticket-guidelines-screen06-green1-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent
                                                                        dataScroll
                                                                        className="ticket-green1"
                                                                        srcSet="ticket-guidelines-screen06-green1.svg"
                                                                        src="ticket-guidelines-screen06-blue1-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent
                                                                        dataScroll
                                                                        className="ticket-yellow1"
                                                                        srcSet="ticket-guidelines-screen06-yellow1.svg"
                                                                        src="ticket-guidelines-screen06-orange1-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent
                                                                        dataScroll
                                                                        className="ticket-green3"
                                                                        srcSet="ticket-guidelines-screen06-green3.svg"
                                                                        src="ticket-guidelines-screen06-aquamarine1-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent
                                                                        dataScroll
                                                                        className="ticket-green2"
                                                                        srcSet="ticket-guidelines-screen06-green2.svg"
                                                                        src="ticket-guidelines-screen06-green2-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent
                                                                        dataScroll
                                                                        className="ticket-blue1"
                                                                        srcSet="ticket-guidelines-screen06-blue1.svg"
                                                                        src="ticket-guidelines-screen06-blue2-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent
                                                                        dataScroll
                                                                        className="ticket-aquamarine1"
                                                                        srcSet="ticket-guidelines-screen06-aquamarine1.svg"
                                                                        src="ticket-guidelines-screen06-green3-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent
                                                                        dataScroll
                                                                        className="ticket-blue2"
                                                                        srcSet="ticket-guidelines-screen06-blue2.svg"
                                                                        src="ticket-guidelines-screen06-orange2-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent
                                                                        dataScroll
                                                                        className="ticket-yellow2"
                                                                        srcSet="ticket-guidelines-screen06-yellow2.svg"
                                                                        src="ticket-guidelines-screen06-green4-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent
                                                                        dataScroll
                                                                        className="ticket-orange2"
                                                                        srcSet="ticket-guidelines-screen06-orange2.svg"
                                                                        src="ticket-guidelines-screen06-yellow1-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent
                                                                        dataScroll
                                                                        className="ticket-aquamarine2"
                                                                        srcSet="ticket-guidelines-screen06-aquamarine2.svg"
                                                                        src="ticket-guidelines-screen06-aquamarine2-sm.svg"
                                                                        />
                                                                  </div>
                                                            </div>
                                                            {/* <!-- .content__body --> */}
                                                            <div className="content__cta">
                                                                  <a className="btn btn-icon p-0" href="#mgSection8" data-scroll-to>
                                                                        <ImageComponent
                                                                        src="icon-arrow-down-bordered-circle-black.svg"
                                                                        type="images"
                                                                        />
                                                                  </a>
                                                            </div>
                                                            {/* <!-- .content__cta --> */}
                                                      </div>
                                                      {/* <!-- .content --> */}
                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}
                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>
                              {/* <!-- /#mgSection7 --> */}

                              {/* <!-- #mgSection8 --> */}
                              <section data-scroll-section id="mgSection8" className="mg-screen screen07 position-relative">
                                    <a className="btn__scrollTo-first-screen" href="#mgSection1" data-scroll-to>
                                          {/* {" "} */}
                                    </a>
                                    <div className="container-fluid">
                                          <div className="row">
                                                <div className="col-md-12">
                                                      <div className="content content__screen08 text-center mx-auto">
                                                            <div className="content__body"> 
                                                                  <div data-scroll data-scroll-repeat data-inview="mgSection7" className="big-heading">
                                                                        {/* <h2>Our service is born as an exchange between <br className="d-md-none"/>venues and <br className="d-md-none"/>people.</h2> */}
                                                                        <p className="h2 mb-0">
                                                                              Please help us maintain good relationships by
                                                                              following our member guidelines.
                                                                        </p>

                                                                        <HeadingPictureComponent
                                                                        dataScroll
                                                                        className="ticket-orange1"
                                                                        srcSet="ticket-guidelines-screen06-orange1.svg"
                                                                        src="ticket-guidelines-screen06-green1-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent
                                                                        dataScroll
                                                                        className="ticket-green1"
                                                                        srcSet="ticket-guidelines-screen06-green1.svg"
                                                                        src="ticket-guidelines-screen06-blue1-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent
                                                                        dataScroll
                                                                        className="ticket-yellow1"
                                                                        srcSet="ticket-guidelines-screen06-yellow1.svg"
                                                                        src="ticket-guidelines-screen06-orange1-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent
                                                                        dataScroll
                                                                        className="ticket-green3"
                                                                        srcSet="ticket-guidelines-screen06-green3.svg"
                                                                        src="ticket-guidelines-screen06-aquamarine1-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent
                                                                        dataScroll
                                                                        className="ticket-green2"
                                                                        srcSet="ticket-guidelines-screen06-green2.svg"
                                                                        src="ticket-guidelines-screen06-green2-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent
                                                                        dataScroll
                                                                        className="ticket-blue1"
                                                                        srcSet="ticket-guidelines-screen06-blue1.svg"
                                                                        src="ticket-guidelines-screen06-blue2-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent
                                                                        dataScroll
                                                                        className="ticket-aquamarine1"
                                                                        srcSet="ticket-guidelines-screen06-aquamarine1.svg"
                                                                        src="ticket-guidelines-screen06-green3-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent
                                                                        dataScroll
                                                                        className="ticket-blue2"
                                                                        srcSet="ticket-guidelines-screen06-blue2.svg"
                                                                        src="ticket-guidelines-screen06-orange2-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent
                                                                        dataScroll
                                                                        className="ticket-yellow2"
                                                                        srcSet="ticket-guidelines-screen06-yellow2.svg"
                                                                        src="ticket-guidelines-screen06-green4-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent
                                                                        dataScroll
                                                                        className="ticket-orange2"
                                                                        srcSet="ticket-guidelines-screen06-orange2.svg"
                                                                        src="ticket-guidelines-screen06-yellow1-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent
                                                                        dataScroll
                                                                        className="ticket-aquamarine2"
                                                                        srcSet="ticket-guidelines-screen06-aquamarine2.svg"
                                                                        src="ticket-guidelines-screen06-aquamarine2-sm.svg"
                                                                        />
                                                                  </div>
                                                            </div>
                                                            {/* <!-- .content__body --> */}
                                                      </div>
                                                      {/* <!-- .content --> */}
                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}
                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>
                              {/* <!-- /#mgSection8 --> */}

                        </main>
                        {!pathname.includes("membership_guidelines") &&
                        <Footer/>}

                  </div>
                  {/* <!-- /div[data-scroll-container] --> */}
            </React.Fragment>
      )
}

export default MembershipGuidelines;
