export const privacy =  build =>
      build.query({
            query: (body) =>  ({
                  url: 'contents?type=privacy_ct_web',
                  method: 'GET',
                  body: body
            }),
      })


      //changed as per new