import React, { useEffect, useState } from 'react';

// external css
import "./LandingPage.css"
import appleTouchIcon from "../../assets/images/apple-touch-icon.png"
// import LandingPageBackgroundImage from "../../assets/images/landing-page-background.png"
import ticketHomeCarouselItem1BlueSm from "../../assets/images/tickets/ticket-home-carousel-item1-blue-sm.svg"
import ticketHomeCarouselItem1Blue from "../../assets/images/tickets/ticket-home-carousel-item1-blue.svg"
import landingPageTicketOrange from "../../assets/images/tickets/landing-page-ticket-orange.png"

// redux and react hooks
import { Link  } from 'react-router-dom';

// child component
import Footer from "../../Layouts/Footer/Footer";
import ShowsTicker from '../Home/showTicker.js';
import { HeadingPictureComponent, ImageComponent } from '../../Components';

// packages
import { TickerText } from '../../Components';
import OwlCarousel from 'react-owl-carousel';
import { isIOS, isMacOs } from 'react-device-detect';


// Actions & RTK query or mutations
// Constants
import { routesName } from '../../resources/RoutesName.js';
import { catsTwoText, locText } from '../../resources/TextRollerConstants';
import { landingPageMobileOptions } from '../../resources/OwlCarouselOptions.js';
import { URL } from '../../resources/Url.js';

const LandingPage = () => {

      const [device,setDevice]            =     useState('');

      let width                           =     window.innerWidth

      useEffect(()=>{
            
            if(width > 767){
                  setDevice('desktop')
            } else {
                  setDevice('mobile')
            }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

      const getDateForEcologiLogo = ()=>{
            const date = Date.now()
            return `ecology_logo_black.png?v=${date}`
      }


      let ecologiLogoBlack = "ecology_logo_black.png?v=1";
      ecologiLogoBlack = getDateForEcologiLogo()

      let qRImage = "https://api.centraltickets.co.uk/landing_page/central_tickets_app_android.png"
      let downloadUrl = URL.GOOGLE_PLAY
      if(isIOS || isMacOs) {
            qRImage = "https://api.centraltickets.co.uk/landing_page/central_tickets_app_apple.png"
            downloadUrl = URL.APPLE_STORE
      }

      return (
            
            <React.Fragment>
                  {/* <!-- REQUIRED wrapper div for parallax --> */}
                  <div className="scroll-animations position-relative" data-scroll-container>
                        
                        <main className="main__home main__landing trustpilot-home-header">

                              <section data-scroll-section className="section__home-hero section__landing-hero">
            
                                    <div className="container-fluid border-bottom">
                                          <div className="image-wrapper">
                                                <img src={'https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/landing-page-background-converted.png'} alt=''/> 
                                          </div>
                                          <div className="col-md-12 qr-container">

                                                <div className="background-container">
                                                      <div className="col-md-12 text-container">
                                                            <h2 className="heading">Download the <br className="break-tag" style={{display : "none"}} /><span>Central Tickets</span> app.</h2>
                                                            <p>
                                                                  Enjoy tickets at £4-15 for theatre, music, dance, comedy, classical music, talks & movies by becoming a seat-filler. It's free to join, download the app or scan the QR code today!
                                                            </p>
                                                      </div>
                                                      <hr />
                                                      <div className="qr-code-container">
                                                            <div className="qr-image-wrapper">
                                                                  <img src={qRImage} alt='' />
                                                            </div>
                                                            <p>
                                                                  Scan the QR code  <br className="break-tag" style={{display : "none"}}/>to get the app
                                                            </p>
                                                      </div>
                                                </div>

                                                <div className="hero-cta">
                                                      <div className="buttons-wrapper two-buttons">
                                                            <a className="btn btn-xl-lg btn-primary has-icon" href="https://apps.apple.com/in/app/central-tickets-member-app/id1599267466" rel="noreferrer" target="_blank">
                                                                  <span className="icon-wrapper icon-apple-as-bg"></span>
                                                                  <span className="btn__text">Apple Store</span>
                                                            </a>
                                                            <a className="btn btn-xl-lg btn-primary has-icon" href="https://play.google.com/store/apps/details?id=centraltickets.co.uk" rel="noreferrer" target="_blank">
                                                                  <span className="icon-wrapper icon-googleplay-as-bg"></span>
                                                                  <span className="btn__text">Google Play</span>
                                                            </a>
                                                      </div>
                                                </div>
                                          </div>
                                        
                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>

                              <div data-scroll-section className="py-0 border-bottom">
                                    <TickerText id="tickerShowCatsTwo" textData={catsTwoText}/>
                                   
                                    {/* <!-- /.ticker --> */}
                              </div>

                              <section data-scroll-section className="section__home-hero section__landing-hero border-bottom">
           
                                    <div className="container-fluid container__block">
                                          <div className="row">
                                                <div className="col-md-12">
                                                      <div className="content content__carousel-home-hero">
                                                            {device === "mobile" ?
                                                            <div className="content__body position-relative">
                                                                  <OwlCarousel className='owl-carousel owl-carousel__home-hero owl-theme'  {...landingPageMobileOptions}
                                                                  mergeFit={true} 
                                                                  startPosition={0}
                                                                  autoplay={true}>

                                                                        {/* <!-- item 1 --> */}
                                                                        <div className="item">
                                                                              <h2>Discover <br/>new shows</h2>
                                                                              <div className="hero__image">
                                                                                    <img className="carousel-image object-fit" src="https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/Listing-mobile-image.png" alt=''/>
                                                                              </div>
                                                                        </div>

                                                                        <div className="item">
                                                                              <h2>Book tickets</h2>
                                                                              <div className="hero__image">
                                                                                    <img className="carousel-image object-fit" src="https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/Event-details-mobile-image.png"  alt=''/> 
                                                                              </div>
                                                                        </div>

                                                                        <div className="item">
                                                                              <h2>Manage bookings</h2>
                                                                              <div className="hero__image">
                                                                                    <img className="carousel-image object-fit" src="https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/Mybookings-mobile-image.png" alt=''/> 
                                                                              </div>
                                                                        </div>

                                                                        
                                                                  {/* </div> */}
                                                                  </OwlCarousel>

                                                                  <div id="owlHomeHeroNav" className="owl-nav">
                                                                  </div>
                                                            </div> : 
                                                            <div className="content__body-desktop">
                                                                  <div className="item">
                                                                        <h2>Discover <br/>new shows</h2>
                                                                        <div className="hero__image">
                                                                              <img className="carousel-image object-fit" src="https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/Listing-mobile-image.png" alt=''/>
                                                                        </div>
                                                                  </div>

                                                                  <div className="item">
                                                                        <h2>Book <br/>tickets</h2>
                                                                        <div className="hero__image">
                                                                              <img className="carousel-image object-fit" src="https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/Event-details-mobile-image.png"  alt=''/> 
                                                                        </div>
                                                                  </div>

                                                                  <div className="item">
                                                                        <h2>Manage <br/>bookings</h2>
                                                                        <div className="hero__image">
                                                                              <img className="carousel-image object-fit" src="https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/Mybookings-mobile-image.png" alt=''/> 
                                                                        </div>
                                                                  </div>
                                                            </div>}
                                                            
                                                      </div>
                                                      {/* <!-- /.content --> */}
                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}
                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>

                              <section data-scroll-section className="section__home-hero section__landing-steps border-bottom">
           
                                    <div className="container-fluid container__block">
                                          <div className="row content-container">
                                                <div className="heading-container col-12 col-md-6 order-1">
                                                      <div className="big-heading">
                                                            <h1>A better <br className='one'/> mobile <br className='two'/>experience</h1>

                                                            <HeadingPictureComponent dataScroll className="ticket-yellow" srcSet="ticket-register-big-heading1-yellow.svg" src="ticket-register-big-heading1-yellow-sm.svg"/>
                                                            <HeadingPictureComponent dataScroll className="ticket-aquamarine" srcSet="ticket-home-big-heading3-aquamarine.svg" src="ticket-home-big-heading3-aquamarine-sm.svg"/>
                                                            <HeadingPictureComponent dataScroll className="ticket-orange" srcSet="ticket-faq-big-heading1-orange.svg" src="ticket-faq-big-heading1-orange-sm.svg"/>
                                                      </div>

                                                </div>
                                                <div className="steps-container col-12 col-md-6  order-2">
                                                      <ul>
                                                            <li><h4>+ &nbsp;Create an account</h4></li>
                                                            <li><h4>+ &nbsp;Save favorite shows</h4></li>
                                                            <li><h4>+ &nbsp;Top-up credit wallet</h4></li>
                                                            <li><h4>+ &nbsp;Book tickets</h4></li>
                                                            <li><h4>+ &nbsp;Search Shows</h4></li>
                                                            <li><h4>+ &nbsp;Filter listings</h4></li>
                                                      </ul>
                                                </div>
                                                <div className="icon-container col-12 col-md-6 order-3">
                                                      <img src={appleTouchIcon} alt=''/>
                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}
                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>

                              <section data-scroll-section className="section__voucher border-bottom">
           
                                    <div className="container-fluid container__block">
                                          <div className="row content-container">
                                                <h1>10% free <br className='one'/> wallet credit <br className='two'/>with first <br className='three'/>top-up*</h1>
                                                <p>
                                                      Add credit to your wallet and we'll give you 10% extra free (£10 max) *<Link to={{ pathname: `${routesName.Terms}?wallet`, state: { myData: "wallet" } }}  target="_blank" rel="noopener noreferrer">terms apply</Link>
                                                </p>
                                          </div>
                                          
                                    </div>
                              </section>


                              <section data-scroll-section className="section__ecologi" data-scroll-section-inview="">
                                    <div className="container-fluid container__block">
                                          <div className="row">
                                                <div className="col-md-12">
                                                      <div className="content content__ecologi text-center mx-auto">
                                                            <div className="content__header">
                                                                  <div className="big-heading">
                                                                        <h2 className="mb-0">Working to <br className='one'/>generate <br className='two' /> social impact</h2>
 
                                                                        <HeadingPictureComponent dataScroll className="ticket-aquamarine" srcSet="ticket-home-big-heading2-aquamarine.svg" src="ticket-home-big-heading2-aquamarine-sm.svg"/> 
                                                                        
                                                                        <HeadingPictureComponent dataScroll className="ticket-green" 
                                                                        srcSet="ticket-home-big-heading2-green.svg" src="ticket-for-members-green-sm.svg"/>

                                                                        <HeadingPictureComponent dataScroll className="ticket-yellow" srcSet="ticket-home-big-heading2-yellow.svg" src="ticket-home-big-heading2-yellow-sm.svg"/>

                                                                        <picture>
                                                                              <source media="(min-width: 992px)" srcSet={ticketHomeCarouselItem1Blue}/>
                                                                              <img data-scroll className="ticket ticket-blue" src={ticketHomeCarouselItem1BlueSm} alt=""/>
                                                                        </picture> 
                                                                        <picture>
                                                                              <source media="(min-width: 992px)" srcSet={landingPageTicketOrange}/>
                                                                              <img data-scroll className="ticket ticket-orange" src={landingPageTicketOrange} alt=""/>
                                                                        </picture>
                                                                  </div>
                                                            </div>
                                                            <div className="content__body">
                                                                  
                                                                  <div className="features__impact justify-content-md-center">
                                                                        <div data-scroll="true" className="feature feature__impact is-inview">
                                                                              <a className="feature__icon" href="https://ecologi.com/centralticketslimited" target="_blank" rel='noreferrer'>
                                                                                    <img className="icon icon- mx-auto logo__ecologi"  src={`${URL.Image_Base_Url}ecology_logo/${ecologiLogoBlack}`}  alt=""/>
                                                                              </a>
                                                                             
                                                                              <div className="feature__body">
                                                                                    <p>We contribute to carbon avoidance projects via Ecologi for every round-trip our members take to the theatre.</p>
                                                                              </div>
                                                                        </div>
                                                                        <div data-scroll="true" className="feature feature__impact is-inview">
                                                                              <a className="feature__icon" href="https://nhscharitiestogether.co.uk/" target="_blank" rel='noreferrer' style={{ textDecoration : "none"}}>
                                                                                    <ImageComponent className="icon icon- mx-auto" srcSet="logo-nhs-charities-together-blue-296@2x.png" src="logo-nhs-charities-together-blue-296.png" alt="" type="images"/>
                                                                              </a>
                                                                              
                                                                              <p class="p-registered text-start mb-0">Registered charity no. 1186569</p>
                                                                              <div className="feature__body">
                                                                                    <p>We are working in support of <a className="" href="https://nhscharitiestogether.co.uk/" target="_blank" rel='noreferrer' style={{ textDecoration : "underline", color: "inherit"}}>NHS Charities Together</a> who help over 200 NHS Charities that support staff and patients.</p>
                                                                              </div>
                                                                        </div>
                                                                        <div data-scroll="true" className="feature feature__impact is-inview">
                                                                              <a className="feature__icon" href="https://www.crowdfunder.co.uk/p/centraltickets" target="_blank" rel='noreferrer' style={{ textDecoration : "none", color: "inherit"}}>
                                                                                    <h4>
                                                                                          £47K
                                                                                    </h4>
                                                                              </a>
                                                                              <div className="feature__body">
                                                                                    <p>
                                                                                          <a href="https://www.crowdfunder.co.uk/p/centraltickets" target="_blank" rel='noreferrer' style={{ textDecoration : "underline", color: 'inherit'}}>Donations</a> 
                                                                                          &nbsp;raised to help venues and performers impacted by theatre closures during the Covid pandemic.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </section>

                              {/* <section data-scroll-section className="section__social-impact border-bottom">
           
                                    <div className="container-fluid container__block">
                                          <div className="row content-container">
                                             <div className='item'>
                                                <div className='image-wrapper'>
                                                      <img src='' alt=''/>
                                                </div>
                                                <div className="content">
                                                      <p>We invest in carbon</p>
                                                </div>
                                             </div>
                                             <div className='item'>
                                                <div className='image-wrapper'>
                                                      <img src='' alt=''/>
                                                </div>
                                                <div className="content">
                                                      <p></p>
                                                </div>
                                             </div>
                                             <div className='item'>
                                                <div className='image-wrapper'>
                                                      <img src='' alt=''/>
                                                </div>
                                                <div className="content">
                                                      <p></p>
                                                </div>
                                             </div>
                                          </div>
                                    </div>
                              </section> */}


                              
                              <div data-scroll-section className="border-top py-0">
                                    <TickerText id="tickerLoc" textData={locText}/>
                                    {/* <!-- /.ticker --> */}
                              </div>

                              <ShowsTicker />
          
                              <div data-scroll-section className="section__cta section__cta--huge bg-red border-bottom">
                                    <div className="container-fluid container__block">
                                          <div className="row">
                                                <div className="col-md-12">
                                                      <div className="data-scroll-wrapper">
                                                            <a data-scroll className="btn btn-huge btn-outline-primary btn-outline-white" href={downloadUrl} rel="noreferrer" target="_blank">
                                                                  Download
                                                            </a>
                                                      </div>
                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}
                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </div>
          
                              <div data-scroll-section className="py-0 border-bottom">
                                    <TickerText id="tickerShowCatsTwo" textData={catsTwoText}/>
                                   
                                    {/* <!-- /.ticker --> */}
                              </div>
                        </main>

                        <Footer/>
                  </div>
                  {/* <!-- /div[data-scroll-container] --> */}
            </React.Fragment>
      )
}

export default LandingPage;
