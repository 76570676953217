import React from 'react'

const SectionHeading = ({ data }) => {

      return (
            <div className="section-heading">
                  <h2>{data.Title}</h2>
            </div>
      )
}

export default SectionHeading
