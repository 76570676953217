import React from 'react'

import externalAlterImage from "./../assets/images/external-alterImage.png"


const ExternalCarouselDummyImage = () => {

      return (
            <img className="event-image" src={externalAlterImage} alt=''/>
      )
}

export default ExternalCarouselDummyImage
