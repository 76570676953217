import React, { useState, useRef, useEffect } from 'react'

// external css
// redux and react hooks
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// child component
import Backdrop from '../Backdrop'

// packages
// Actions & RTK query or mutations
import { useOtpVerificationMutation } from "./../../Services/modules/Otp"
import { otpVerificationValidationRules } from "./../../Services"
import { setAlert, setOtpModalStatus, setResetPasswordCode, setCtChangingMobileNumber } from './../../Store/UI'
import { logout } from '../../Store/User'
import { api } from '../../Services/Api'

// Constants
import { label, errors, messages } from "./../../resources/en"
import { routesName } from '../../resources/RoutesName'

const OtpMyAccountModal = (props) => {

      const [state,setState] = useState({
            otpFirstChar:"",
            otpSecondChar:"",
            otpThirdChar:"",
            otpFourthChar:"",
            otpFifthChar:"",
      })
      const [frontError,setFrontError]    =     useState({error:false,errorMessage:""})
      const [seconds,setSeconds]          =     useState(0)
      const [minutes,setMinutes]          =     useState(0)
      const [isSentOtp,setIsSentOtp]      =     useState(true)
      
      const dispatch                      =     useDispatch()
      const ui                            =     useSelector(state=>state.ui)
      const user                          =     useSelector(state=>state.user)
      const firstInputRef                 =     useRef()
      const secondInputRef                =     useRef()
      const thirdInputRef                 =     useRef()
      const fourthInputRef                =     useRef()
      const fifthInputRef                 =     useRef()
      const navigate                      =     useNavigate()

      const [otpVerification, { data, isSuccess, isLoading, isFetching, error }]    =     useOtpVerificationMutation();


      useEffect(()=>{
            
            setMinutes(1);
            setSeconds(0);
            setIsSentOtp(false)

            return ()=> {
                  resetStates();
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

      useEffect(()=>{
            let myInterval
            if(!isSentOtp){

                  myInterval = setInterval(() => {
                        if (seconds > 0) {
                              setSeconds(seconds-1)
                        }
                        if (seconds === 0) {
                              if (minutes === 0) {
                                    clearInterval(myInterval)
                                    setIsSentOtp(true);
                              } else {
                                    setMinutes(minutes-1)
                                    setSeconds(59)
                              }
                        } 
                  }, 1000)
            }

            return ()=>{
                  clearInterval(myInterval);
            }
      })

      // Verify OTP Response Handler
      useEffect(()=>{
            //  Handling verify OTP API Request
            if(isSuccess){
                  if(data.status === "1"){

                        resetStates();
                        dispatch(setCtChangingMobileNumber({ct_changing_mobile_number : ""}))
                        const status = ui.ct_otp_modal_status === "otp" ? "mobile_verified" : ""
                        dispatch(setOtpModalStatus({status : status}))

                        if(ui.ct_otp_modal_status !== "otpForgotPassword"){
                              dispatch(setAlert({model:true,title:messages.modalSuccessTitle,message:data.message || errors.NotFound404}))
                        }
                        
                        if(ui.ct_otp_modal_status === "otpForgotPassword"){
                              dispatch(setResetPasswordCode({ct_reset_password_code : data.data.code}));
                              navigate(routesName.ResetPassword)
                        } 
                        if(status !== "mobile_verified") {
                              dispatch(api.util.resetApiState());
                              console.log("logout using otp my account modal")
                              dispatch(logout());
                        }
                  }

                  if(data.status === "0"){
                        
                        setFrontError(prevState=>{
                              return {
                                    error:true,
                                    errorMessage:data.message
                              }
                        })
                  }
            } 
           
            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[data, isSuccess, isLoading, isFetching, error, dispatch])

      const resetStates = ()=>{
            setSeconds(0)
            setMinutes(0)
            setIsSentOtp(true)
            setState({
                  otpFirstChar:"",
                  otpSecondChar:"",
                  otpThirdChar:"",
                  otpFourthChar:"",
                  otpFifthChar:"",
            })
            setFrontError({error:false,errorMessage:""})
      }

      const focusHandler = ()=>{
            setFrontError(prevState=>{
                  return {
                        error:false,
                        errorMessage:""
                  }
            })
      }

      const closeModalHandler = (event)=>{
            event.preventDefault();
            dispatch(setOtpModalStatus({status : ""}))
      }

      const changeHandler = (event)=> {

            const key = event.target.name;
            const onlyNumbers = event.target.value.replace(/\D/g, '');

            setState(prevState=>{

                  return {
                        ...prevState,
                        [key] :onlyNumbers
                  }
            })

            if(!onlyNumbers){
                  if(key === "otpFifthChar"){
                        fourthInputRef.current.focus();
                  }
                  else if(key === "otpFourthChar"){
                        thirdInputRef.current.focus();
                  }
                  else if(key === "otpThirdChar"){
                        secondInputRef.current.focus();
                  }
                  else if (key === "otpSecondChar"){
                        firstInputRef.current.focus();
                  }
                  return;
            }
            else {

                  if(key === "otpFirstChar"){
                        secondInputRef.current.focus();
                  }
                  else if(key === "otpSecondChar"){
                        thirdInputRef.current.focus();
                  }
                  else if(key === "otpThirdChar"){
                        fourthInputRef.current.focus();
                  }
                  else if(key === "otpFourthChar"){
                        fifthInputRef.current.focus();
                  }
                  
            }

      }

      // const sendOTPHandler = (event)=>{
      //       event.preventDefault();
            
      //       console.log("resend OTP")
      //       // const  type = ui.ct_otp_modal_status === "otp" ? "mobile" : "email"
      //       // sendOTPToVerify({ type:type });
      // }

      const submitOtpHandler = (event)=>{
            event.preventDefault();

            let otp = "";

            for(let i in state){
                  if(state[i]){
                        otp+=state[i]
                  }
            }
            const validation = otpVerificationValidationRules(otp,ui.ct_otp_modal_status);
            if(!validation.isValid){
                  setFrontError({error:true,errorMessage:validation.validationMessage})
                  return;
            }
            
            if(validation.isValid && otp.length === 5){
                    
                  let type= "";
                  let workflow = ""
                  let email = ""
                  if(ui.ct_otp_modal_status === "otp") {
                        type = "phone"
                        workflow = "change_phonenumber"
                  } else if (ui.ct_otp_modal_status === "otpEmail") {
                        type = "email"
                        workflow = "change_email"
                  } else if(ui.ct_otp_modal_status === "otpForgotPassword") {
                        type = "email"
                        workflow = "forget_password"
                        email = user.ct_access_email ? user.ct_access_email : ""
                  }
                  
                  otpVerification({
                        "otp":otp,
                        "type":type,
                        "workflow":workflow,
                        "email": email
                  })
            }
      }

      return (

                  <React.Fragment>
                        <div className={["modal modal__registration-verification show fade",(props.status === "entering" || props.status === "entered") && "modalOpen",(props.status === "exiting" || props.status === "exited") && "modalClosed", props.isAjtix && "ajtix-wrapper", props.isTfm && "tfm-wrapper"].join(" ")} id="modalVerificationCode" tabIndex="-1" aria-hidden="true" style={{"display":"block"}}>
                              <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                          <div className="modal-body">
                                                {ui.ct_otp_modal_status === "otp" &&
                                                <button type="button" className="btn-close btn-close__custom btn-close__absolute" data-bs-dismiss="modal" aria-label="Close" onClick={closeModalHandler}>
                                                </button>}

                                                <div className="content">
                                                      <div className="content__body">
                                                            <form>
                                                                  <p className="mb-0">
                                                                  
                                                                        {ui.ct_otp_modal_status === "otp" ? label.otpVerifyModal.replace('{{mobile}}',ui.ct_changing_mobile_number) : label.otpChangeEmailVerifyModal}
                                                                  </p>
                                                                  <div id="otp" className={["form-group__verification-code d-flex justify-content-center text-center",frontError.error ? "form-group__invalid-verification-code" : ""].join(" ")}>
                                                                        <input id="otpFirstChar" name="otpFirstChar" type="text" className="form-control" maxLength="1" autoFocus onChange={changeHandler} value={state.otpFirstChar} ref={firstInputRef} onFocus={focusHandler}/>
                                                                        <input id="otpSecondChar" name="otpSecondChar" type="text" className="form-control" maxLength="1" onChange={changeHandler} value={state.otpSecondChar} ref={secondInputRef} onFocus={focusHandler}/>
                                                                        <input id="otpThirdChar" name="otpThirdChar" type="text" className="form-control" maxLength="1" onChange={changeHandler}
                                                                        value={state.otpThirdChar} ref={thirdInputRef} onFocus={focusHandler}/>
                                                                        <input id="otpFourthChar" name="otpFourthChar" type="text" className="form-control" maxLength="1" onChange={changeHandler}
                                                                        value={state.otpFourthChar} ref={fourthInputRef} onFocus={focusHandler}/>
                                                                        <input id="otpFifthChar" name="otpFifthChar" type="text" className="form-control" maxLength="1" onChange={changeHandler}
                                                                        value={state.otpFifthChar} ref={fifthInputRef} onFocus={focusHandler}/>
                                                                        
                                                                  </div>
                                                                  {frontError.error && <p className="invalid-otp">{frontError.errorMessage}</p>}
                                                                  <div className="form-group__cta d-flex justify-content-center">
                                                                        <button type="submit" className="btn btn-xl-lg btn-primary" disabled={isLoading} onClick={submitOtpHandler}>Verify</button>
                                                                  </div>
                                                                  {/* {(isSentOtp && ui.ct_otp_modal_status === "otp") && 
                                                                        <p className="mb-0 text-center">Didn’t receive it? <Link className="blue" to="" onClick={sendOTPHandler}>Send it again</Link></p>}
                                                                  {(!isSentOtp && ui.ct_otp_modal_status === "otp") && 
                                                                        <p className="mb-0 text-center">Didn’t receive it? <Link className="blue" to="" onClick={(e)=>{e.preventDefault()}}>Send it again in {String(minutes).padStart(2, '0') +":"+ String(seconds).padStart(2, '0')}</Link></p>} */}
                                                            </form>
                                                      </div>
                                                      {/* <!-- /.content__body --> */}
                                                </div>
                                                {/* <!-- /.content --> */}

                                          </div>
                                          {/* <!-- /.modal-body --> */}

                                    </div>
                                    {/* <!-- /.modal-content --> */}
                              </div>
                              {/* <!-- /.modal-dialog --> */}
                        </div>
                        {/* <!-- modal #modalVerificationCode --> */}
                        <Backdrop />
                  </React.Fragment>
                 
      )
}

export default OtpMyAccountModal;
