import React, { useState, useEffect } from 'react'

// external css

// redux and react hooks
import { useDispatch } from 'react-redux';

// child component
import { HeadingPictureComponent } from '../../Components';
import Footer from '../../Layouts/Footer/Footer';

// packages
import { Helmet } from 'react-helmet';

// Actions & RTK query or mutations
import { useLazyGetPrivacyQuery } from '../../Services/modules/Others';
import { setAlert, updateLocoScroll } from '../../Store/UI';

// Constants
import { errors, messages } from '../../resources/en';
import { MetaData } from '../../resources/MetaData';

const Privacy = () => {

      const [scroll,setScroll]      =     useState(false)
      const dispatch                =     useDispatch();
      const [getPrivacy, { data, isSuccess, error, isLoading }] = useLazyGetPrivacyQuery();

      useEffect(()=>{
            document.title = "Privacy";
            getPrivacy();

      },[getPrivacy]);
      
      useEffect(()=>{
            if(isSuccess) {
                  if(data.status=== "1"){
                        setScroll(true);
                  }
                  else if(data.status === "0"){
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.message || errors.NotFound404}));
                  }
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}));
            }
      
      },[data, isSuccess, error, isLoading, dispatch])

      useEffect(()=>{
            if(scroll){
                  dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
            }
      },[scroll, dispatch])

      return (
            <>
                  {/* <!-- REQUIRED wrapper div for parallax --> */}
                  <div className={["scroll-animations position-relative"].join(" ")} data-scroll-container>
                        <Helmet>
                              <title>{MetaData.PrivacyPolicy.title}</title>
                              <meta name="description" content={MetaData.PrivacyPolicy.description} />
                              <meta name="keywords" content={MetaData.PrivacyPolicy.keywords} />
                        </Helmet>
                        <main>
                              <section data-scroll-section className="section__faq section__privacy pt-0">
                                    <div className="container-fluid container__block">
                                          <div className="row">
                                                <div className="col-md-12 privacy_page">
                                                      <div className="content content__faq mx-auto">
                                                            <div className="content__header text-center overflow-hidden">
                                                                  <div className="big-heading">
                                                                        <h1 className="mb-0">Privacy Policy</h1>
                                                                        <HeadingPictureComponent dataScroll className="ticket-orange" srcSet="ticket-faq-big-heading1-orange.svg" src="ticket-faq-big-heading1-green-sm.svg"/>

                                                                        <HeadingPictureComponent dataScroll className="ticket-blue" srcSet="ticket-faq-big-heading1-blue.svg" src="ticket-faq-big-heading1-blue-sm.svg"/>

                                                                        <HeadingPictureComponent dataScroll className="ticket-aquamarine" srcSet="ticket-faq-big-heading1-aquamarine.svg" src="ticket-faq-big-heading1-orange-sm.svg"/>

                                                                        <HeadingPictureComponent dataScroll className="ticket-yellow" srcSet="ticket-faq-big-heading1-yellow.svg" src="ticket-faq-big-heading1-aquamarine-sm.svg"/>

                                                                        <HeadingPictureComponent dataScroll className="ticket-red" srcSet="ticket-faq-big-heading1-red.svg" src="ticket-faq-big-heading1-red-sm.svg"/>

                                                                  </div>
                                                            </div>
                                                            {/* <!-- .content__header --> */}
                                                            <div className="content__body" style={{"minHeight": "40vh"}}>
                                                                  {data && <div dangerouslySetInnerHTML={{ __html: data.data }}></div> }
                                                            </div>
                                                            {/* <!-- .content__body --> */}
                                                      </div>
                                                      {/* <!-- .content.content__faq --> */}

                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}
                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>
                              {/* <!-- /.section__faq --> */}
                        </main>

                        <Footer/>

                  </div>
                  {/* <!-- /div[data-scroll-container] --> */}
            </>
      )
}

export default Privacy;
