import React, { useRef, useEffect } from 'react';

// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams, useNavigate } from 'react-router-dom';

// child component
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Footer from '../../Layouts/Footer/Footer';
import OtpModal from '../../Ui/Modal/OtpVerificationModal';
import AlmostDoneModal from './../../Ui/Modal/AlmostDoneModal';

// packages
import { Transition } from 'react-transition-group';
import { useGetLinkResponseMutation } from '../../Services/modules/Register';
import { Helmet } from 'react-helmet';

// Actions & RTK query or mutations
import { updateLocoScroll } from '../../Store/UI';
import { setReferral, setOldUserFetchedDetails } from "../../Store/User"
import { routesName } from '../../resources/RoutesName';

// Constants
import { MetaData } from '../../resources/MetaData';
import { Config } from '../../Config';

const Register = () => {

      const register                =     useSelector(state=>state.register);
      const { auth, domain }        =     useSelector(state=>state.user);
      const dispatch                =     useDispatch();
      const nodeRef                 =     useRef(null);
      const { pathname }            =     useLocation();
      const params                  =     useParams();
      const navigate                =     useNavigate()

      const [getLinkResponse, { data, isSuccess, error, isLoading }] = useGetLinkResponseMutation();

      useEffect(()=>{
            if(auth){
                  navigate(routesName.Home)
            }
            
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

      // Call API if old user came to complete registration
      useEffect(()=>{
            if(pathname.includes("verification") && params.code) {

                  getLinkResponse({
                       "link" : window.location.href
                  //      "link" : "https://upload.centraltickets.co/verification/Z1BCdlpSYU54RlJrUThJYjU2eCtiZz09"
                  })
                  // navigate(routesName.Register, { replace : true })
            }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

      // Handler Fetched GetLinkResponse Data
      useEffect(()=>{
            if(isSuccess) {
                  if(data.status === "1"){
                        dispatch(setOldUserFetchedDetails({...data.data}))
                  }
            }

            if(error) {
                  console.log(error)
            }
      },[data, isSuccess, error, isLoading, dispatch])

      useEffect(()=>{

            if(pathname.includes("invite")) {
                  dispatch(setReferral({referral:params.ReferralCode}))
            }

      },[dispatch, pathname, params])

      useEffect(()=>{

            dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}));
      },[register.step, dispatch])
      
      return (
                  <React.Fragment>
                       
                        {/* <!-- REQUIRED wrapper div for parallax --> */}
                        <div className={["scroll-animations position-relative"].join(" ")} data-scroll-container>
                              <Helmet>
                                    <title>{MetaData.Register.title}</title>
                                    <meta name="description" content={MetaData.Register.description} />
                                    <meta name="keywords" content={MetaData.Register.keywords} />
                              </Helmet>
                              
                              <main className={[register.step === "step3" && "main__register-last-part align-items-center"].join(" ")}>

                                    <section data-scroll-section className={["section__register py-0",register.step === "step3" && "my-auto" ].join(" ")}>

                                          {/* <!--
                                                NOTE: class "container__block" removes child row paddings and negative margins; and col-* paddings
                                          --> */}
                                          <div className="container-fluid container__block">
                                                <div className="row">
                                                      <div className="col-md-12">
                                                            {register.step === "step1" && <Step1 />}
                                                            {register.step === "step2" && <Step2 isAjtix={domain === Config.DOMAIN_CONSTANT_AJTIX} isTfm={domain === Config.DOMAIN_CONSTANT_TFM}/>}
                                                            {register.step === "step3" && <Step3/>}
                                                            {/* <!-- /.content.content__register --> */}
                                                      </div>
                                                      {/* <!-- /.col-md-12 --> */}
                                                </div>
                                                {/* <!-- /.row --> */}
                                          </div>
                                          {/* <!-- /.container-fluid  --> */}

                                    </section>
                                    {/* <!-- /.section__register --> */}

                              </main>

                              <Footer/>                    

                        </div>
                        {/* <!-- /div[data-scroll-container] --> */}
                                          
                        {/* <!-- modalVerificationCode --> */}
                        {/* {state && <OtpModal state/>} */}
                        <Transition in={register.ct_otp_modal_status === "otp" || register.ct_otp_modal_status === "otpEmail"} timeout={500} mountOnEnter unmountOnExit nodeRef={nodeRef}>
                        {status => {
                                    return <OtpModal status={status}/> 
                              }
                        }
                        </Transition>

                        <Transition in={register.ct_otp_modal_status === "almostDone"} timeout={500} mountOnEnter unmountOnExit nodeRef={nodeRef}>
                              {status => {
                                          return <AlmostDoneModal status={status}/> 
                                    }
                              }
                        </Transition>

                        

                  </React.Fragment>
      )
}

export default Register;
