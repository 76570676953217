import React, { useRef } from 'react';

// external css
// redux and react hooks
// child component
import Backdrop from '../Backdrop';

// packages
import { Transition } from 'react-transition-group';

// Actions & RTK query or mutations
// Constants

const Modal = (props) => {

      const nodeRef = useRef(null);
            
      return (

                  
                        <Transition in={props.show} timeout={500} mountOnEnter unmountOnExit nodeRef={nodeRef}>
                        {status => {

                              return (
                                    <React.Fragment>
                                          <div className={["modal modal__form-modal modal__almost-done show fade modal_error_handler",(status === "entering" || status === "entered") && "modalOpen",(status === "exiting" || status === "exited") && "modalClosed",props.type.value === "ticAlert" && "ticAlert", props.isAjtix && "ajtix-wrapper", props.isTfm && "tfm-wrapper" ].join(" ")} id="modalAlmostDone" style={{"display":"block"}} tabIndex="-1" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered">
                                                      <div className="modal-content">
                                                            <div className="modal-body d-flex align-items-center justify-content-center">
                                                                  <div className="content">
                                                                        <div className="content__body text-center">
                                                                              <h2>{props.title ? props.title : "Attention!"}</h2>
                                                                              <p dangerouslySetInnerHTML={{__html: props.children}}></p>
                                                                              <div className="buttons_wraper">
                                                                                    <button className="btn btn-primary fw-bold" onClick={props.closeBackdrop}>
                                                                                          {props.type.value === "confirm" && "Cancel"}
                                                                                          {props.type.value === "alert" && "Close"}
                                                                                          {props.type.value === "ticAlert" && "Current bookings"}
                                                                                    </button>
                                                                                    {props.type.value === "confirm" &&
                                                                                    <button className="btn btn-primary fw-bold" onClick={props.confirmMessage} disabled={props.type.status}>
                                                                                          {!props.type.status && "Confirm"}
                                                                                          {props.type.status && "Please wait."}
                                                                                    </button>}
                                                                                    {props.type.value === "ticAlert" && 
                                                                                    <button className="btn btn-primary fw-bold" onClick={props.confirmMessage} disabled={props.type.status}>
                                                                                          Buy more tickets
                                                                                    </button>}
                                                                              </div>
                                                                        </div>
                                                                        {/* <!-- /.content__body --> */}
                                                                  </div>
                                                                  {/* <!-- /.content --> */}

                                                            </div>
                                                            {/* <!-- /.modal-body --> */}
                                                      </div>
                                                      {/* <!-- /.modal-content --> */}
                                                </div>
                                                {/* <!-- /.modal-dialog --> */}
                                          </div>
                                          <Backdrop />
                                    </React.Fragment>
                                    )
                              }
                        }
                        </Transition>
                 
                 
      )
}

export default Modal;
