import React, { useEffect, useState } from 'react'

// external css or import images
import ticketRegisterBigHeading1Orange from "./../../assets/images/tickets/ticket-register-big-heading1-orange.svg"
import ticketRegisterBigHeading1Aquamarine from "./../../assets/images/tickets/ticket-register-big-heading1-aquamarine.svg"
import ticketRegisterBigHeading1Yellow from "./../../assets/images/tickets/ticket-register-big-heading1-yellow.svg"

import ticket1StarRed from "../../assets/images/ticket-1star-red.svg"
import ticket1StarRedAjtix from "../../assets/images/ticket-1star-red-ajtix.svg"

// redux and react hooks
import { useSetMemberDataMutation } from '../../Services/modules/Moreinfo'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// child component
import Footer from "../../Layouts/Footer/Footer";

// packages
// Actions & RTK query or mutations
import { setAlert } from '../../Store/UI'
import { setCtUserInputFields } from "../../Store/User"

// Constants
import { messages, errors } from '../../resources/en'
import { routesName } from '../../resources/RoutesName'
import { Config } from '../../Config'

const MoreInfo = () => {

      const {ct_user_input_fields, domain }     =     useSelector(state=>state.user)
      const [memberData, setMemberData]         =     useState(ct_user_input_fields)
      const [frontError,setFrontError]          =     useState([])
      const dispatch                            =     useDispatch()
      const navigate                            =     useNavigate()

      const [setMembers, { data, error, isSuccess, isLoading }] = useSetMemberDataMutation();

      useEffect(()=>{

            if(isSuccess){
                  if(data.status === "1") {
                        dispatch(setCtUserInputFields({ct_user_input_fields : null}))
                        dispatch(setAlert({model:true,title:messages.modalSuccessTitle,message:data.message || errors.NotFound404 }))
                        navigate(routesName.CurrentListing)
                  } else {
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.message || errors.NotFound404 }))
                  }
            } 

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404 }))
            }

      },[data,error, isSuccess,isLoading, dispatch,navigate])

      const ageChangeHandler = (event)=> {
            let val = validate(event.target.value)?.input;

            const copyUserFields = memberData
            let ageField = {...copyUserFields[2]}

            const copyBody = [...ageField.body]
            copyBody[0] = {...copyBody[0], value : val }

            const updateAgeField = {...ageField, body : copyBody}

            const updateuserFields = [...copyUserFields.slice(0, 2), updateAgeField]

            setMemberData(updateuserFields)
            clearErrorHandler()
      }

      const identifyChangeHandler = (index)=> {
            const copyMemberData = [...memberData];
            let identifyField = {...copyMemberData[1]}


            // Create a shallow copy of the body array in the object
            let updatedBody = [...identifyField.body];

            // Create a shallow copy of the specific body element and modify its value
            updatedBody = updatedBody.map((el,ind)=>{
                  if(ind === index){
                        return {...el,selected:true}
                  } else {
                        return {...el,selected : false}
                  }
            })

            // Update the body element at the specified index with the modified element
            identifyField.body = updatedBody
            copyMemberData[1] = identifyField;

            setMemberData(copyMemberData)
            clearErrorHandler()
      }

      const undDepComHandler = (index)=> {
            const copyMemberData = [...memberData];
            let undDepField = {...copyMemberData[0]}

            // Create a shallow copy of the body array in the object
            let updatedBody = [...undDepField.body];
             // Create a shallow copy of the specific body element and modify its value
            updatedBody = updatedBody.map((el,ind)=>{
                  if(ind === index){
                        return {...el,selected:!el.selected}
                  } else {
                        if(index === 8) {
                              return {...el, selected: false}
                        }
                        return {...el}
                  }
            })

            undDepField.body = updatedBody
            copyMemberData[0] = undDepField;
            setMemberData(copyMemberData)
            clearErrorHandler()
      }

      const updateHandler = ()=> {
            const errorLengthArray = [];

            let undDepSelected = []
            let identifySelected = []
            
            memberData[0].body.forEach(element => {
                  if(element.selected) {
                        undDepSelected.push(element)
                  }
            });

           
            memberData[1].body.forEach(element => {
                  if(element.selected) {
                        identifySelected.push(element)
                  }
            });

            if(identifySelected.length === 0) {
                  errorLengthArray.push('undDep')
            }

            if(identifySelected.length === 0) {
                  errorLengthArray.push('identify')
            }

            if(errorLengthArray.length > 0) {
                  setFrontError(errorLengthArray)
            } else {
                  setMembers({user_input_fields : memberData})
            }

      }

      const clearErrorHandler = ()=> {
            setFrontError([])
      }
      
      const validate = (s) => {
            var rgx = /^[0-9]*\.?[0-9]*$/;
            return s.match(rgx);
      }


      // console.log(memberData.user_input_fileds[0])

      return (
            <div className={["scroll-animations position-relative"].join(" ")} data-scroll-container>
                  <main>
                        <section data-scroll-section className="section__register section__more-info py-0">
         
                              <div className="container-fluid container__block">
                                    <div className="row">
                                          <div className="col-md-12">
                                                <div className="content content__register content__register-part2 mx-auto">
                                                      <div className="content__header content__header--template border-bottom text-center overflow-hidden">
                                                            <div className="big-heading">
                                                                  <h1 className="h2 mb-0">More Info</h1>
                                                                  <img data-scroll className="ticket ticket-orange" src={ticketRegisterBigHeading1Orange}
                                                                        alt="" />
                                                                  <img data-scroll className="ticket ticket-aquamarine" src={ticketRegisterBigHeading1Aquamarine}
                                                                        alt=""/>
                                                                  <img data-scroll className="ticket ticket-yellow" src={ticketRegisterBigHeading1Yellow}
                                                                        alt=""/>
                                                            </div>
                                                      </div>
                                                      <div className="content__body p-0 d-flex flex-column flex-lg-row">
                                                            <div className="col__left border-right-lg d-none d-lg-block">
                                                                  <div className="content">
                                                                        <div className="content__body">
                                                                              <div className="wrapper d-flex flex-column flex-md-row flex-lg-column">
                                                                                    <div className="wrapper">
                                                                                          <h3 className="heading">Tell us more about you</h3>
                                                                                          <p> 
                                                                                                These informations will allow us to have a
                                                                                                better idea of what kind of events you may be
                                                                                                interested and make sure we can personalise for
                                                                                                you our newsletter.
                                                                                          </p>
                                                                                    </div>
                                                                                    <div className="feature flex-shrink-md-0">
                                                                                          <img className="ticket-1-star" src={domain === Config.DOMAIN_CONSTANT_AJTIX ? ticket1StarRedAjtix  : ticket1StarRed} alt=""/>
                                                                                    </div>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                            </div>

                                                            <div className="col__right">
                                                                  <div className="content">
                                                                        <div className="content__body">
                                                                              <form>
                                                                                    <div className="row row__checkboxes">
                                                                                          <div className="col-md-12">
                                                                                                {!!memberData && 
                                                                                                <label className="heading fw-bold">
                                                                                                      {memberData[0].text}
                                                                                                </label> }
                                                                                                {!!memberData && memberData[0].body.map((el,index)=>{

                                                                                                      return (
                                                                                                            <div className="form-group form-group__custom-controls" key={`${el.id}${el.label}${index}`} >
                                                                                                                  <div className="custom-control custom-checkbox custom-checkbox__radio">
                                                                                                                        <input type={el.type} className={["custom-control-input",el.selected && "checked"].join(" ")} id={`considerYourself${el.id}`} onClick={undDepComHandler.bind(this,index)}/>
                                                                                                                        <label className="custom-control-label" htmlFor={`considerYourself${el.id}`}>
                                                                                                                              {el.label}
                                                                                                                        </label>
                                                                                                                  </div>
                                                                                                            </div>
                                                                                                      )
                                                                                                })}
                                                                                                { frontError.includes('undDep') && <label className="custom-control-label form-label-error">
                                                                                                      Please select your choice from above
                                                                                                </label>}
                                                                                          </div>
                                                                                    </div>

                                                                                    <div className="row row__form-groups">
                                                                                          <div className="col-md-12">
                                                                                                <div className="form-group" style={{"paddingTop": "30px"}}>
                                                                                                      {!!memberData && 
                                                                                                      <label className="heading fw-bold">
                                                                                                            {memberData[1].text}
                                                                                                      </label>}
                                                                                                      <ul className="list__tags justify-content-xl-center">
                                                                                                            {!!memberData && memberData[1].body.map((el,index)=>{

                                                                                                                  return (
                                                                                                                        <li key={`${el.id}${el.label}${index}`} >
                                                                                                                              <div className="btn-check-wrapper" >
                                                                                                                                    <input className={["form-check-input btn-check", el.selected && "btn-checked"].join(" ")} type={memberData[1].type} checked={el.selected} onChange={()=>{}}
                                                                                                                                          id={`doYouIdentify${el.id}`} />
                                                                                                                                    <label className="btn btn-sm btn-outline-primary hover-primary"
                                                                                                                                          htmlFor={`doYouIdentify${el.id}`} onClick={identifyChangeHandler.bind(this,index)}>
                                                                                                                                                {el.label}
                                                                                                                                    </label>
                                                                                                                              </div>
                                                                                                                        </li>
                                                                                                                  )
                                                                                                            })}
                                                                                                      </ul>
                                                                                                      { frontError.includes('identify') && <label className="custom-control-label form-label-error">
                                                                                                            Please select your choice from above
                                                                                                      </label>}
                                                                                                </div>

                                                                                                <div className="form-group" style={{"paddingTop": "15px"}}>
                                                                                                      {!!memberData && 
                                                                                                      <label className="heading fw-bold">
                                                                                                            {memberData[2].text}
                                                                                                      </label>}
                                                                                                      {!!memberData &&
                                                                                                      <input type="text" className="form-control tomselect tomselect-xl-lg" value={memberData[2].body[0].value || ""} onChange={ageChangeHandler}
                                                                                                            autoComplete="off" placeholder="Enter your age here" 
                                                                                                            style={{"marginTop": "18px"}}/>}
                                                                                                </div>

                                                                                                {/* <div className="form-group" style={{"paddingTop": "30px"}}>
                                                                                                      <label className="heading fw-bold">
                                                                                                            Do you consider yourself to be any of the
                                                                                                            following?  (please select all that
                                                                                                            apply).
                                                                                                      </label>
                                                                                                      <ul className="list__tags justify-content-xl-center">
                                                                                                            <li>
                                                                                                                  <div className="btn-check-wrapper">
                                                                                                                        <input className="form-check-input btn-check"
                                                                                                                              type="checkbox" name="checkArea" id="checkAreaCentralLondon"/>
                                                                                                                        <label className="btn btn-sm btn-outline-primary hover-primary"
                                                                                                                              htmlFor="checkAreaCentralLondon">
                                                                                                                                    65+
                                                                                                                        </label>
                                                                                                                  </div>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                  <div className="btn-check-wrapper">
                                                                                                                        <input className="form-check-input btn-check" type="checkbox"
                                                                                                                              name="checkArea" id="Single"/>
                                                                                                                        <label className="btn btn-sm btn-outline-primary hover-primary"
                                                                                                                              htmlFor="Single">
                                                                                                                                    Single parent family
                                                                                                                        </label>
                                                                                                                  </div>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                  <div className="btn-check-wrapper">
                                                                                                                        <input className="form-check-input btn-check"
                                                                                                                              type="checkbox" name="checkArea" id="POC"/>
                                                                                                                        <label className="btn btn-sm btn-outline-primary hover-primary"
                                                                                                                              htmlFor="POC" >
                                                                                                                                    POC (person of colour)
                                                                                                                        </label>
                                                                                                                  </div>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                  <div className="btn-check-wrapper">
                                                                                                                        <input className="form-check-input btn-check"
                                                                                                                              type="checkbox" name="checkArea" id="Hearing"/>
                                                                                                                        <label className="btn btn-sm btn-outline-primary hover-primary"
                                                                                                                              htmlFor="Hearing">
                                                                                                                                    Hearing impaired
                                                                                                                        </label>
                                                                                                                  </div>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                  <div className="btn-check-wrapper">
                                                                                                                        <input className="form-check-input btn-check"
                                                                                                                              type="checkbox" name="checkArea" id="Visually"/>
                                                                                                                        <label className="btn btn-sm btn-outline-primary hover-primary"
                                                                                                                              htmlFor="Visually">
                                                                                                                                    Visually impaired
                                                                                                                        </label>
                                                                                                                  </div>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                  <div className="btn-check-wrapper">
                                                                                                                        <input className="form-check-input btn-check"
                                                                                                                              type="checkbox" name="checkArea" id="Disabled"/>
                                                                                                                        <label className="btn btn-sm btn-outline-primary hover-primary"
                                                                                                                              htmlFor="Disabled">
                                                                                                                                    Disabled
                                                                                                                        </label>
                                                                                                                  </div>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                  <div className="btn-check-wrapper">
                                                                                                                        <input className="form-check-input btn-check"
                                                                                                                              type="checkbox" name="checkArea" id="income"/>
                                                                                                                        <label className="btn btn-sm btn-outline-primary hover-primary"
                                                                                                                              htmlFor="income">
                                                                                                                                    Low income (under £25.5K per annum)
                                                                                                                        </label>
                                                                                                                  </div>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                  <div className="btn-check-wrapper">
                                                                                                                        <input className="form-check-input btn-check"
                                                                                                                              type="checkbox" name="checkArea" id="Living"/>
                                                                                                                              <label className="btn btn-sm btn-outline-primary hover-primary"
                                                                                                                                    htmlFor="Living" >
                                                                                                                                    Living with a mental health condition
                                                                                                                              </label>
                                                                                                                  </div>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                  <div className="btn-check-wrapper">
                                                                                                                        <input className="form-check-input btn-check"
                                                                                                                              type="checkbox" name="checkArea" id="Prefer"/>
                                                                                                                        <label className="btn btn-sm btn-outline-primary hover-primary"
                                                                                                                              htmlFor="Prefer">
                                                                                                                                    Prefer not to say
                                                                                                                        </label>
                                                                                                                  </div>
                                                                                                            </li>
                                                                                                      </ul>
                                                                                                </div> */}
                                                                                          </div>
                                                                                    </div>

                                                                                    <div className="row row__cta">
                                                                                          <div className="col-md-12">
                                                                                                <div className="form-group form-group__cta">
                                                                                                      <button type="button" className="btn btn-xl-lg btn-primary" onClick={updateHandler} disabled={isLoading}>
                                                                                                            Update
                                                                                                      </button>
                                                                                                </div>
                                                                                          </div>
                                                                                    </div>
                                                                              </form>
                                                                        </div>
                                                                        {/* <!-- /.content__body --> */}
                                                                  </div>
                                                                  {/* <!-- /.content --> */}
                                                            </div>
                                                            {/* <!-- /.col__right --> */}
                                                      </div>
                                                      {/* <!-- /.content__body --> */}
                                                </div>
                                                {/* <!-- /.content.content__register --> */}
                                          </div>
                                          {/* <!-- /.col-md-12 --> */}
                                    </div>
                                    {/* <!-- /.row --> */}
                              </div>
                              {/* <!-- /.container-fluid  --> */}
                        </section>
                  </main>

                  <Footer />
            </div>
      )
}

export default MoreInfo
