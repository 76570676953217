import React from "react";
import ChangePasswordForm from "../Components/ChangePasswordForm";

const ChangePassword = ()=>{

      return (
            <div className="tab-pane tab-pane__ChangePassword fade show active" id="myAccntChangePassword" role="tabpanel" aria-labelledby="myAccntChangePassword-tab">

                  <div className="tab-pane__body">
                        <div className="content">
                              <h4 className="heading">Change Password</h4>
                              <ChangePasswordForm type="desktop"/>
                        </div>
                        {/* <!-- /.content --> */}

                  </div>
                  {/* <!-- /.tab-pane__body --> */}

            </div>
      )
}

export default ChangePassword;