import React, { useState, useEffect } from  'react';

// external css

import iconArrowLeft35X24 from "../../assets/images/icon-arrow-left-35x24-black.svg";
import iconArrowLeft23X16 from "../../assets/images/icon-arrow-left-23x16-black.svg";

// redux and react hooks
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

// child component
import { ImageComponent } from "./../../Components";
import {
      EventDetailImageDummy, EventDetailTitleDummy, 
      EventDetailLocationDummy, EventDetailTagsDummy, 
      EventDetailDescriptionDummy,
      EventDetailNavItemDummy,
      EventDetailListGroupItemDummy,
      EventDetailHeadingDummy,
      EventDetailCollectionInstructionDummy,
      EventDetailButtonDummy 

} from './DummyComponents';
import Footer from "../../Layouts/Footer/Footer";
import BookingModule from "./BookingModule/BookingModule";

// packages
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Transition } from 'react-transition-group';
import { Helmet } from 'react-helmet';

// Actions & RTK query or mutations
import { useFetchEventDetailMutation } from "../../Services/modules/EventDetails";
import { useEventNotifymeWebMutation, useRemoveEventNotifymeWebMutation } from "../../Services/modules/currentlisting";
import { setEventsData, setEventNotifyResponse, setBookingHandler, reset } from "../../Store/EventDetail";
import { setBookingStep,setGetBookingDetails, reset as resetBookingModule } from '../../Store/BookingModule';
import { setAlert, setSpinner } from "../../Store/UI";

// Constants
import { errors, messages } from '../../resources/en';
import { routesName } from '../../resources/RoutesName';
import { NameConstants } from '../../resources/NameConstants';
import { MetaData } from '../../resources/MetaData';

const EventDetails = (props) => {

      const [tab,setTabs]                 =     useState("Information");
      const [script,setScript]            =     useState(false)
      const state                         =     useSelector(state=>state.eventDetail);
      const confirm                       =     useSelector(state=>state.ui.alert.type.status);
      const browserDetect                 =     useSelector(state=>state.ui.browserDetect);

      const [fetchEventDetail, { data, isSuccess, isLoading, isFetching, error }]  =    useFetchEventDetailMutation();
      const [eventNotifyWeb, { data : notifyData , isSuccess : isNotifySuccess, isLoading : isNotifyLoading, isFetching : isNotifyFetching, error : notifyError}] = useEventNotifymeWebMutation();
      const [removeEventNotifyWeb, { data : removeNotifyData , isSuccess : isRemoveNotifySuccess, isLoading : isRemoveNotifyLoading, isFetching : isRemoveNotifyFetching, error : removeNotifyError}] = useRemoveEventNotifymeWebMutation();
      const { eventId } = useParams()

      const dispatch = useDispatch();
      const location = useLocation();
      const navigate = useNavigate();
      let scrollListener;

      // Check event details URL is hit by manage tickets -  Buy More tickets
      useEffect(()=>{
           
            if(location.state && location.state === "buyMoreTickets" && state.eventInfo) {
                  bookingHandler()
                  navigate('',{ replace : true })
            }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[location,state.eventInfo])

      // clear event details state and booking module state while event detail page unmount
      useEffect(()=>{   
            window.scrollTo(0, 0);
            localStorage.setItem('eventId',eventId)
            return ()=> {
                  dispatch(reset())
                  dispatch(resetBookingModule())


                  // Remove This scroller listner while component will unmount for sticky nav info wrapper
                  window.removeEventListener('scroll', scrollListener);
            }     

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

      // Clear Eventdetails Data while component mount
      useEffect(()=>{

            dispatch(setEventsData({}))
      },[dispatch])

      useEffect(()=>{
            
            fetchEventDetail({event_id:eventId})
      },[fetchEventDetail,eventId])
      
      // // Check Redirect from 3D secure Booking
      useEffect(()=>{
            
            if(location.pathname.includes("payment-success") || location.pathname.includes("payment-failure")) {

                  const searchParams = new URLSearchParams(location.search);  
                  const bookingStatus = location.pathname.includes("/payment-success") ? "success" : "failure";
                  const booking_id = searchParams.get('booking_id') || "";
                  const client = searchParams.get('client');
                  const message = bookingStatus === "success" ?  messages.SuccessTicketBooking : messages.FailureTicketBooking;

                  if(client === "web"){
                        if(bookingStatus === "success"){
                              dispatch(setGetBookingDetails({getBookingDetails: { booking_id : booking_id } }))
                              dispatch(setBookingStep({step:NameConstants.bookingModuleSteps.bookingSuccess}))
                              dispatch(setBookingHandler({isBooking:true}));
                        } 
                        else {
                              dispatch(setAlert({model:true,title:messages.modalAlertTitle,message: message}))
                        }
                  }
                  navigate(`/event-details/${eventId}`, { replace: true });
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[location.pathname])
      
      useEffect(()=>{
            if(isSuccess){
                  if(data.status === "1"){
                        dispatch(setEventsData(data.data))
                        // dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
                  } else {
                        navigate(routesName.Home)
                  }
            }

            if(error){
                  navigate(routesName.Home)
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404 }))
            }

      },[ data, isSuccess, isLoading, isFetching, error, dispatch, navigate])

      useEffect(()=>{
            if(isNotifySuccess){
                  if(notifyData.status === "1"){
                        dispatch(setEventNotifyResponse({is_waiting_joined:"1"}))
                  }
                  dispatch(setAlert({model:true,title:messages.modalSuccessTitle,message:notifyData.message}))

            }

            if(notifyError){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:notifyError.error || notifyError.data.error || notifyError.data.message || errors.NotFound404 }))
            }

      },[ notifyData, isNotifySuccess, isNotifyLoading, isNotifyFetching, notifyError, dispatch])

      useEffect(()=>{
            if(isRemoveNotifySuccess){
                  if(removeNotifyData.status === "1"){
                        dispatch(setEventNotifyResponse({is_waiting_joined:"0"}))
                  }
                  dispatch(setAlert({model:true,title:messages.modalSuccessTitle,message:removeNotifyData.message}))
            }

            if(removeNotifyError){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:removeNotifyError.error || removeNotifyError.data.error || removeNotifyError.data.message || errors.NotFound404 }))
            }

      },[ removeNotifyData, isRemoveNotifySuccess, isRemoveNotifyLoading, isRemoveNotifyFetching, removeNotifyError, dispatch])


      // To add fixed and bottom fixed class to nav-tabs__info-venue-wrapper according to scroll event
      var windowWidth = window.innerWidth;
      useEffect(()=>{

            if(state.eventInfo && state.venueDetails && state.scheduleInfo && !script){
                  
                  if (windowWidth > 991) {

                        var resizeTimer;
                        // $(window).resize(function() {
                              clearTimeout(resizeTimer);
                              resizeTimer = setTimeout(function() {
                              //location.reload();
                              fnStickyInfoVenueTabs();
                              }, 100);
                        // });
                  }

                  setScript(true);
                  
                  // handleLoadScript();
            }
      
           
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[script, state.eventInfo, state.venueDetails, state.scheduleInfo])


      const tabHandler = (e)=>{
            if(e.target.id === "bt-information-tab"){
                  setTabs("Information")
            } else {
                  setTabs("Venue")
            }
      }

      const bookingHandler = (e)=>{
            if(e){
                  e.preventDefault();
            }

            if(state.eventInfo && state.scheduleInfo && state.eventInfo.is_soldout !== "1") {
                  
                  dispatch(setBookingHandler({isBooking:true}));
                  dispatch(setSpinner({status : "request"}));
            }
            else if(state.eventInfo.is_soldout === "1"){

                  let message = messages.joinWaitingList;
                  if(is_waiting_joined === "1"){
                        message = messages.removeFromwaitingList;
                  }
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:message,type:{value:"confirm",status:false}}))
            }
      }

      useEffect(()=>{
            if(confirm){
                  confirmationForWaitingHandler();
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[confirm])


      const confirmationForWaitingHandler= ()=>{
                
            if(state.eventInfo.is_soldout === '1'){
                  const event_id  = eventId;
                  const data      = {event_id:event_id};

                  if(is_waiting_joined === "1") {
                        // ................call API Remove From Waiting List..........//
                        removeEventNotifyWeb(data)
                  }
                  else {
                        // .................call API Join Waiting List...............//
                        eventNotifyWeb(data);
                  }
            }
            else{
                  dispatch(setAlert({model:false,title:"",message:''}))
            }
            
      }

      const backHandler = ()=> {
            navigate(routesName.CurrentListing)
      }

      // To add fixed and bottom fixed class to nav-tabs__info-venue-wrapper according to scroll event
      function fnStickyInfoVenueTabs() {

            var offsetVal = 110;
            // var contentWidth = document.querySelector('.fixed-scroll-target').offsetWidth;
            var contentHeight = document.querySelector('.fixed-scroll-target').offsetHeight;
            var navTabsHeight = document.querySelector('.nav-tabs__info-venue-wrapper').offsetHeight;
            var navTabsBottomPos = contentHeight - navTabsHeight;

            document.querySelector('.fixed-scroll-target').style.minHeight = navTabsHeight + 'px';

            scrollListener = function() {
                  var trigger = window.pageYOffset;

                  if(document.querySelector('.nav-tabs__info-venue-wrapper')){

                        if (trigger >= offsetVal) {
                              document.querySelector('.nav-tabs__info-venue-wrapper').classList.add('fixed');
                        } else {
                              document.querySelector('.nav-tabs__info-venue-wrapper').classList.remove('fixed');
                        }
      
                        if (trigger >= navTabsBottomPos) {
                              document.querySelector('.nav-tabs__info-venue-wrapper').classList.add('fixed-bottom-tab');
                        } else {
                              document.querySelector('.nav-tabs__info-venue-wrapper').classList.remove('fixed-bottom-tab');
                        }
                  }
            }

            window.addEventListener('scroll',scrollListener);

            
      } // END fnStickyInfoVenueTabs()
      
      let title = "";
      let price = "";
      let event_img = "";
      // let blur_event_image = "";
      let display_date = "";
      let venue_address = "";
      let category = "";
      let min_age = "";
      let description= '';
      let collection_instruction= '';
      let is_soldout = "";
      let map_link = "";
      let is_waiting_joined = "";
      let citymaper = ""
      let videoLink = "";
      
      if(state.eventInfo && state.venueDetails && state.scheduleInfo) {

            title=state.eventInfo.title;
            price=state.eventInfo.price;
            event_img=state.eventInfo.event_image;
            // blur_event_image=state.eventInfo.blur_event_image;
            display_date = state.eventInfo.display_date;
            venue_address = state.eventInfo.address;
            category = state.eventInfo.category;
            min_age = state.eventInfo.min_age;
            description = state.eventInfo.description;
            collection_instruction = state.eventInfo.collection_instruction;
            is_soldout = state.eventInfo.is_soldout;
            is_waiting_joined = state.eventInfo.is_waiting_joined;
            map_link = state.eventInfo.map_link !== "https://centraltickets.co.uk/" ? state.eventInfo.map_link : "";
            citymaper = state.eventInfo.citymaper
            videoLink = state.eventInfo.link || "";
      }
      
      return (
            
            <React.Fragment>
                 
                  {/* <!-- Checkout slidein begins here --> */}
                  <Transition in={state.isBooking} timeout={500} mountOnEnter unmountOnExit>
                        {status => {
                              return (
                                    <BookingModule show={status} eventId={eventId}/>
                              )}
                        }
                  </Transition>
                  {/* <!-- /.slidein-wrapper --> */}

                  {/* <!-- REQUIRED wrapper div for parallax --> */}
                  <div className={["scroll-animations position-relative event-details-main-container", state.isBooking ? "slidein__book-tickets__is-open":""].join(" ")} style={{"maxHeight": state.isBooking ? "85vh" : "", "overflow" : state.isBooking ? "hidden" : ""}}>
                        <Helmet>
                              <title>{MetaData.Book.title}</title>
                              <meta name="description" content={MetaData.Book.description} />
                              <meta name="keywords" content={MetaData.Book.keywords} />
                        </Helmet>
                        <main className={["main__event-details",state.isBooking ? "stop-scroll" : ""].join(" ")}>
                        {/* <main className="main__event-details"> */}

                              <section data-scroll-section className="section__event-details">
                                    <div className="container" id="tabTicketInfoVenue">
                                          <div className="row">
                                                <div className="col-lg-12 fixed-scroll-target">

                                                      <div className="content content__event-details">
                                                            <div className="content__body">
                                                                  <div className="event">
                                                                        {/* Added Back Button here */}
                                                                        <div className="buttons-wrapper buttons-wrapper__nav back-button" style={{marginBottom: "10px"}} onClick={backHandler}>
                                                                              <button className="btn btn-flat btn-has-icon p-0">
                                                                                    <picture>
                                                                                          <source media="(min-width: 1200px)" srcSet={iconArrowLeft35X24}/>
                                                                                          <img className="icon-arrow__left" src={iconArrowLeft23X16} alt=""/>
                                                                                    </picture>
                                                                                    <span className="text-wrapper">Back</span>
                                                                              </button>
                                                                        </div>
                                                                        <div className="event__header flex-xl-row">
                                                                              <div className={["event__header-image",is_soldout === "1" ? "card__event-image" : ""].join(" ")}>
                                                                                    {is_soldout === "1" && <div className='badge badge__ticket-sold-out'>Sold out!</div>}
                                                                                    {event_img ?<LazyLoadImage className={["event-image w-100",is_soldout === "1" ? "event-details__soldout__event-image" : ""].join(" ")} srcSet={`${event_img} 2x`} src={event_img} />
                                                                                    :
                                                                                          <EventDetailImageDummy />} 
                                                                                    {/* {event_img ? <img className="event-image w-100" srcSet={`${event_img} 2x`} src={event_img} alt=""/> :
                                                                                    <EventDetailImageDummy />} */}
                                                                              </div>
                                                                              <div className="event__header-text" style={{width: title ? "" : "100%"}}>
                                                                                    {title ? <h2 className="h1 event__name">{title}</h2> :
                                                                                    <EventDetailTitleDummy />}
                                                                                    <hr className="event__name-hr"/>
                                                                                    {venue_address ? <h6 className="event__location">{venue_address}</h6> :
                                                                                    <EventDetailLocationDummy />}
                                                                                    <ul className="list__event-tags">
                                                                                          {category ? category.split(",").map((cat,index)=>{
                                                                                                return (
                                                                                                      <li key={index}>
                                                                                                            <div className="badge badge-outline fw-normal m-1">
                                                                                                                  {cat}
                                                                                                            </div>
                                                                                                      </li>
                                                                                                )  
                                                                                          }):
                                                                                          <EventDetailTagsDummy />}
                                                                                    </ul>
                                                                              </div>
                                                                        </div>
                                                                        {/* <!-- .event__header --> */}

                                                                        <div className="nav-tabs__info-venue-wrapper">
                                                                              <div className="sticky__tabTicketInfoVenue" data-scroll data-scroll-sticky data-scroll-target="#tabTicketInfoVenue" data-scroll-offset="-156">
                                                                                    <div className="nav-tabs__info-venue">
                                                                                          <ul className="nav nav-tabs" id="bookTicketsTab" role="tablist">
                                                                                                <li className="nav-item" role="presentation">
                                                                                                      {state.eventInfo ?<button className={["nav-link",tab === "Information" && "active"].join(" ")} id="bt-information-tab" data-bs-toggle="tab" data-bs-target="#bookTicketInformation" type="button" role="tab" aria-controls="bookTicketInformation"
                                                                                                      aria-selected="true" onClick={tabHandler}>
                                                                                                            Information
                                                                                                      </button> :
                                                                                                      <EventDetailNavItemDummy active/>}
                                                                                                </li>
                                                                                                <li className="nav-item" role="presentation">
                                                                                                      {state.eventInfo ?<button  className={["nav-link",tab === "Venue" && "active"].join(" ")} id="bt-venue-tab" data-bs-toggle="tab" data-bs-target="#bookTicketVenue" type="button" role="tab" aria-controls="bookTicketVenue" aria-selected="false" onClick={tabHandler}>
                                                                                                            Venue
                                                                                                      </button> :
                                                                                                      <EventDetailNavItemDummy/>}
                                                                                                </li>
                                                                                          </ul>
                                                                                          <div className="tab-content" id="bookTicketsTabContent">
                                                                                                <div className={["tab-pane tab-pane__info fade",tab === "Information" && "show active"].join(" ")} id="bookTicketInformation" role="tabpanel" aria-labelledby="bt-information-tab">
                                                                                                      <div className="tab-pane__body">
                                                                                                            <div className="list-group list-group__event-details list-group-flat">
                                                                                                                  {display_date ?<div className="list-group-item list-group__time">
                                                                                                                        <div className="icon-wrapper" style={{marginRight:browserDetect ? "15px":""}}>
                                                                                                                              <ImageComponent className="icon__clock" src="icon-clock-black.svg" type="images"/>
                                                                                                                        </div>
                                                                                                                        <p className="mb-0">
                                                                                                                              {display_date}
                                                                                                                        </p>
                                                                                                                  </div> : <EventDetailListGroupItemDummy /> }
                                                                                                                  {price ? <div className="list-group-item list-group__price">
                                                                                                                        <div className="icon-wrapper" style={{marginRight:browserDetect ? "15px":""}}>
                                                                                                                              <ImageComponent className="icon__payment-card" src="icon-payment-card-black.svg" type="images"/>

                                                                                                                        </div>
                                                                                                                        {parseFloat(price) <= 6.5 && 
                                                                                                                        <p className="mb-0">£0 Ticket + £{price} Admin Fee </p>}
                                                                                                                        {parseFloat(price) >= 6.51 && 
                                                                                                                        <p className="mb-0">£{price} Ticket</p>}
                                                                                                                  </div> :
                                                                                                                  <EventDetailListGroupItemDummy /> }
                                                                                                            </div>
                                                                                                            {/* <!-- /.list-group --> */}
                                                                                                            <hr/>
                                                                                                            {collection_instruction ? 
                                                                                                            <h4 className="heading">Collection instructions:</h4> :
                                                                                                            <EventDetailHeadingDummy />}
                                                                                                            {collection_instruction ? 
                                                                                                            <p>
                                                                                                                  {collection_instruction}
                                                                                                            </p> :
                                                                                                            <EventDetailCollectionInstructionDummy/>}
                                                                                                      </div>
                                                                                                      {/* <!-- /.tab-pane__body --> */}
                                                                                                      <div className="tab-pane__cta mt-auto">
                                                                                                            {state.eventInfo ?
                                                                                                            <button type="button" className="btn btn-primary btn-book-show" onClick={bookingHandler}>
                                                                                                                  {is_soldout === "1" && is_waiting_joined !=="1" && " JOIN WAITING LIST"}
                                                                                                                  {is_soldout === "1" && is_waiting_joined ==="1" && "  REMOVE FROM WAITING LIST"}
                                                                                                                  {is_soldout !== "1" && "Book now"}
                                                                                                            </button> :
                                                                                                            <EventDetailButtonDummy />}
                                                                                                      </div>
                                                                                                      {/* <!-- /.tab-pane__cta --> */}
                                                                                                </div>
                                                                                                {/* <!-- /.tab-pane#bookTicketInformation --> */}
                                                                                                <div className={["tab-pane tab-pane__venue fade",tab === "Venue" && "show active"].join(" ")} id="bookTicketVenue" role="tabpanel" aria-labelledby="bt-venue-tab">
                                                                                                      <div className="tab-pane__body">
                                                                                                            <div className="list-group list-group__event-details list-group-flat">
                                                                                                                  <div className="list-group-item list-group__location">
                                                                                                                        <div className="icon-wrapper" style={{marginRight:browserDetect ? "15px":""}}> 
                                                                                                                              <ImageComponent className="icon__location-pin" src="icon-location-pin-black.svg" type="images"/>

                                                                                                                        </div>
                                                                                                                        <p className="mb-0">  
                                                                                                                              {venue_address}
                                                                                                                        </p>
                                                                                                                  </div>
                                                                                                                  <div className="list-group-item list-group__cta">
                                                                                                                        <div className="icon-wrapper"></div>
                                                                                                                        <a className="btn-citymapper" href={citymaper} target='_blank' rel='noreferrer'>
                                                                                                                              <ImageComponent className="img__citymapper img-fluid" src="citymapper-btn.webp" type="images"/>
                                                                                                                        </a>
                                                                                                                  </div>
                                                                                                            </div>
                                                                                                            {/* <!-- /.list-group --> */}
                                                                                                            <div className="venue-map">
                                                                                                                  <iframe title="myFrame" style={{"width": "100%", "height": "287px"}}
                                                                                                                  width="100%" height="289" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src={map_link}>
                                                                                                                  </iframe>
                                                                                                            </div>
                                                                                                      </div>
                                                                                                      {/* <!-- /.tab-pane__body --> */}
                                                                                                      <div className="tab-pane__cta mt-auto">
                                                                                                            {state.eventInfo ?
                                                                                                            <button type="button" className="btn btn-primary btn-book-show" onClick={bookingHandler}>
                                                                                                                  {is_soldout === "1" && is_waiting_joined !=="1" && " JOIN WAITING LIST"}
                                                                                                                  {is_soldout === "1" && is_waiting_joined ==="1" && "  REMOVE FROM WAITING LIST"}
                                                                                                                  {is_soldout !== "1" && "Book now"}
                                                                                                            </button> :
                                                                                                            <EventDetailButtonDummy />}
                                                                                                      </div>
                                                                                                      {/* <!-- /.tab-pane__cta --> */}
                                                                                                </div>
                                                                                                {/* <!-- /.tab-pane#bookTicketVenue --> */}
                                                                                          </div>
                                                                                          {/* <!-- /.tab-content --> */}
                                                                                    </div>
                                                                                    {/* <!-- /.nav-tabs__info-venue --> */}
                                                                                    <ul className="list__event-tags">
                                                                                          {category ? category.split(",").map((cat,index)=>{
                                                                                                return (
                                                                                                      <li key={index} style={{margin:browserDetect ? "0 10px 10px 0":""}}>
                                                                                                            <div className="badge badge-outline fw-normal">
                                                                                                                  {cat}
                                                                                                            </div>
                                                                                                      </li>
                                                                                                )  
                                                                                          }): <>
                                                                                                <EventDetailTagsDummy tabInfo/>
                                                                                                <EventDetailTagsDummy tabInfo/>
                                                                                          </>}
                                                                                          {min_age &&
                                                                                          <li>
                                                                                                <div className="badge bg-danger badge-outline fw-normal ">
                                                                                                      {min_age.includes("+") && "Min Age"} {min_age}
                                                                                                </div>
                                                                                          </li> }
                                                                                    </ul>
                                                                              </div>
                                                                              {/* <!-- /.wrapper --> */}
                                                                        </div>
                                                                        {/* <!-- .nav-tabs__info-venue-wrapper --> */}

                                                                        <div className="event__body border-top">
                                                                              {description ? <p dangerouslySetInnerHTML={{__html: description}}></p> : 
                                                                              <EventDetailDescriptionDummy />}
                                                                              {videoLink !== "" && 
                                                                              <div className="video-wrapper">
                                                                                    <iframe style={{"width": "560px", "height": "315px","maxWidth":"100%"}} src={videoLink} title="YouTube video player"	
                                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                                                                                    </iframe>
                                                                              </div>}

                                                                        </div>
                                                                        {/* <!-- .event__body --> */}
                                                                  </div>
                                                                  {/* <!-- .event --> */}
                                                            </div>
                                                            {/* <!-- .content__body --> */}
                                                      </div>
                                                      {/* <!-- .content.content__event-details --> */}

                                                </div>
                                                {/* <!-- .col-lg-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}
                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>
                              {/* <!-- /.section__event-details --> */}
                        </main>

                        <Footer />

                  </div>
                  {/* <!-- /div[data-scroll-container] --> */}

                  {/* <!-- /.modal --> */}
            </React.Fragment>
      )
}

export default EventDetails;
