import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header/Header';
import { Config } from '../Config';
import { useSelector } from 'react-redux';

const MainLayout = () => {

      const domain =  useSelector(state=>state.user.domain)

      return (
            <div className={[domain === Config.DOMAIN_CONSTANT_AJTIX && "ajtix-wrapper", domain === Config.DOMAIN_CONSTANT_TFM && "tfm-wrapper"].join(" ")}>
                  <Header/>
                  <Outlet/>
            </div>
      )
}

export default MainLayout
