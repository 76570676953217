import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { setupListeners } from '@reduxjs/toolkit/query'

import { api } from "../Services/Api";
import storage from 'redux-persist/lib/storage' 
import { 
      persistReducer,
      persistStore,
      FLUSH,
      REHYDRATE,
      PAUSE,
      PERSIST,
      PURGE,
      REGISTER, 
} from 'redux-persist'

import user from "./User/index"
import currentListing from "./CurrentListing/index";
import eventDetail from "./EventDetail";
import bookingModule from "./BookingModule";
import ui from "./UI"
import wallet from "./Wallet";
import giftVoucher from "./GiftVoucher";
import curruntBookings from "./CurruntBookings";
import contactUs from "./ContactUs";
import register from "./Register";
import webViewPayment from "./WebViewPayment";

import { reset as resetUser } from "./User/index";
import { reset as resetUI } from "./UI/index";

const reducers = combineReducers({
      // theme,
      api: api.reducer, 
      ui,
      user,
      wallet,
      giftVoucher,
      currentListing,
      eventDetail,
      bookingModule,
      curruntBookings,
      contactUs,
      register,
      webViewPayment
})

const persistConfig = {
      key: 'root',
      storage: storage,
      whitelist: ['user'],
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
      reducer:persistedReducer,
      middleware:(getDefaultMiddleware)=>{

            const middlewares = getDefaultMiddleware({
                  serializableCheck: {
                        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                  },
            }).concat(api.middleware)

            return middlewares
      },
      devTools: process.env.NODE_ENV !== 'production',
})

const persistor = persistStore(store)

setupListeners(store.dispatch)

export { store, persistor }


window.addEventListener('storage', e => {
      
      if(e.key === "persist:root"){
            if(e.oldValue && !e.newValue){
                  // const parsedState = JSON.parse(e.newValue);
                  // const user = JSON.parse(parsedState.user);
                  // Check auth and token in redux persist are not valid then logout user from all tabs
                  // if(!user.auth && !user.ct_access_token) {
                        console.log("here logout in event listner")
                        store.dispatch(resetUser());
                        store.dispatch(resetUI());
                  // }
            }

            // If auth and token in redux persist are valid than redirect user to current listing
            // if(user.auth && user.ct_access_token) {
            //       window.location = "/currentListing"
            // }
            // if(e.oldValue  && e.newValue ){
            //       window.location = "/currentListing"
            // }

      }
     
});
