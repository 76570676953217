import React, { useState, useEffect } from 'react';

// external css
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

// redux and react hooks
import { useDispatch } from 'react-redux';

// child component
import { SelectComponent } from '../../../Components';

// packages
// Actions & RTK query or mutations
import { useLazyFetchMemberPreferenceQuery, useUpdateMemberPreferenceMutation } from './../../../Services/modules/Preference';
import { setAlert, updateLocoScroll } from '../../../Store/UI';

// Constants
import { errors, messages } from '../../../resources/en';

const EmailPrefrencesForm = (props) => {

      const [scroll,setScroll] = useState(false)
      const [fontError,setFontError] = useState('');
      const dispatch = useDispatch();
      const [memberPreference, { data, isSuccess, isLoading, isFetching, error }] =  useLazyFetchMemberPreferenceQuery();
      const [updatePrefrences, { data : preData, isSuccess : isPreSuccess, isLoading : isPreLoading , isFetching : isPreFetching, error : preError }] =  useUpdateMemberPreferenceMutation();
      const [state,setState] = useState(null)

      useEffect(() => {

             console.log('here')
            memberPreference({
                  "categories":true,
                  "regions":true,
                  "notification_preferences":true
            });

      }, [memberPreference])

      useEffect(()=>{
            if(scroll){
                  dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
                  setScroll(false)
            }
            

      },[scroll,dispatch])

      useEffect(() => {
            if(isSuccess){
                  if(data.status === "1"){
                        if(state === null) {
                              setState(data.data)
                        }
                        setScroll(true);
                  } else if(data.status === "0"){
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.message || errors.NotFound404}));
                  }
            }
            
            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}));
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [data, isSuccess, isLoading, isFetching, error, dispatch])

      useEffect(() => {
            if(isPreSuccess){
                  // if(preData.status === "1"){
                        // setState(preData.data)
                  // } else if(preData.status === "0"){
                        dispatch(setAlert({model:true,title:messages.modalSuccessTitle,message:preData.error || preData.message || errors.NotFound404}));
                  // }
            }
            
            if(preError){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:preError.error || preError.data.error || preError.data.message || errors.NotFound404}));
            }

      }, [preData, isPreSuccess, isPreLoading, isPreFetching, preError, dispatch])

      let idSelect = "selectArea";
      if(props.mobile){
            idSelect = "selectAreaMobile"
      }

      const onRegionChangeHandler = (event,index) => {
            event.preventDefault();
            clearErrorHandler();
            const name = event.target.name;
            const value = event.target.value;

            

            if(name === "regions") {
                  const updateArray = []

                  state.regions.forEach((el,index)=>{
                        if(el.id === value){
                              updateArray.push({...el,selected:true})
                        } else {
                              updateArray.push({...el,selected:false})
                        }
                  })

                  
                  setState(prevState=>{
                        return {
                              ...prevState,
                              regions:updateArray
                        }
                  })

                  setScroll(true)

            } else if(name === "sub_locations") {
                  const updateArray = []
                  let updateRegions = []
                  const selected = event.target.checked ? true : false;

                  state.regions[0].sub_locations.forEach((el,ind)=>{
                        if(index === ind){
                              updateArray.push({...el,selected:selected})
                        } else {
                              updateArray.push({...el})
                        }
                  })

                  updateRegions = [...state.regions]

                  updateRegions[0] = {
                        ...state.regions[0],
                        sub_locations : updateArray
                  }
                  
                  setState(prevState=>{
                        return {
                              ...prevState,
                              regions:updateRegions
                        }
                  })

            }
      }

      const onCategorieChangeHandler = (event,index)=>{
            event.preventDefault();
            clearErrorHandler();
            const name = event.target.name;
                  
            
            const updateArray = []
            const selected = event.target.checked ? true : false;
            
            state[name].forEach((el,ind)=>{
                  if(ind === index){
                        updateArray.push({...el,selected:selected})
                  } else {
                        updateArray.push({...el})
                  }
            })
            
            setState(prevState=>{
                  return {
                        ...prevState,
                        [name]:updateArray
                  }
            })


      }

      const updateNotificationHandler = (event,index) => {
            event.preventDefault();
            clearErrorHandler();
            const name = event.target.name;
            // const value = event.target.value;

            const updateArray = []
            const selected = event.target.checked ? true :false;

            state[name].forEach((el,ind)=>{
                  if(index ===4 && selected ) {
                        
                        if(ind === index){
                              updateArray.push({...el,selected:selected})
                        } else {
                              updateArray.push({...el,selected:false})
                        }
                  } 
                  else {
                        if(ind === index){
                              updateArray.push({...el,selected:selected})
                        } else if(ind ===4) {
                              updateArray.push({...el,selected:false})
                        } else {
                              updateArray.push({...el})
                        }
                  }
            })

            setState(prevState=>{
                  return {
                        ...prevState,
                        [name]:updateArray
                  }
            })

      }

      const updatePrefrencesRequestHandler = (event)=>{
            event.preventDefault();
            setFontError([]);

            const arrayForRegionPref = [];
            const arrayForLondonPref = [];
            const arrayForCatPref = [];
            const arrayForNotPref = [];
            let locationPref = null;
            state.regions.forEach(not=> {
                  if(not.selected){
                        arrayForRegionPref.push(not.id);
                        locationPref = not.id;
                  }
            });

            if(locationPref === "4") {
                  state.regions[0].sub_locations.forEach(lon=> {
                        if(lon.selected){
                              arrayForLondonPref.push(lon.id);
                        }
                  });
            }


            state.categories.forEach(event=> {
                  if(event.selected){
                        arrayForCatPref.push(event.id);
                  }
            });

            state.notification_preferences.forEach(event=> {
                  if(event.selected){
                        arrayForNotPref.push(event.id);
                  }
            });

            const errorStore = [];

            if(arrayForRegionPref.length === 0) {
                  errorStore.push('regions');
            }

            if(arrayForLondonPref.length === 0 && state.regions[0].selected){
                  errorStore.push('sub_locations');
            }

            if(arrayForCatPref.length === 0){
                  errorStore.push('categories');
            }

            if(arrayForNotPref.length === 0) {
                  errorStore.push('notification_preferences');
            }

            if(errorStore.length > 0){
                  setFontError(errorStore)
                  return;
            }

            updatePrefrences(state)
      }

      const clearErrorHandler = ()=>{
            setFontError("");
      }

      let showLondonPrefrences = null;
      if(state!= null) {
            if(state.regions[0].selected) {
                  showLondonPrefrences = true;
            }
      }

      let items = [];
      if(state!==null){
            state.regions.forEach(element => {
                  if(element.selected){
                        items.push(element.id)
                  }
            })
      }

      return (
            <form className='emailPrefrenceForm'>
                  {state !== null && <SelectComponent id={idSelect} items={items} onChange={onRegionChangeHandler} name="regions">
                        {state!==null && state.regions.map(item=>{
                              return (
                                    <option key={`regions${item.id}`} value={item.id}>{item.lable}</option>
                              )
                        })}
                  </SelectComponent >}
                  {fontError.includes("regions") && <div className="form-group form-group__your-interests mw-100 error-exists">
                        {errors.emailPrefrences.regions}
                  </div>}
                  
                  {/* <!-- /.form-group --> */}

                  {/* <!-- IMPORTANT: .form-group in Account Settings has max-width 440px --> */}
                  {/* <!-- IMPORTANT: add class "mw-100" to override max-width to 100%  --> */}
                  {showLondonPrefrences && <React.Fragment><h4 className="heading heading__your-area-2">London area <br className="d-md-none"/>(select as many as you wish)</h4>
                  <div className="form-group form-group__list-tags mw-100">
                        <ul className="list__tags">
                              {state!==null && state.regions[0].sub_locations.map((item,index)=>{

                                    return (<li key={`sub_locations${item.id}`}>
                                                <div className="btn-check-wrapper">
                                                      <input className="form-check-input btn-check" type="checkbox" name="sub_locations" id={`sub_locations${item.id}`} onChange={(event)=>onRegionChangeHandler(event,index)} value={item.id} checked={item.selected}/>
                                                      <label className={["btn btn-sm btn-outline-primary hover-primary",item.selected ? "checkedLabel" : "unCheckLabel"].join(" ")} htmlFor={`sub_locations${item.id}`}>
                                                            {item.lable}
                                                      </label>
                                                      
                                                </div>
                                          </li>)
                              })}
                        </ul>
                  </div>
                  {/* <!-- /.form-group --> */}
                  {fontError.includes("sub_locations") && 
                  <div className="form-group form-group__your-interests mw-100 error-exists">
                        {errors.emailPrefrences.sub_locations}
                  </div>}
                  </React.Fragment> }

                  <h4 className="heading">Select your interests <br className="d-md-none"/>(select as many as you wish)</h4>
                  <div className="form-group form-group__your-interests mw-100">
                        <ul className="list__tags">
                              {state !== null && state.categories.map((item,index)=>{

                                    return (<li key={`categories${item.id}${index}`}>
                                                <div className="btn-check-wrapper">
                                                      <input className="form-check-input btn-check" type="checkbox" name="categories" id={`categories${item.id}`} checked={item.selected} onChange={(event)=>onCategorieChangeHandler(event,index)} value={item.id}/>
                                                      <label className={["btn btn-sm btn-outline-primary hover-primary",item.selected ? "checkedLabel" : "unCheckLabel"].join(" ")} htmlFor={`categories${item.id}`}>
                                                            {item.name}
                                                      </label>
                                                </div>
                                          </li>)
                              })}
                        </ul>
                  </div>
                  {/* <!-- /.form-group --> */}
                  {fontError.includes("categories") && <div className="form-group form-group__your-interests mw-100 error-exists">
                        {errors.emailPrefrences.categories}
                  </div>}

                  <h4 className="heading heading__select-email-preferences">
                        Select your email preferences <br className="d-md-none"/>(select as many as you wish)
                  </h4>
                  {state !== null && state.notification_preferences.map((item,index)=>{

                        return      (<div key={`not${item.id}${index}`} className="form-group form-group__custom-controls mw-100">
                                          <div className="custom-control custom-checkbox custom-checkbox__radio">
                                                <input type="checkbox" className={["custom-control-input",item.selected ? "custom-control-input-checked" : ""].join(" ")} id={`not${item.id}`} name="notification_preferences" onChange={(event)=>updateNotificationHandler(event,index)} checked={item.selected}/>

                                                <label className="custom-control-label" htmlFor={`not${item.id}`}>
                                                      {item.lable}&nbsp;
                                                      <span className="icon-tooltip-info" data-tooltip-id="my-tooltip" data-tooltip-content={item.lable_description}>i</span>
                                                </label>
                                                
                                          </div>
                                    </div>)
                  })}
                  <Tooltip id="my-tooltip" />
                  {/* <!-- /.form-group --> */}
                  {fontError.includes("notification_preferences") && <div className="form-group form-group__your-interests mw-100 error-exists">
                        {errors.emailPrefrences.notification_preferences}
                  </div>}

                  <div className="form-group form-group__cta mw-100">
                        <button type="submit" className="btn btn-xl-lg btn-primary" onClick={updatePrefrencesRequestHandler} disabled={isPreLoading}>
                              Update preferences
                        </button>
                  </div>
                  {/* <!-- /.form-group --> */}

            </form>
      )
}

export default EmailPrefrencesForm