import React from 'react'

// external css
// redux and react hooks
// child component
// packages
// Actions & RTK query or mutations
// Constants

const TermsModal = (props) => {

      return (

                  <React.Fragment>
                        <div className="modal modal__fw modal__terms-and-conditions slideInDown show" style={{"display":"block"}} >
                              <div className="modal-dialog">
                                    <div className="modal-content">
                                          <div className="modal-body">
                                                <button type="button" className="btn-close btn-close__custom btn-close__absolute" 
                                                // data-bs-dismiss="modal" aria-label="Close" 
                                                onClick={props.termsPopupHandler}>
                                                </button>

                                                <div className="content d-flex flex-column flex-xl-row">
                                                      <div className="content__header flex-shrink-0">
                                                            <h2 className="heading">Terms & Conditions</h2>
                                                      </div>
                                                      {/* <!-- /.content__body --> */}
                                                      <div className="content__body">
                                                            <p>
                                                            Recommended friends must become active members of Central Tickets before a discount voucher will be issued to the referring member. Once your friends spend £75 or more on seat-filling bookings you will receive a discount code which can be used against the cost of bookings to discount your next booking by 10% (for a maximum saving of £5).
                                                            </p>
                                                            <p>
                                                            Referrals must be genuine and you cannot recommend yourself, or someone from within your household. We check all requests and where we see similarities in registration details, or suspect that an alias has been created, or abuse of the referral promotion we reserve the right to decline any request for referral tickets and withdraw membership from all connected accounts.
                                                            </p>
                                                            <p>
                                                            Discount codes can only be used for events managed directly by Central Tickets and found within the Shows tab.
                                                            </p>
                                                      </div>
                                                      {/* <!-- /.content__body --> */}
                                                </div>
                                                {/* <!-- /.content --> */}

                                          </div>
                                          {/* <!-- /.modal-body --> */}
                                    </div>
                                    {/* <!-- /.modal-content --> */}
                              </div>
                              {/* <!-- /.modal-dialog --> */}
                        </div>
                        {/* <!-- modal #modalTermsAndConditions --> */}
                        <div className="modal-backdrop fade show" style={{"top": "91px"}}></div>
                  </React.Fragment>
      )
}

export default TermsModal;
