import React, { useEffect, useState } from 'react';
// external css
// redux and react hooks
import { useDispatch, useSelector } from 'react-redux';

import { HeadingPictureComponent } from '../../Components';
// child component
import { TickerText } from '../../Components';
import ManageBooking from "./ManageBooking/ManageBooking";
import { MyBookingItem, MyBookingItemDummy } from '../../Components';
import Footer from "../../Layouts/Footer/Footer";

// packages
import { Helmet } from 'react-helmet';

// Actions & RTK query or mutations
import { useLazyFetchBookingsQuery } from '../../Services/modules/Bookings';
import { updateLocoScroll } from '../../Store/UI';
import { currBookingText, pastBookingText, catsOneText } from '../../resources/TextRollerConstants';
import { setAlert } from '../../Store/UI';

// Constants
import { errors, messages } from '../../resources/en';
import { MetaData } from '../../resources/MetaData';

const MyBookings = () => {

      const [scroll,setScroll]            =     useState(false);
      const curruntBookings               =     useSelector(state => state.curruntBookings)
      // const [isManageBooking,setIsManageBooking] = useState(false);
      const dispatch                      =     useDispatch();
      
      const [fetchBookings, { data, isSuccess, error, isLoading }]  =    useLazyFetchBookingsQuery();

      useEffect(()=>{
            fetchBookings({type:'booking'});

      },[fetchBookings])


      useEffect(()=>{
            if(isSuccess) {
                  // if(data.status === "1"){

                  // } 
                  // else 
                  if(data.status === "0"){
                        setScroll(true)
                  }
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}));
            }
      
      },[data, isSuccess, error, isLoading, dispatch])

      useEffect(()=>{
            document.title = "My Bookings";
      },[])

      useEffect(()=>{
            if(scroll){
                  dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
                  setScroll(false)
            }
      },[scroll, dispatch])

      // const manageBookingHandler = ()=>{
      //       setIsManageBooking(!isManageBooking)
      // }

      const afterLoadHandler = ()=>{
            dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
      }


      return (
                  <React.Fragment>
                        
                        {curruntBookings.isCancling && <ManageBooking />}
                        {/* <!-- /.slidein-wrapper --> */}

                        {/* <!-- REQUIRED wrapper div for parallax --> */}
                        <div className={["scroll-animations position-relative"].join(" ")} data-scroll-container>
                              <Helmet>
                                    <title>{MetaData.Bookings.title}</title>
                                    <meta name="description" content={MetaData.Bookings.description} />
                                    <meta name="keywords" content={MetaData.Bookings.keywords} />
                              </Helmet>
                              <main>      
                                    <section data-scroll-section className="section__my-bookings py-0">
                                          <div className="container-fluid container__block">
                                                <div className="row">
                                                      <div className="col-md-12">
                                                            <div className="content content__my-bookings mx-auto">
                                                                  <div className="content__header content__header--template border-bottom text-center overflow-hidden">
                                                                        <div className="big-heading">
                                                                              <h1 className="mb-0">Your Bookings</h1>
                                                                                    <HeadingPictureComponent dataScroll className="ticket-aquamarine" srcSet="ticket-my-bookings-big-heading1-aquamarine.svg" src="ticket-my-bookings-big-heading1-blue-sm.svg"/>
                                                                                    
                                                                                    <HeadingPictureComponent dataScroll className="ticket-yellow" srcSet="ticket-my-bookings-big-heading1-yellow.svg" src="ticket-my-bookings-big-heading1-red-sm.svg"/>
                                                                                   
                                                                                    <HeadingPictureComponent dataScroll className="ticket-green" srcSet="ticket-my-bookings-big-heading1-red.svg" src="ticket-my-bookings-big-heading1-green-sm.svg"/>

                                                                                    <HeadingPictureComponent dataScroll className="ticket-blue" srcSet="ticket-my-bookings-big-heading1-blue.svg" src="ticket-my-bookings-big-heading1-aquamarine-sm.svg"/>

                                                                                    <HeadingPictureComponent dataScroll className="ticket-orange" srcSet="ticket-my-bookings-big-heading1-orange.svg" src="ticket-my-bookings-big-heading1-yellow-sm.svg"/>
                                                                                    
                                                                        </div>
                                                                  </div>
                                                                  {/* <!-- .content__header --> */}

                                                                  <div className="content__body">

                                                                        <div className="border-bottom">
                                                                              <TickerText id="tickerCurrentBookings" textData={currBookingText} count={(data && data.data.current_bookings ) ? data.data.current_bookings.length : null}/>
                                                                              {/* <!-- /.ticker --> */}
                                                                        </div>

                                                                        <ul className="list__my-bookings list-unstyled mb-0">
                                                                              {!data && 
                                                                                    <>
                                                                                    <MyBookingItemDummy type="current"/>
                                                                                    <MyBookingItemDummy type="current"/>
                                                                                    </>
                                                                              }
                                                                              {(data && data.data.current_bookings.length > 0 )? data.data.current_bookings.map(el=>{
                                                                                    return <MyBookingItem key={el.booking_id} {...el} type="current" afterLoad={afterLoadHandler}/>
                                                                              }) :
                                                                              (!isLoading && <div className='section__no-my-bookings'>
                                                                                    <h1>No Current Bookings</h1>
                                                                              </div>)}
                                                                        </ul>

                                                                        <div className="border-bottom">
                                                                              <TickerText id="tickerPastBookings" textData={pastBookingText} afterLoad={afterLoadHandler} count={(data && data.data.past_bookings ) ? data.data.past_bookings.length : null}/>
                                                                              {/* <!-- /.ticker --> */}
                                                                        </div>

                                                                        <ul className="list__my-bookings list-unstyled">
                                                                              {!data && 
                                                                                    <MyBookingItemDummy type="past"/>
                                                                              }
                                                                              {(data && data.data.past_bookings.length > 0 ) ? data.data.past_bookings.map(el=>{
                                                                                    return <MyBookingItem key={el.booking_id} {...el} type="past" afterLoad={afterLoadHandler}/>
                                                                              }) : 
                                                                              (!isLoading && <div className='section__no-my-bookings'>
                                                                                    <h1>No Past Bookings</h1>
                                                                              </div>)
                                                                                    }
                                                                        </ul>

                                                                  </div>
                                                                  {/* <!-- .content__body --> */}
                                                            </div>
                                                            {/* <!-- .content.content__my-bookings --> */}

                                                      </div>
                                                      {/* <!-- .col-md-12 --> */}
                                                </div>
                                                {/* <!-- /.row --> */}
                                          </div>
                                          {/* <!-- /.container-fluid  --> */}
                                    </section>
                                    {/* <!-- /.section__my-bookings --> */}

                                    <div data-scroll-section className="border-bottom">
                                          <TickerText id="tickerShowCatsOne" textData={catsOneText}/>
                                          {/* <!-- /.ticker --> */}
                                    </div>

                              </main>

                              <Footer/>

                        </div>
                        {/* <!-- /div[data-scroll-container] --> */}
                  </React.Fragment>
      )
}

export default MyBookings;
