export const Config = {
      // API_URL: 'https://cooking.centraltickets.co/frontend/',
      // API_URL: 'https://cooking.centraltickets.co.uk/',
      API_URL: 'https://stage.centraltickets.co/',
     //API_URL: 'https://api.centraltickets.co.uk/',
     RECAPTCHA_SITE_KEY : "6LfARaclAAAAAFN4Rsr5KoXm4ortYJkSFRkiZMkm",
     // AJTIX_URL_CHECK : "localhost",
     // TFM_URL_CHECK : "localhost",
     DOMAIN_CONSTANT_AJTIX : "Ajtix",
     DOMAIN_CONSTANT_TFM : "ticketsformedics",
     AJTIX_URL_CHECK : "ajtix",
     TFM_URL_CHECK : "ticketsformedics",
     
}
 