import React, { useState, useEffect } from 'react'

// external css
import "./GiftVouchers.css"

// redux and react hooks
import { useSelector, useDispatch  } from 'react-redux';
import {  Link, useLocation, useNavigate } from 'react-router-dom';

// child component
import { HeadingPictureComponent, TickerText } from '../../Components';
import Footer from "../../Layouts/Footer/Footer";
import GiftTopUp from './GiftTopUp/GiftTopUp';

// packages
// Actions & RTK query or mutations
import { useLazyGetVoucherHistoryQuery } from "../../Services/modules/GiftVoucher"
import { updateLocoScroll } from '../../Store/UI';
import { setGiftParams, setRefetchHistory } from "../../Store/GiftVoucher"
import { setAlert } from '../../Store/UI';

// Constants
import { messages, errors } from '../../resources/en';
import { catsOneText } from '../../resources/TextRollerConstants';
import { routesName } from '../../resources/RoutesName';

const GiftVouchers  = (props) => {

      const [scroll,setScroll]                  =     useState(false);
      const [height,setHeight]                  =     useState(0);
      const giftVoucher                         =     useSelector(state=>state.giftVoucher);
      const user                                =     useSelector(state=>state.user);
      
      const dispatch                            =     useDispatch();
      const navigate                            =     useNavigate();

      const { pathname, search }                =     useLocation();


      const [getHistory, { data, isSuccess, error }] = useLazyGetVoucherHistoryQuery()

      useEffect(()=>{
            const elmnt = document.getElementById("content-id-ref");
            const height = elmnt.offsetHeight ? elmnt.offsetHeight - 41 : 0
            setHeight(height)

      },[])

      useEffect(()=>{
            if(scroll){
                  dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
                  setScroll(false)
            }
      },[scroll, dispatch])

      useEffect(()=>{
            if(!giftVoucher.isTopUpOpen) {
                  dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
            }
      },[giftVoucher.isTopUpOpen, dispatch])

      useEffect(()=>{
            if(giftVoucher.refetchHistory){
                  getHistory()
                  dispatch(setRefetchHistory({refetchHistory : false}))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[giftVoucher.refetchHistory])

      useEffect(()=>{
            getHistory()

      },[getHistory,])


      // handle Response from 3D secure
      useEffect(()=>{

            if(pathname.includes(routesName.SuccessAddVoucher) || pathname.includes(routesName.FailureAddVoucher)) {
                  const searchParams = new URLSearchParams(search);
                  const client = searchParams.get('client') || "";
                  const bookingStatus = pathname.includes(routesName.SuccessAddVoucher) ? "success" : "failure";
                  const message = bookingStatus === "success" ?  messages.SuccessAddVoucher : messages.FailureAddVoucher;
                  if(client === "web" || client === ""){
                        if(bookingStatus === "success"){
                              dispatch(setGiftParams({
                                    isTopUpOpen: true,
                                    step : "step4",
                                    paymentMethod : "",
                                    giftAmount : 0,
                                    userDetails : null
                              }))

                        } else {
                              dispatch(setGiftParams({
                                    isTopUpOpen: false,
                                    step : "step1",
                                    paymentMethod : "",
                                    giftAmount : 0,
                                    userDetails : null
                              }))
                              // comment this code bacause using firebase to refetch wallet balance
                              // dispatch(setGetWalletBalance({getWalletBalance:true}))
                              dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:message || errors.NotFound404}));
                        }
                  }
                  navigate(routesName.GiftVouchers, { replace: true });
            }
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])


      useEffect(()=>{
            if(data) {
                  setScroll(true)
            }

            if(error) {
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}));
            }
      },[data, error, dispatch])

      const toggleTopUpOpen = ()=> {
            dispatch(setGiftParams({
                  isTopUpOpen: true,
                  step : "step1",
                  paymentMethod : "",
                  giftAmount : 0,
                  userDetails : null
            }))
      }

      return (
                  <React.Fragment>
                        {giftVoucher.isTopUpOpen && <GiftTopUp slidUp={true} webView={props.webView} /> }
                        {/* <!-- REQUIRED wrapper div for parallax --> */}
                        <div className={["scroll-animations position-relative giftVoucher-index", giftVoucher.isTopUpOpen && "footer-hide"].join(" ")} data-scroll-container>
                              <main className={[giftVoucher.isTopUpOpen ? "GiftVoucher-page-overflow"  :""].join(" ")}>

                                    <section data-scroll-section className="section__my-wallet section__gift-vouchers py-0">
                                          <div className="container-fluid container__block">
                                                <div className="row">
                                                      <div className="col-md-12">
                                                            <div className="content content__my-wallet mx-auto">
                                                                  <div className="content__header content__header--template border-bottom text-center overflow-hidden">
                                                                        <div className="big-heading">
                                                                              <h1 className="mb-0">Gift Vouchers</h1>

                                                                              <HeadingPictureComponent 
                                                                              srcSet="ticket-my-wallet-big-heading1-aquamarine.svg"
                                                                              src="ticket-my-wallet-big-heading1-green-sm.svg"
                                                                              className="ticket-aquamarine"
                                                                              dataScroll/>

                                                                              <HeadingPictureComponent 
                                                                              srcSet="ticket-my-wallet-big-heading1-blue.svg"
                                                                              src="ticket-my-wallet-big-heading1-blue-sm.svg"
                                                                              className="ticket-blue"
                                                                              dataScroll/>

                                                                              <HeadingPictureComponent 
                                                                              srcSet="ticket-my-wallet-big-heading1-yellow.svg"
                                                                              src="ticket-my-wallet-big-heading1-aquamarine-sm.svg"
                                                                              className="ticket-yellow"
                                                                              dataScroll/>
                                                                              
                                                                        </div>
                                                                  </div>
                                                                  {/* <!-- .content__header --> */}

                                                                  <div className="content__body border-bottom d-flex flex-column flex-lg-row align-items-lg-stretch">

                                                                        <div className="col__top-up-info border-right-lg">

                                                                              <div className="content ms-lg-auto" id="content-id-ref">
                                                                                    <div className="card card__credit-balance">
                                                                                          <div className="card-body">
                                                                                                <h2 className="h3 heading">Gift wallet credit to friends & family</h2>
                                                                                                {/* <h3 className="h2 wallet-balance mb-0">£{parseFloat(user.ct_wallet_balance).toFixed(2)}</h3> */}
                                                                                          </div>
                                                                                          {/* <!-- .card-body --> */}
                                                                                    </div>
                                                                                    {/* <!-- .card__credit-balance --> */}
                                                                                    <div className="p-top-up d-flex flex-column flex-sm-row align-items-sm-center">
                                                                                          Ready to send a gift voucher?  <button type="button" className="btn btn-outline-primary btn-slidein-topup hover-primary" onClick={toggleTopUpOpen}>Gift wallet credit</button>
                                                                                    </div>
                                                                                    <h3 className="heading heading__why-top-up">
                                                                                          How does it work?
                                                                                    </h3>
                                                                                    <p>
                                                                                          Gift wallet credit for seat-filling bookings to anyone. Just enter their name and email address.  We’ll ask you which date you want it delivered and we’ll send it for you (and send you a copy).
                                                                                    </p>
                                                                                    <p>
                                                                                          Your friend will receive an automatic credit to their wallet and message from you.  If they’re not already a member, we’ll send them a link to join along with a voucher code.
                                                                                    </p>
                                                                                    <p>
                                                                                          Each time you give £50 or more in gift wallet credit, we'll also give you 10% free credit as a reward. (to a maximum free credit of £10)
                                                                                    </p>
                                                                                    <p className="p-terms fw-bold">
                                                                                          {/* <Link to={routesName.Terms}  target="_blank" rel="noopener noreferrer" state={{ myData: 'wallet' }}> Terms & Conditions</Link>  */}
                                                                                          <Link to={{ pathname: `${routesName.Terms}?giftVoucher`, state: { myData: "giftVoucher" } }}  target="_blank" rel="noopener noreferrer">Terms & Conditions</Link>
                                                                                    </p>
                                                                                    {/* <hr className="d-none d-md-block mt-5"/> */}
                                                                              </div>
                                                                              {/* <!-- .content --> */}

                                                                        </div>
                                                                        {/* <!-- .col__top-up-info --> */}

                                                                        <div className="col__transaction-history me-lg-auto">

                                                                              <div className="content" id="headingHistory">

                                                                                    {(isSuccess && data.data.length > 0) &&
                                                                                    <h3 className="heading" >Gift credit history</h3>}
                                                                                    
                                                                                    {(data) && 
                                                                                    <div id="inside-js-scroll" className={false ? " ": "inside-js-scroll-height"} style={{"height":height, minHeight : (user.ct_web_view_session) ? "350px" : ""}}>


                                                                                          {data.data.length > 0 ? data.data.map((tran, index)=>{

                                                                                                return (

                                                                                                      <div key={`giftVoucherHistory${index}`}  className={["card card__transaction-summary bg-yellow" ].join(" ")} >
                                                                                                            <div className="card-body">
                                                                                                                  <div className="content">
                                                                                                                        <div className="item-wrapper">
                                                                                                                              <div className="item d-flex justify-content-between">
                                                                                                                                    <div className="item__name">{tran.narration}</div>
                                                                                                                                    <div className="item__date flex-shrink-0">{tran.created_at}</div>
                                                                                                                              </div>
                                                                                                                              {/* <div className="item-detail">
                                                                                                                                    Card (**** 9456)
                                                                                                                              </div> */}
                                                                                                                        </div>
                                                                                                                        {/* <!-- .item-wrapper --> */}
                                                                                                                  </div>
                                                                                                                  {/* <!-- .content --> */}
                                                                                                            </div>
                                                                                                            {/* <!-- .card-body --> */}
                                                                                                            <div className="card-footer pt-0">
                                                                                                                  <hr className="card-footer__hr"/>
                                                                                                                  <div className="content">
                                                                                                                        <div className="transaction-status d-flex justify-content-between">
                                                                                                                              <div className="transaction-amount">
                                                                                                                                    {tran.amount && tran.amount.toString()}
                                                                                                                              </div>
                                                                                                                              <div className="wallet-balance">
                                                                                                                                    <span>{tran.voucher_code}</span>
                                                                                                                              </div>
                                                                                                                        </div>
                                                                                                                        {/* <!-- .transaction-status --> */}
                                                                                                                  </div>
                                                                                                                  {/* <!-- .content --> */}
                                                                                                            </div>
                                                                                                            {/* <!-- .card-footer --> */}
                                                                                                      </div>
                                                                                                )

                                                                                          })
                                                                                          :
                                                                                          <div className='col__no-transaction-history'>
                                                                                                <h2>Gift credit <br/>to friends</h2>
                                                                                                <p>If you gift £50 or more, you'll get 10% credit in your wallet (up to £10 max)</p>
                                                                                                <button type="button" className="btn btn-outline-primary btn-slidein-topup hover-primary" onClick={toggleTopUpOpen}>Gift wallet credit</button>
                                                                                          </div>
                                                                                          }
                                                                                    </div>}

                                                                              </div>
                                                                              {/* <!-- .content --> */}

                                                                        </div>
                                                                        {/* <!-- .col__transaction-history --> */}

                                                                  </div>
                                                                  {/* <!-- .content__body --> */}
                                                            </div>
                                                            {/* <!-- .content.content__my-wallet --> */}

                                                      </div>
                                                      {/* <!-- .col-md-12 --> */}
                                                </div>
                                                {/* <!-- /.row --> */}
                                          </div>
                                          {/* <!-- /.container-fluid  --> */}
                                    </section>
                                    {/* <!-- /.section__my-wallet --> */}

                                    <div data-scroll-section className="border-bottom">
                                          <TickerText id="tickerShowCatsOne" textData={catsOneText}/>
                                    </div>
                              </main>
                              <Footer/>
                        </div>
                        {/* <!-- /div[data-scroll-container] --> */}
                  </React.Fragment>
      )
}

export default GiftVouchers;
