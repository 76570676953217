import React,{useState, useEffect } from "react";

// external css
// redux and react hooks
import { useDispatch } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";

// child component
import ticketGuidelinesBigHeading1Yellow from "../../assets/images/tickets/ticket-guidelines-big-heading1-yellow.svg"
import ticketGuidelinesBigHeading1YellowSm from "../../assets/images/tickets/ticket-guidelines-big-heading1-yellow-sm.svg"

import ticketReferFriendBigHeading1Green from "../../assets/images/tickets/ticket-refer-friend-big-heading1-green.svg"

import ticketReferFriendBigHeading1Blue from "../../assets/images/tickets/ticket-refer-friend-big-heading1-blue.svg"
import ticketReferFriendBigHeading1GreenSm from "../../assets/images/tickets/ticket-refer-friend-big-heading1-blue-sm.svg"

import ticketReferFriendBigHeading1Orange from "../../assets/images/tickets/ticket-refer-friend-big-heading1-orange.svg"
import ticketReferFriendBigHeading1OrangeSm from "../../assets/images/tickets/ticket-refer-friend-big-heading1-orange-sm.svg"

import ticketReferFriendBigHeading1Aquamarine from "../../assets/images/tickets/ticket-refer-friend-big-heading1-aquamarine.svg"
import ticketReferFriendBigHeading1AquamarineSm from "../../assets/images/tickets/ticket-refer-friend-big-heading1-aquamarine-sm.svg"

import ticketMyFavouritesBigHeading1Red from "../../assets/images/tickets/ticket-my-favourites-big-heading1-red.svg"



import Footer from "../../Layouts/Footer/Footer";
import PaymentModal from "./PaymentModal";

// packages
import { Transition } from 'react-transition-group';
import { Helmet } from "react-helmet";

// Actions & RTK query or mutations
import { useLazyRestoreBlockAccessQuery } from "../../Services/modules/ReactiveAccount";
import { setSpinner } from "../../Store/UI";
import { setAlert } from "../../Store/UI";

// Constants
import { routesName } from "../../resources/RoutesName";
import { errors, messages } from "../../resources/en";
import { MetaData } from "../../resources/MetaData";

const ReactiveAccount = () => {

      const [state,setState]  =     useState(false);
      const dispatch          =     useDispatch();
      const navigate          =     useNavigate();
      const { status }        =     useParams();

      const [restoreBlockAccess,{ data, isSuccess, error, isLoading }] = useLazyRestoreBlockAccessQuery();

      useEffect(()=>{
            document.title = "Reactive Account"; 
      },[])
      
      useEffect(()=>{
            restoreBlockAccess({access_id:status})

      },[restoreBlockAccess, status])

      useEffect(()=>{
            if(isSuccess) {
                  if(data.status === "1"){
                        // console.log(data)
                  } 
                  else if(data.status === "0"){
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.errors || data.error || data.message || errors.NotFound404}))
                        navigate(routesName.Home)
                  }
            }
            
            if(error){
                  navigate(routesName.Home)
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404 }))
            }

      },[data, isSuccess, error, isLoading, dispatch, navigate])

      const restoreAccessHandler = ()=>{
            if(data.data){
                  setState(true)
            }
      }

      const closePaymentModalHandler = ()=>{
            dispatch(setSpinner({status : ""}));
            setState(false)
      }

      return (
            <>    
                  <Transition in={state} timeout={500} mountOnEnter unmountOnExit>
                        {stat => {
                              return (
                                    <PaymentModal show={stat} closePaymentModal={closePaymentModalHandler} page="reactive" amount={data && data.data.upgrade_fees} access_id={status}/>
                              )}
                        }
                  </Transition>
                  <div className="scroll-animations position-relative" data-scroll-container>
                        <Helmet>
                              <title>{MetaData.Reactivate.title}</title>
                              <meta name="description" content={MetaData.Reactivate.description} />
                              <meta name="keywords" content={MetaData.Reactivate.keywords} />
                        </Helmet>
                        <main>

                              <section data-scroll-section className="section__faq section__reactive-account my-auto">
                                    <div className="container-fluid">
                                          <div className="row">
                                                <div className="col-md-12">
                                                      <div className="content content__reactive-account mx-auto">
                                                            <div className="content__header pt-0 px-xl-5 text-center">
                                                                  <div className="big-heading">
                                                                        <h1 className="mb-0">Account Paused - Unlock Listings</h1>
                                                                        <picture>
                                                                              <source media="(min-width: 992px)" srcset={ticketGuidelinesBigHeading1Yellow}/>
                                                                              <img data-scroll className="ticket ticket-yellow" src={ticketGuidelinesBigHeading1YellowSm} alt=""/>
                                                                        </picture>
                                                                        <picture>
                                                                              <img data-scroll className="ticket ticket-green" src={ticketReferFriendBigHeading1Green} alt=""/>
                                                                        </picture>
                                                                        <picture>
                                                                              <source media="(min-width: 992px)" srcset={{ticketReferFriendBigHeading1Blue}}/>
                                                                              <img data-scroll className="ticket ticket-blue" src={ticketReferFriendBigHeading1GreenSm} alt=""/>
                                                                        </picture>
                                                                        <picture>
                                                                              <source media="(min-width: 992px)" srcset={ticketReferFriendBigHeading1Orange}/>
                                                                              <img data-scroll className="ticket ticket-orange"src={ticketReferFriendBigHeading1OrangeSm} alt=""/>
                                                                        </picture>
                                                                        <picture>
                                                                              <source media="(min-width: 992px)" srcset={ticketReferFriendBigHeading1Aquamarine}/>
                                                                              <img data-scroll className="ticket ticket-aquamarine" src={ticketReferFriendBigHeading1AquamarineSm} alt=""/>
                                                                        </picture>
                                                                        <picture>
                                                                              <img data-scroll className="ticket ticket-red" src={ticketMyFavouritesBigHeading1Red} alt=""/>
                                                                        </picture>
                                                                  </div>
                                                            </div>
                                                            {/* <!-- .content__header --> */}
                                                            <div className="content__body px-md-5 px-xl-3 position-relative z-1">
                                                                  <div className="content text-center">
                                                                        <p>Your account is currently paused for a period of {data ? data.data.count : "0"} weeks. You can choose to wait until your account unlocks or <br className="d-none d-xl-block"/>you can pay to restore access to our seat-filling listings immediately by paying £{data ? data.data.upgrade_fees : "0"}.
                                                                        </p>
                                                                        <div className="buttons-wrapper justify-content-center mt-4 mt-lg-5">
                                                                              <button type="submit" className="btn btn-primary fw-bold" onClick={restoreAccessHandler}
                                                                              disabled={isLoading}>Restore Access</button>
                                                                              <Link className="btn btn-primary fw-bold" to="/" disabled={isLoading}>No thanks, I’ll wait</Link>
                                                                        </div>
                                                                  </div>
                                                                  {/* <!-- .content --> */}
                                                            </div>
                                                            {/* <!-- .content__body --> */}
                                                      </div>
                                                      {/* <!-- .content__reactive-account --> */}
                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}
                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>
                              {/* <!-- /.section__faq --> */}
                        </main>
                        <Footer/>  
                  </div>      
            </>
            
      );
};

export default ReactiveAccount;
