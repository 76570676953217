import React, { useState, useEffect } from 'react'

// external css
// import "./ReferFriend.css"
// import IconArrowRightRed from "../../assets/images/icon-arrow-right-34x31-red.svg"
// import IconArrowRightRedAjtix from "../../assets/images/icon-arrow-right-34x31-red-ajtix.svg"

// redux and react hooks
import { useDispatch, useSelector } from 'react-redux';

// child component
import { HeadingPictureComponent, ImageComponent, TickerText } from '../../Components';
import TermsModal from './TermsModal/TermsModal';
import Footer from "../../Layouts/Footer/Footer";

// packages
import { Helmet } from 'react-helmet';
// import {
//       FacebookShareButton,
//       FacebookIcon,
//       WhatsappShareButton,
//       WhatsappIcon,
//       EmailShareButton,
//       EmailIcon,
//       LinkedinShareButton,
//       LinkedinIcon
//     } from "react-share";


// Actions & RTK query or mutations
import { useReferralCodeMutation } from "./../../Services/modules/referral";
import { setAlert } from '../../Store/UI';

// Constants
import { errors, messages } from '../../resources/en';
import { catsOneText } from '../../resources/TextRollerConstants';
import { MetaData } from '../../resources/MetaData';
// import { URL } from '../../resources/Url';
import { Config } from '../../Config';

const ReferFriend = () => {

      const [showTerms,setShowTerms] = useState(false)
      const [copy,setCopy] = useState("Copy");

      const domain = useSelector(state=>state.user.domain)
   
      const [referralCode, { data, isSuccess, isLoading, isFetching, error }] = useReferralCodeMutation();
      const dispatch = useDispatch();

      useEffect(()=>{
            referralCode();
      },[referralCode])

      useEffect(() => {
            
            if(isSuccess){
                  // console.log(data);
            } 

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404 }))
            }

      }, [data, isSuccess, isLoading, isFetching, error, dispatch])

      const termsPopupHandler = ()=>{
            setShowTerms(!showTerms)
      }

      const copyToClipboard = ()=>{
            navigator.clipboard.writeText(data.data.link_for_web);
            setCopy("Copied");
      }

      // const message = 'First line of your message\nSecond line of your message';
      // const yourSubject = 'Your Email Subject';
      // const yourBody = 'Your pre-filled email body text.';

      // const yourTitle = 'Your Share Title';
      // const yourSummary = 'Your custom message for LinkedIn.';

      let isAjtix = domain === Config.DOMAIN_CONSTANT_AJTIX
      let isTfm = domain === Config.DOMAIN_CONSTANT_TFM
    

      return (
                  <React.Fragment>
                        
                        {/* <!-- REQUIRED wrapper div for parallax --> */}
                        <div className={["scroll-animations position-relative"].join(" ")} data-scroll-container>
                              <Helmet>
                                    <title>{MetaData.Referrals.title}</title>
                                    <meta name="description" content={MetaData.Referrals.description} />
                                    <meta name="keywords" content={MetaData.Referrals.keywords} />
                              </Helmet>
                              <main>

                                    <section data-scroll-section className="section__refer-friend pt-0">
                                          <div className="container-fluid">
                                                <div className="row">
                                                      <div className="col-md-12">
                                                            <div className="content content__refer-friend mx-auto">
                                                                  <div className="content__header text-center">
                                                                        <div className="big-heading">
                                                                              <h1 className="mb-0">Get 10% off!</h1>
                                                                              <HeadingPictureComponent 
                                                                              dataScroll
                                                                              className="ticket-aquamarine"
                                                                              srcSet="ticket-refer-friend-big-heading1-aquamarine.svg"
                                                                              src="ticket-refer-friend-big-heading1-aquamarine-sm.svg"
                                                                              />

                                                                              <HeadingPictureComponent 
                                                                              dataScroll
                                                                              className="ticket-yellow"
                                                                              srcSet="ticket-refer-friend-big-heading1-yellow.svg"
                                                                              src="ticket-refer-friend-big-heading1-yellow-sm.svg"
                                                                              />

                                                                              <HeadingPictureComponent 
                                                                              dataScroll
                                                                              className="ticket-red"
                                                                              srcSet="ticket-refer-friend-big-heading1-red.svg"
                                                                              src="ticket-refer-friend-big-heading1-red-sm.svg"
                                                                              />

                                                                              <HeadingPictureComponent 
                                                                              dataScroll
                                                                              className="ticket-blue"
                                                                              srcSet="ticket-refer-friend-big-heading1-blue.svg"
                                                                              src="ticket-refer-friend-big-heading1-blue-sm.svg"
                                                                              />

                                                                              <HeadingPictureComponent 
                                                                              dataScroll
                                                                              className="ticket-orange"
                                                                              srcSet="ticket-refer-friend-big-heading1-orange.svg"
                                                                              src="ticket-refer-friend-big-heading1-orange-sm.svg"
                                                                              />

                                                                              <picture className="d-none d-md-block">
                                                                                    <ImageComponent dataScroll className="ticket ticket-green" type="tickets" src="ticket-refer-friend-big-heading1-green.svg"/>
                                                                              </picture>
                                                                        </div>
                                                                  </div>
                                                                  {/* <!-- .content__header --> */}

                                                                  <div className="content__body">
                                                                        <div className="card card__invite-link mx-auto">
                                                                              <div className="card-body text-center">
                                                                                    <h3 className="heading">
                                                                                          Share this link with friends.
                                                                                    </h3>
                                                                                    <p className="lead">
                                                                                    You’ll get a promo code for 10% off (max discount £5) any seat-filling booking for each friend who becomes an active member*.
                                                                                    </p>
                                                                                    <div className="form-group">
                                                                                          <div className="input-group input-group__invite-link mx-auto">
                                                                                                <input id="inviteLink" type="text" className="form-control faux-read-only" value={data ? data.data.link_for_web :""} readOnly disabled={isLoading}/>
                                                                                                <button id="copyInviteLink" className="btn btn-primary fw-normal" type="button" onClick={data ? copyToClipboard : null} disabled={isLoading}>
                                                                                                      {copy}
                                                                                                </button>
                                                                                          </div>
                                                                                    </div>
                                                                                    {/* <!-- /.form-group --> */}
                                                                                    <div className="form-group form-group__cta mb-0">
                                                                                          <button type="button" className="btn btn__modal-slide-down btn__modal-fw btn__modal-terms-conditions has-icon mx-auto p-0" data-bs-toggle="modal" data-bs-target="#modalTermsAndConditions" onClick={termsPopupHandler}>
                                                                                                <ImageComponent className="btn__icon" type="images" src={isAjtix ? "icon-arrow-right-34x31-red-ajtix.svg" : (isTfm ?  "icon-arrow-right-34x31-red-tfm.svg": "icon-arrow-right-34x31-red.svg") }/>
                                                                                                <span className="btn__text red text-decoration-underline">Terms & Conditions apply</span>
                                                                                                <ImageComponent className="btn__icon" type="images" src={isAjtix ? "icon-arrow-left-34x31-red-ajtix.svg" : ( isTfm ? "icon-arrow-left-34x31-red-tfm.svg":"icon-arrow-left-34x31-red.svg" )}/>
                                                                                                
                                                                                          </button>
                                                                                    </div>
                                                                                    {/* <!-- /.form-group --> */}
                                                                              </div>
                                                                        </div>
                                                                        {/* <!-- .card__invite-link --> */}
                                                                  </div>
                                                                  {/* <!-- .content__body --> */}

                                                                  {/* <div className="social-share-buttons">
                                                                        <div className="social-share-button">
                                                                              <FacebookShareButton 
                                                                                    url={URL.SITE_BASE_URL}
                                                                                    quote={yourTitle}
                                                                                    hashtag="#CentralTickets" >
                                                                                    <FacebookIcon size={40} round={true} />
                                                                              </FacebookShareButton>
                                                                        </div>
                                                                        <div className="social-share-button">
                                                                              <WhatsappShareButton url={URL.SITE_BASE_URL} title={message} hashtag={"#CentralTickets"}>
                                                                                    <WhatsappIcon size={40} round={true} />
                                                                              </WhatsappShareButton>
                                                                        </div>
                                                                        <div className="social-share-button">
                                                                              <EmailShareButton url={URL.SITE_BASE_URL} subject={yourSubject} body={yourBody}>
                                                                                    <EmailIcon size={40} round={true} />
                                                                              </EmailShareButton>
                                                                        </div>
                                                                        <div className="social-share-button">
                                                                              <LinkedinShareButton url={URL.SITE_BASE_URL} title={yourTitle} summary={yourSummary}>
                                                                                    <LinkedinIcon size={40} round={true} />
                                                                              </LinkedinShareButton>
                                                                        </div>
                                                                  </div> */}
                                                                  
                                                            </div>
                                                            {/* <!-- .content__refer-friend --> */}
                                                      </div>
                                                      {/* <!-- .col-md-12 --> */}
                                                </div>
                                                {/* <!-- /.row --> */}
                                          </div>
                                          {/* <!-- /.container-fluid  --> */}
                                    </section>
                                    {/* <!-- /.section__refer-friend --> */}

                                    <div data-scroll-section className="border-top mt-auto">
                                          <TickerText id="tickerShowCatsOne" textData={catsOneText}/>
                                          {/* <!-- /.ticker --> */}
                                    </div>

                              </main>

                              <Footer/>

                        </div>
                        {/* <!-- /div[data-scroll-container] --> */}

                        {/* <!-- modalTermsAndConditions --> */}
                        {/* $('.modal__terms-and-conditions .btn-close').click(function() {
                        +    $('body').removeClass('modal-slide-down');
                        +    $('.modal-backdrop').removeClass('fadein');
                        +  }); */}
                        {showTerms && <TermsModal termsPopupHandler={termsPopupHandler}/>}
                  </React.Fragment>
      )
}

export default ReferFriend;
