import React from 'react'
// external css
// redux and react hooks

// child component
import HomeCarouselItem from './HomeCarouselItem';

// packages
import OwlCarousel from 'react-owl-carousel';

// Actions & RTK query or mutations
// Constants
import { SectionHomeCarousel } from '../../resources/OwlCarouselOptions'; 

const HomeCarousel = ({ data, index, isHighlighted, afterLoad }) => {

      

      return (
            <div key={`${data.SectionType} + ${index.toString()}`}>
                  <div className="row">
                        <div className="col-md-12">
                              <div className="content content__carousel-home-hero home-carousel">
                                    <div className="content__body position-relative">
                                          <OwlCarousel className='owl-carousel owl-carousel__home-hero owl-theme'  {...SectionHomeCarousel}
                                          // onChanged={handleSlideChanged}
                                          autoplay={false}>
                                                {data && data.Items.map(el=>{

                                                      return <HomeCarouselItem el={el} afterLoad={afterLoad} isHighlighted={isHighlighted}/>
                                                            
                                                })}
                                          </OwlCarousel>
                                    </div>
                                    {/* <!-- /.content__body --> */}
                              </div>
                              {/* <!-- /.content --> */}
                        </div>
                        {/* <!-- .col-md-12 --> */}
                  </div>
            </div>
      )
}

export default HomeCarousel
