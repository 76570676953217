import React from 'react';

// external css
// redux and react hooks
// child component
// packages
// Actions & RTK query or mutations
// Constants

// content__header--template
import ticketCurrentListingsBigHeading1Blue from "../../../assets/images/tickets/ticket-current-listings-big-heading1-blue.svg";
import ticketCurrentListingsBigHeading1BlueSm from "../../../assets/images/tickets/ticket-current-listings-big-heading1-blue-sm.svg";

import ticketCurrentListingsBigHeading1Red from "../../../assets/images/tickets/ticket-current-listings-big-heading1-red.svg";
import ticketCurrentListingsBigHeading1RedSm from "../../../assets/images/tickets/ticket-current-listings-big-heading1-red-sm.svg";

import ticketCurrentListingsBigHeading1Green from "../../../assets/images/tickets/ticket-current-listings-big-heading1-green.svg";
import ticketCurrentListingsBigHeading1GreenSm from "../../../assets/images/tickets/ticket-current-listings-big-heading1-green-sm.svg";

import ticketCurrentListingsBigHeading1Aquamarine from "../../../assets/images/tickets/ticket-current-listings-big-heading1-aquamarine.svg";
import ticketCurrentListingsBigHeading1AquamarineSm from "../../../assets/images/tickets/ticket-current-listings-big-heading1-aquamarine-sm.svg";

import ticketCurrentListingsBigHeading1Yellow from "../../../assets/images/tickets/ticket-current-listings-big-heading1-yellow.svg";
import ticketCurrentListingsBigHeading1YellowSm from "../../../assets/images/tickets/ticket-current-listings-big-heading1-yellow-sm.svg";

const ContentHeader = () => {

      return (
            
            <React.Fragment>
                <div className="content__header content__header--template border-bottom text-center overflow-hidden">
                        <div className="big-heading">
                              <h1 className="mb-0">Current Listings</h1>
                              <picture>
                                    <source media="(min-width: 992px)" srcSet={ticketCurrentListingsBigHeading1Blue}/>
                                    <img className="ticket ticket-blue" src={ticketCurrentListingsBigHeading1BlueSm} alt=""/>
                              </picture>
                              <picture>
                                    <source media="(min-width: 992px)" srcSet={ticketCurrentListingsBigHeading1Red}/>
                                    <img className="ticket ticket-red" src={ticketCurrentListingsBigHeading1RedSm} alt=""/>
                              </picture>
                              <picture>
                                    <source media="(min-width: 992px)" srcSet={ticketCurrentListingsBigHeading1Green}/>
                                    <img className="ticket ticket-green" src={ticketCurrentListingsBigHeading1GreenSm} alt=""/>
                              </picture>
                              <picture>
                                    <source media="(min-width: 992px)" srcSet={ticketCurrentListingsBigHeading1Aquamarine}/>
                                    <img className="ticket ticket-aquamarine" src={ticketCurrentListingsBigHeading1AquamarineSm} alt=""/>
                              </picture>
                              <picture>
                                    <source media="(min-width: 992px)" srcSet={ticketCurrentListingsBigHeading1Yellow}/>
                                    <img className="ticket ticket-yellow" src={ticketCurrentListingsBigHeading1YellowSm} alt=""/>
                              </picture>
                        </div>
                  </div>
            </React.Fragment>
      )
}

export default ContentHeader;
