import React, { useState, useEffect } from 'react'
// external css
import iconLocationPinBlack from "./../../assets/images/icon-location-pin-black.svg";
import iconClockBlack from "./../../assets/images/icon-clock-black.svg";
import iconPaymentCardBlack from "./../../assets/images/icon-payment-card-black.svg";
import iconLocationPinWhite from "./../../assets/images/icon-location-pin-white.svg";
import iconPaymentCardSmWhite from "./../../assets/images/icon-payment-card-sm-white.svg"
import iconClockWhite from "./../../assets/images/icon-clock-white.svg";

// redux and react hooks
import { Link } from 'react-router-dom';

// child component
import { CurrentListingDummyImage } from '../../Components';

// packages
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Actions & RTK query or mutations
import { useFavUnFavMutation } from '../../Services/modules/favourites';
// Constants

const HomeCarouselItem = ({ el, afterLoad, isHighlighted }) => {

      const [isFavourite,setIsFavourite]  =     useState(el.is_favorite)
      const [imageLoaded,setImageLoaded]  =     useState(true)
      const [displayLazy,setDisplayLazy]  =     useState(true)

      const [favUnFav, { isSuccess : favUnFavIsSuccess, data : favData}]        =    useFavUnFavMutation();


      useEffect(()=>{

            if(!imageLoaded){
                  afterLoad()
            }
            
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[imageLoaded])


      useEffect(()=>{
            if(favUnFavIsSuccess){
                  console.log(favData)
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[favUnFavIsSuccess])

      const onLoadHandler = ()=> {
            // commment from here and added in useEffect which dependency is imageLoaded
            // props.afterLoad()
            setImageLoaded(false)
      }

      const favUnfavHandler = (event, id)=> {
            event.preventDefault()
            favUnFav(id);
            setIsFavourite(!isFavourite)
      } 

      return (
            <div className="item" >
                  <div className="card card__event">
                        <div className="card__event-image">
                              <ul className="list__event-tags">
                                    {el.labels && el.labels.map(label=>{
                                          return (
                                                <li>
                                                      <div className="badge" style={{ backgroundColor : label.bgColor  ? label.bgColor :  "",color : label.fgColor ? label.fgColor : ""}}>{label.displayText}</div>
                                                </li> 
                                          )
                                    })}
                                    
                              </ul>
                              <Link className={["btn-heart", isFavourite && "active"].join(" ")} to="/" onClick={(event)=>favUnfavHandler(event, el.id)}></Link>

                              <Link to={`/event-details/${el.id}`} style={{ position : "relative"}}>
                                    <CurrentListingDummyImage />
                                    {/* <img className="event-image" src="/static/media/alterImage.8249c623c2794d6e3c99.png" alt=""/> */}

                                    {displayLazy && <LazyLoadImage className={["event-image event-image-listing event-image-home-carousel"].join(" ")} src={el.event_image} srcSet={`${el.event_image} 2x`} 
                                    onLoad={onLoadHandler} 
                                    // visibleByDefault={true}
                                    // delayTime={5000}
                                    // placeholder={<img className="event-image" src={alterImage} effect="opacity" alt=''/>}
                                    onError={() => {
                                          // Handle image load failure
                                          setImageLoaded(true)
                                          setDisplayLazy(false)
                                          // commment from here and added in useEffect which dependency is imageLoaded
                                          // props.afterLoad()
                                    }}
                                    />}

                              </Link>
                        </div>
                        <div className="card-body">
                              <h5 className="card-title fw-bold">
                                    <Link to={`/event-details/${el.id}`}>
                                          {el.event_name}
                                    </Link>
                              </h5>
                              <div className="list-group list-group__event-details list-group-flat">
                                    <div className="list-group-item list-group__location">
                                          <div className="icon-wrapper">
                                                <img className="icon__location-pin" src={isHighlighted ? iconClockWhite : iconClockBlack} alt=""/>
                                          </div>
                                          <p className="mb-0">{el.event_date_time}</p>
                                    </div>
                                    <div className="list-group-item list-group__price">
                                          <div className="icon-wrapper">
                                                <img className="icon__location-pin" src={isHighlighted ? iconPaymentCardSmWhite  :iconPaymentCardBlack} alt=""/>
                                          </div>
                                          <p className="mb-0">Admin Fee: £{el.admin_fees}</p>
                                    </div>
                                    <div className="list-group-item list-group__time">
                                          <div className="icon-wrapper">
                                                <img className="icon__location-pin" src={isHighlighted ? iconLocationPinWhite  :iconLocationPinBlack} alt=""/>
                                          </div>
                                          <p className="mb-0">{el.address}</p>
                                    </div>
                              </div>
                        </div>
                        <div className="card-footer">
                              <div className="buttons-wrapper d-flex align-items-center justify-content-between">
                                    <Link className="btn btn-outline-primary hover-primary" to={`/event-details/${el.id}`} >
                                          Book now
                                    </Link>
                              </div>
                        </div>
                  </div>
            </div>
      )
}

export default HomeCarouselItem
