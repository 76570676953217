import React from 'react'

const UptakeMemberFeedback = () => {
      return (
            <div className="buttons-wrapper mt-lg-auto uptake-memberfeedback__dummy">
                  <a className="btn btn-primary" href={()=> false}></a>
            </div>
      )
}

export default UptakeMemberFeedback
