import React, { useState, useRef, useEffect } from 'react';


// external css
import { BackButtonUpdateNumber } from "./../../Components"
// redux and react hooks
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// child component
import Backdrop from '../Backdrop';

// packages
// Actions & RTK query or mutations
import { useOtpVerificationMutation, useSendOTPMutation } from "./../../Services/modules/Otp";
import { useUpdateProfileMutation } from '../../Services/modules/settings';
import { useUpdateEmailStatusMutation } from '../../Services/modules/Register';
import { updateRegistrationState, setIsRegister } from "../../Store/Register"
import { reset as resetUser, setMobileNumber, loginAfterCompleteRegistration} from "../../Store/User"
import { otpVerificationValidationRules } from "./../../Services";
import { setAlert } from './../../Store/UI';

// Constants
import { routesName } from '../../resources/RoutesName';
import { label, errors, messages } from "./../../resources/en";
import { NameConstants } from '../../resources/NameConstants';

const OtpModal = (props) => {

      const [state,setState] = useState({
            otpFirstChar:"",
            otpSecondChar:"",
            otpThirdChar:"",
            otpFourthChar:"",
            otpFifthChar:"",
      })
      const [frontError,setFrontError]    =     useState({error:false,errorMessage:""});
      const [seconds,setSeconds]          =     useState(0);
      const [minutes,setMinutes]          =     useState(0);
      const [isSentOtp,setIsSentOtp]      =     useState(true);
      
      // to change mobile number
      const [mobNum,setMobNum]                        =     useState({error:false,errorMessage:"", value : ""});
      const [isChngMob,setIsChngMob]                  =     useState(false);
      const [isSentChngMobReq,setIsSentChngMobReq]    =     useState(false);

      const [otpVerification, { data, isSuccess, isLoading, isFetching, error }] = useOtpVerificationMutation();
      const [updateProfile,{ data : upData, isSuccess : upIsSuccess, isLoading : upIsLoading, isFetching : upIsFetching, error : upError }]  =    useUpdateProfileMutation();
      const [sendOTPToVerify,sendOTPData] = useSendOTPMutation();
      const [updateEmailStatus, { data : upeData, isSuccess: upeIsSuccess, isLoading : upeIsLoading, isFetching : upeIsFetching, error : upeError }] = useUpdateEmailStatusMutation();

      const dispatch = useDispatch();
      const navigate = useNavigate();
      const user = useSelector(state=>state.user)
      const register = useSelector(state=>state.register)

      
      // to change mobile number
      useEffect(()=>{

            if(!user.ct_access_mobilenumber){
                  return
            }

            setMobNum(prevState=>{
                  return {
                        ...prevState,
                        value : user.ct_access_mobilenumber
                  }
            })
      },[user.ct_access_mobilenumber])

      useEffect(()=>{
            resetStates();
            setIsChngMob(false)
            setIsSentChngMobReq(false)
            setMobNum({error:false,errorMessage:"", value : ""})

      },[register.ct_otp_modal_status])

      // Check if existing number is invalid then directly show change number options
      useEffect(()=>{
            if(register.mobile_number_validation){
                  if(!register.mobile_number_validation.is_valid && register.ct_otp_modal_status === "otp"){
                        changeMobHandler()
                  }
            }
            
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

      useEffect(()=>{
            if((register.ct_otp_modal_status === "otp" || register.ct_otp_modal_status === "otpEmail") && register.step === "step2") {
                  // const  type = register.ct_otp_modal_status === "otp" ? "mobile" : "email"
                  // if(!register.isRegister && register.mobile_number_validation.is_valid){
                        // sendOTPToVerify({type : type});
                  // }
                  setIsSentOtp(false)
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

      const resetStates = ()=>{
            setSeconds(0)
            setMinutes(0)
            setIsSentOtp(true)
            setState({
                  otpFirstChar:"",
                  otpSecondChar:"",
                  otpThirdChar:"",
                  otpFourthChar:"",
                  otpFifthChar:"",
            })
            setFrontError({error:false,errorMessage:""})
      }
      
      //  Handling verify OTP API Request
      useEffect(()=>{

            if(isSuccess){
                  if(data.status === "1"){
                        resetStates();

                        if(register.ct_otp_modal_status === "otpEmail") {
                              const registration_step = {
                                    ...register.registration_step,
                                    otp_verify_email : true
                              }

                              // Send OTP to Mobile if mobile is not verified
                              if(!registration_step.otp_verify && !register.isRegister) {
                                    sendOTPToVerify({ type:"mobile" });
                              }

                              let ct_otp_modal_status = !registration_step.otp_verify && "otp";
                              let isRemainRegister = (!registration_step.update_region || !registration_step.update_categories || !registration_step.update_email_preferences) ?  true : false

                              if(isRemainRegister || ct_otp_modal_status === "otp"){
                                    
                                    dispatch(updateRegistrationState({
                                          ct_otp_modal_status : ct_otp_modal_status,
                                          step : "step2",
                                          registration_step : registration_step
                                    }))

                              } else {

                                    // Check further no steps remains to update then login user
                                    dispatch(loginAfterCompleteRegistration({auth : true}))
                                    dispatch(setIsRegister({isRegister : false}))
                                    dispatch(updateRegistrationState({
                                          ct_otp_modal_status : "",
                                          step : "step1",
                                          registration_step : null
                                    }))
                                    navigate(routesName.CurrentListing);
                              }


                        } else if(register.ct_otp_modal_status === "otp") {
                              const registration_step = {
                                    ...register.registration_step,
                                    otp_verify : true
                              }

                              let isRemainRegister = (!registration_step.update_region || !registration_step.update_categories || !registration_step.update_email_preferences) ?  true : false

                              if(isRemainRegister) {

                                    dispatch(updateRegistrationState({
                                          ct_otp_modal_status : "",
                                          step : "step2",
                                          registration_step : registration_step
                                    }))
                              } else {
                                    // Check further no steps remains to update then login user
                                    dispatch(loginAfterCompleteRegistration({auth : true}))
                                    dispatch(setIsRegister({isRegister : false}))
                                    dispatch(updateRegistrationState({
                                          ct_otp_modal_status : "",
                                          step : "step1",
                                          registration_step : null
                                    }))
                                    navigate(routesName.CurrentListing);
                              }
                        }     

                  }

                  if(data.status === "0"){
                        setFrontError(prevState=>{
                              return {
                                    error:true,
                                    errorMessage:data.message
                              }
                        })
                  }
                 
            } 
           
            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[data, isSuccess, isLoading, isFetching, error, dispatch])

      // Handling Send OTP API Request
      useEffect(()=>{

            if(sendOTPData.isSuccess){
                  if(sendOTPData.data.status === "1"){
                        // console.log(sendOTPData.data)
                  }

                  if(sendOTPData.data.status === "0"){
                        setFrontError(prevState=>{
                              return {
                                    error:true,
                                    errorMessage:sendOTPData.data.message
                              }
                        })

                  }
            } 
           
            if(sendOTPData.error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:sendOTPData.error.error || sendOTPData.error.data.error || sendOTPData.error.data.message || errors.NotFound404}))
            }

      },[sendOTPData.data, sendOTPData.isSuccess, sendOTPData.isLoading, sendOTPData.isFetching, sendOTPData.error, dispatch])


      // Handling email update status API Request 
      useEffect(()=>{
            if(upeIsSuccess) {
                  if(upeData.status === "1"){
                        resetStates();

                        if(register.ct_otp_modal_status === "otpEmail") {
                              const registration_step = {
                                    ...register.registration_step,
                                    otp_verify_email : true
                              }

                              // Send OTP to Mobile if mobile is not verified
                              if(!registration_step.otp_verify && !register.isRegister) {
                                    sendOTPToVerify({ type:"mobile" });
                              }

                              let ct_otp_modal_status = !registration_step.otp_verify && "otp";
                              let isRemainRegister = (!registration_step.update_region || !registration_step.update_categories || !registration_step.update_email_preferences) ?  true : false

                              if(isRemainRegister || ct_otp_modal_status === "otp"){
                                    
                                    dispatch(updateRegistrationState({
                                          ct_otp_modal_status : ct_otp_modal_status,
                                          step : "step2",
                                          registration_step : registration_step
                                    }))
                              }

                        }   

                  }

                  if(upeData.status === "0"){
                        setFrontError(prevState=>{
                              return {
                                    error:true,
                                    errorMessage:upeData.message
                              }
                        })
                  }
            }

            if(upeError) {
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:upeError.error || upeError.data.error || upeError.data.message || errors.NotFound404}))
            }
      }, [upeData, upeIsSuccess, upeIsLoading, upeIsFetching, upeError, dispatch])

      useEffect(()=>{
            let myInterval
            if(!isSentOtp){

                  myInterval = setInterval(() => {
                        if (seconds > 0) {
                              setSeconds(seconds-1)
                        }
                        if (seconds === 0) {
                              if (minutes === 0) {
                                    clearInterval(myInterval)
                                    setIsSentOtp(true);
                              } else {
                                    setMinutes(minutes-1)
                                    setSeconds(59)
                              }
                        } 
                  }, 1000)
            }

            return ()=>{
                  clearInterval(myInterval);
            }
      })


      //Handler Update Mobile Number response
      useEffect(()=>{

            if(upIsSuccess){
                  if(upData.status === "1"){
                        setIsSentChngMobReq(true)
                        setIsSentOtp(true)
                        dispatch(setMobileNumber({ ct_access_mobilenumber : mobNum.value }))
                  } else if(upData.status === "0"){
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:upData.error || upData.errors || upData.message || errors.NotFound404}));
                  }
            }

            if(upError){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:upError.error || upError.data.error || upError.data.message || errors.NotFound404}));
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[upData, upIsSuccess, upIsLoading, upIsFetching, error, upError])

      const firstInputRef     = useRef();
      const secondInputRef    = useRef();
      const thirdInputRef     = useRef();
      const fourthInputRef    = useRef();
      const fifthInputRef     = useRef();

      const focusHandler = ()=>{
            setFrontError(prevState=>{
                  return {
                        error:false,
                        errorMessage:""
                  }
            })
      }

      const closeModalHandler = (event)=>{
            event.preventDefault();
            dispatch(setIsRegister({isRegister : false}))
            dispatch(resetUser())
            dispatch(updateRegistrationState({
                  ct_otp_modal_status : "",
                  step : "step1",
                  registration_step : null
            }))
            navigate(routesName.Home);

      }

      const changeHandler = (event)=> {

            const key = event.target.name;
            const onlyNumbers = event.target.value.replace(/\D/g, '');

            setState(prevState=>{

                  return {
                        ...prevState,
                        [key] :onlyNumbers
                  }
            })

            if(!onlyNumbers){
                  if(key === "otpFifthChar"){
                        fourthInputRef.current.focus();
                  }
                  else if(key === "otpFourthChar"){
                        thirdInputRef.current.focus();
                  }
                  else if(key === "otpThirdChar"){
                        secondInputRef.current.focus();
                  }
                  else if (key === "otpSecondChar"){
                        firstInputRef.current.focus();
                  }
                  return;
            }
            else {

                  if(key === "otpFirstChar"){
                        secondInputRef.current.focus();
                  }
                  else if(key === "otpSecondChar"){
                        thirdInputRef.current.focus();
                  }
                  else if(key === "otpThirdChar"){
                        fourthInputRef.current.focus();
                  }
                  else if(key === "otpFourthChar"){
                        fifthInputRef.current.focus();
                  }
                  
            }

      }

     

      const sendOTPHandler = (event)=>{
            event.preventDefault();

            const  type = register.ct_otp_modal_status === "otp" ? "mobile" : "email"
            sendOTPToVerify({ type:type });
            setIsSentOtp(false);
            setMinutes(1);
            setSeconds(0);
      }

      const submitOtpHandler = (event)=>{
            event.preventDefault();

            let otp = "";

            for(let i in state){
                  if(state[i]){
                        otp+=state[i]
                  }
            }
            const validation = otpVerificationValidationRules(otp,register.ct_otp_modal_status);
            if(!validation.isValid){
                  setFrontError({error:true,errorMessage:validation.validationMessage})
                  return;
            }
            
            if(validation.isValid && otp.length === 5){
                    
                  let type= "";
                  if(register.ct_otp_modal_status === "otp") {
                        type = "phonenumber"
                  } else if (register.ct_otp_modal_status === "otpEmail") {
                        type = "email"
                  }
                  
                  otpVerification({
                        "otp":otp,
                        "type":type,
                        "workflow": isChngMob ? "change_phonenumber" : "registration"
                  })

            }
      }

      // To chnage mobile number
      const chnageMobileNumberHandler = (event)=>{

            if(event.target.value.length > 11) {
                  return
            }
            setMobNum(prevState=>{
                  return {
                        ...prevState,
                        value : event.target.value
                  }
            })
      }

      // To enter mobile number to change mobile number
      const changeMobHandler = ()=>{

            const existing_number = register.mobile_number_validation?.existing_number || register.existing_register_number
            if(isChngMob && user.ct_access_mobilenumber !== existing_number){
                  dispatch(setMobileNumber({ ct_access_mobilenumber : existing_number }))
            }
            setIsChngMob(!isChngMob)
            setIsSentChngMobReq(false)
            setState({
                  otpFirstChar:"",
                  otpSecondChar:"",
                  otpThirdChar:"",
                  otpFourthChar:"",
                  otpFifthChar:"",
            })
            setFrontError({error:false,errorMessage:""})
      }

      // To clear change mobile number error
      const focusMobileNumberHandler = ()=> {
            setMobNum(prevState=>{
                  return {
                        ...prevState,
                        error : false,
                        errorMessage : ""
                  }
            })
      }

      // To submit change mobile number
      const submitMobileNumberHandler = (event)=>{
            event.preventDefault();

            if(isSentChngMobReq){
                  setIsSentChngMobReq(false)
                  setIsSentOtp(false)
                  setMinutes(1);
                  setSeconds(0);
                  setState({
                        otpFirstChar:"",
                        otpSecondChar:"",
                        otpThirdChar:"",
                        otpFourthChar:"",
                        otpFifthChar:"",
                  })
                  setFrontError({error:false,errorMessage:""})
                  return;
            }

            const validation = otpVerificationValidationRules(mobNum.value, NameConstants.PhoneNumber)
            
            if(!validation.isValid){

                  setMobNum(prevState=>{
                        return {
                              ...prevState,
                              error : true,
                              errorMessage : validation.validationMessage
                        }
                  })
            }else {
                  const data = {
                        mobilenumber : mobNum.value
                  }

                  // setIsSentChngMobReq(true)
                  // setIsSentOtp(true)
                  updateProfile(data)

            }
      }

      const emailUpdateStatusHandler = () => {
            if(!upeIsLoading) {
                  updateEmailStatus()
            }
      }


      let underneathText = null

      let dindtReceiveText = "Didn’t receive it?"     
      let sendItagainLink = <Link className="blue" to="" onClick={sendOTPHandler}>Send it again</Link>
      let sendItagainInLink = <Link className="blue" to="" onClick={(e)=>{e.preventDefault()}}>Send it again in {String(minutes).padStart(2, '0') +":"+ String(seconds).padStart(2, '0')}</Link>
      let emailVerifiedByEmailLink = <Link className="blue" to="" onClick={emailUpdateStatusHandler}>Click here if you've already verified by email link</Link>
      let changeMobNumLink = <span className="blue" style={{ textDecoration : isChngMob ? 'none' : ''}}  onClick={changeMobHandler}>{isChngMob ? <BackButtonUpdateNumber /> : 'Change mobile number'}</span>

      
      
      underneathText  =  register.ct_otp_modal_status === "otp" ? 
                        <p className="mb-0 text-center">{dindtReceiveText} {sendItagainInLink} <br/>{changeMobNumLink}</p>  :    
                        <p className="mb-0 text-center">{dindtReceiveText} {sendItagainInLink} </p>  

                  

      if(isSentOtp && (register.ct_otp_modal_status === "otp" || register.ct_otp_modal_status === "otpEmail")) {
            underneathText = register.ct_otp_modal_status === "otp" ? 
                  <p className="mb-0 text-center">{dindtReceiveText} {sendItagainLink} <br/>{changeMobNumLink}</p>  : 
                  <p className="mb-0 text-center">{dindtReceiveText} {sendItagainLink} </p>   
      }
    
      let underneathTextForEmailVerifiedByLink

      if(register.ct_otp_modal_status === "otpEmail") { 
            underneathTextForEmailVerifiedByLink = <p className="mb-4 mt-4 text-center">{emailVerifiedByEmailLink}</p>  
      }
      
      if(isChngMob){
            if(!isSentChngMobReq){
                  const isValidMobile = register.mobile_number_validation?.is_valid
                  if(isValidMobile){
                        underneathText = <p className="mb-0 text-center">{changeMobNumLink}</p>     
                  } else {
                        underneathText = null
                        // underneathText = <p className="mb-0 text-center">{changeMobNumLink}</p>
                  }
            } else {
                  underneathText = <p className="mb-0 text-center">{dindtReceiveText} {!isSentOtp ? sendItagainInLink : sendItagainLink}</p>     
            }
      }

      let otpOption = 
            <>
                  <div id="otp" className={["form-group__verification-code d-flex justify-content-center text-center",frontError.error ?"form-group__invalid-verification-code" : ""].join(" ")}>
                        <input id="otpFirstChar" name="otpFirstChar" type="text" className="form-control" maxLength="1" autoFocus onChange={changeHandler} value={state.otpFirstChar} ref={firstInputRef} onFocus={focusHandler}/>
                        <input id="otpSecondChar" name="otpSecondChar" type="text" className="form-control" maxLength="1" onChange={changeHandler} value={state.otpSecondChar} ref={secondInputRef} onFocus={focusHandler}/>
                        <input id="otpThirdChar" name="otpThirdChar" type="text" className="form-control" maxLength="1" onChange={changeHandler}
                        value={state.otpThirdChar} ref={thirdInputRef} onFocus={focusHandler}/>
                        <input id="otpFourthChar" name="otpFourthChar" type="text" className="form-control" maxLength="1" onChange={changeHandler}
                        value={state.otpFourthChar} ref={fourthInputRef} onFocus={focusHandler}/>
                        <input id="otpFifthChar" name="otpFifthChar" type="text" className="form-control" maxLength="1" onChange={changeHandler}
                        value={state.otpFifthChar} ref={fifthInputRef} onFocus={focusHandler}/>
                        
                  </div>
                  {frontError.error && <p className="invalid-otp">{frontError.errorMessage}</p>}
                  <div className="form-group__cta d-flex justify-content-center">
                        <button type="submit" className="btn btn-xl-lg btn-primary" disabled={sendOTPData.isLoading || isLoading || upIsLoading} onClick={submitOtpHandler}>Verify</button>
                  </div>
            </>

      if(isChngMob && !isSentChngMobReq) {
            otpOption = null
      }

      

      let displayMessage = label.otpVerifyModal.replace('{{mobile}}',user.ct_access_mobilenumber)
      if(isChngMob) {
            if(!isSentChngMobReq) {
                  displayMessage = label.updateMobileNumber 
                  // displayMessage = register.mobile_number_validation ? register.mobile_number_validation.message  : label.updateMobileNumber 
            } 
            
      }

      return (

                  <React.Fragment>
                        <div className={["modal modal__registration-verification show fade",(props.status === "entering" || props.status === "entered") && "modalOpen",(props.status === "exiting" || props.status === "exited") && "modalClosed"].join(" ")} id="modalVerificationCode" tabIndex="-1" aria-hidden="true" style={{"display":"block"}}>
                              <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                          <div className="modal-body">
                                                {register.ct_otp_modal_status === "otp" &&
                                                <button type="button" className="btn-close btn-close__custom btn-close__absolute" data-bs-dismiss="modal" aria-label="Close" onClick={closeModalHandler}>
                                                </button>}

                                                <div className="content">
                                                      <div className="content__body">
                                                            <form>
                                                                  <p className="mb-0">
                                                                        {register.ct_otp_modal_status === "otp" ? displayMessage : label.otpEmailVerifyModal}
                                                                  </p>
                                                                  {isChngMob &&
                                                                  <div className={["form-group__verification-code d-flex justify-content-center text-center form-group__button"].join(" ")}>
                                                                        <input id="changeMobile"  type="text" className="form-control" onChange={chnageMobileNumberHandler} value={mobNum.value} onFocus={focusMobileNumberHandler} disabled={isSentChngMobReq} placeholder='Enter Mobile Number'/>
                                                                        {mobNum.error && <p className="invalid-mobile-number">{mobNum.errorMessage}</p>}
                                                                        <button type="submit" className="btn btn-xl-lg btn-primary" onClick={submitMobileNumberHandler} disabled={upIsLoading || isLoading}>{isSentChngMobReq ? "Edit Mobile Number" : 'Update mobile number'}</button>
                                                                  </div> }
                                                                  
                                                                  {otpOption}
                                                                  {underneathText}
                                                                  {underneathTextForEmailVerifiedByLink}
                                                                  
                                                            </form>
                                                      </div>
                                                      {/* <!-- /.content__body --> */}
                                                </div>
                                                {/* <!-- /.content --> */}

                                          </div>
                                          {/* <!-- /.modal-body --> */}

                                    </div>
                                    {/* <!-- /.modal-content --> */}
                              </div>
                              {/* <!-- /.modal-dialog --> */}
                        </div>
                        {/* <!-- modal #modalVerificationCode --> */}
                        <Backdrop />
                  </React.Fragment>
                 
      )
}

export default OtpModal;