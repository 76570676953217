import React from 'react'

import info from "../../assets/images/info.png"

const SubTypeInfoEcology = ({ data }) => {
      

      console.log(data)

      return (
            <div className={["flyer-section", data.SubType === "Wallet" && "flyer-wallet-section", data.SubType === "Refer" && "flyer-wallet-refer", "flyer-ecologi"].join(" ")} >
                  <div className='content' style={{ backgroundColor : data.BackgroundColor }}>
                        <div className='image-wrapper'>
                              <img src={info} alt='' />
                        </div>
                        <div className='description-wrapper'>
                              <p style={{ color: data.DesciptionColor}}>
                                    {data.Description}
                              </p>
                        </div>
                        <div className="hero-cta">
                              <div className="buttons-wrapper" style={{ backgroundColor : data.ButtonBackgroundColor, borderColor: data.ButtonTextColor, color: data.ButtonTextColor }}>
                                    <a className="btn btn-xl-lg btn-primary" href={data.RedirectTo} target="_blank" rel='noreferrer' style={{ color: data.ButtonTextColor}}>
                                          {data.ButtonTitle}
                                    </a>
                              </div>
                              
                        </div>
                  </div>
            </div> 
      )
}

export default SubTypeInfoEcology
