import React, { useState, useEffect } from 'react'

// external css

// redux and react hooks
import { Link } from 'react-router-dom';

// child component
import { ExternalCarouselDummyImage } from '../../Components';

// packages
import OwlCarousel from 'react-owl-carousel';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Actions & RTK query or mutations
// Constants
import { SectionHomeCarouselExternal } from '../../resources/OwlCarouselOptions'; 


const HomeCarouselExternal = ({ data, index, isHighlighted, afterLoad }) => {

      const [imageLoaded,setImageLoaded]  =     useState(true)
      const [displayLazy,setDisplayLazy]  =     useState(true)


      useEffect(()=>{

            if(!imageLoaded){
                  afterLoad()
            }
            
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[imageLoaded])

      const onLoadHandler = ()=> {
            // commment from here and added in useEffect which dependency is imageLoaded
            // props.afterLoad()
            setImageLoaded(false)
      }

      return (
            <div key={`${data.SectionType} + ${index.toString()}`}>
                  <div className="row">
                              <div className="col-md-12">
                                    <div className="content content__carousel-home-hero external-carousel">
                                          <div className="content__body position-relative">
                                                <OwlCarousel className='owl-carousel owl-carousel__home-hero owl-theme'  {...SectionHomeCarouselExternal}
                                                // onChanged={handleSlideChanged}
                                                mergeFit={true} 
                                                autoplay={false}>
                                                      {data && data.Items.map(el=>{
                                                            
                                                            return (
                                                                  <div className="item home-carousel-external" >
                                                                        <div className="card card__event">
                                                                              <div className="card__event-image">
                                                                                    <ul className="list__event-tags">
                                                                                          {el.labels && el.labels.map(label=>{
                                                                                                return (
                                                                                                      <li>
                                                                                                            <div className="badge" style={{ backgroundColor : label.bgColor  ? label.bgColor :  "",color : label.fgColor ? label.fgColor : ""}}>{label.displayText}</div>
                                                                                                      </li> 
                                                                                                )
                                                                                          })}
                                                                                          
                                                                                    </ul>
                                                                                    <Link to={`/event-details/${el.id}`} style={{ position : "relative" }}>
                                                                                          <ExternalCarouselDummyImage />
                                                                                          {/* <img className="event-image" src="/static/media/alterImage.8249c623c2794d6e3c99.png" alt=""/> */}

                                                                                          {displayLazy && <LazyLoadImage className={["event-image event-image-listing event-image-home-carousel"].join(" ")} src={el.event_image} srcSet={`${el.event_image} 2x`} 
                                                                                                onLoad={onLoadHandler} 
                                                                                                onError={() => {
                                                                                                      // Handle image load failure
                                                                                                      setImageLoaded(true)
                                                                                                      setDisplayLazy(false)
                                                                                                      // commment from here and added in useEffect which dependency is imageLoaded
                                                                                                      // props.afterLoad()
                                                                                                }}
                                                                                          />}
                                                                                         
                                                                                    </Link>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                            )
                                                      })}
                                                </OwlCarousel>
                                          </div>
                                          {/* <!-- /.content__body --> */}
                                    </div>
                                    {/* <!-- /.content --> */}
                              </div>
                              {/* <!-- .col-md-12 --> */}
                        </div>
            </div>
      )
}

export default HomeCarouselExternal
