import { validation } from "../resources/en";
import { NameConstants } from "../resources/NameConstants";

const checkValidationBookingModule=(value, type, totalAmount, walletBalance) =>{
      let isValid = true;
      let validatingMessage = "";
      if (!type) {
          return true;
      }


      if(type === NameConstants.partPayment){
            if(!value || value === 0) {
                  isValid = false;
                  validatingMessage = validation[type].invalid;

            } else if(value.trim() ==='') {
                  isValid = false;
                  validatingMessage = validation[type].invalid;

            }else if(value > totalAmount) {
                  isValid = false;
                  validatingMessage = validation[type].more;
            } else if(value > walletBalance) {
                  isValid = false;
                  validatingMessage = validation[type].enoughBalance;
            }
            
      }

      return {isValid,validatingMessage};
}

export default checkValidationBookingModule;
