import React, { useState, useEffect } from 'react'

// external css
// redux and react hooks
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// child component
import Footer from '../../Layouts/Footer/Footer';

// packages
import { Helmet } from 'react-helmet';

// Actions & RTK query or mutations
import { usePasswordChangeSubmitMutation } from "./../../Services/modules/users";
import { setAlert, setResetPasswordCode } from '../../Store/UI';
import { changePasswordValidationRules } from "./../../Services";

// Constants
import { routesName } from '../../resources/RoutesName';
import { actions, errors, label, title, messages } from '../../resources/en';
import { NameConstants } from '../../resources/NameConstants';
import { MetaData } from '../../resources/MetaData';

const ResetPassword = () => {
      
      const [state,setState]= useState({
            [NameConstants.NewPassword]:{value:"",error:false,errorMessage:""},
            [NameConstants.ConfirmPassword]:{value:"",error:false,errorMessage:""},
      })

      const ct_reset_password_code              =     useSelector(state => state.ui.ct_reset_password_code)
      const dispatch                            =     useDispatch();
      const navigate                            =     useNavigate();

      const [changePasswordSubmit,{data, isSuccess, isLoading, error}] = usePasswordChangeSubmitMutation();

      useEffect(()=>{
            document.title = "Reset Password";
      },[])
      
      useEffect(()=>{

            if(isSuccess){

                  if(data.status === "1"){
                        dispatch(setResetPasswordCode({ct_reset_password_code : ""}));
                        dispatch(setAlert({model:true,title:messages.modalSuccessTitle,message:data.message || errors.passwordUpdated }));
                        navigate(routesName.Home)
                  } else if(data.status === "0"){
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.message || errors.NotFound404}));
                  }
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}));
            }

      },[data, isSuccess, isLoading, error, dispatch, navigate])

      const changeStateHandler = (event)=>{
            event.preventDefault();
            const key = event.target.name;
            const value = event.target.value;
            setState(prevState=>{
                  return {
                        ...prevState,
                        [key]:{...prevState[key], value:value}
                  }
            })
      }

      const onfocusHandler = ()=>{
            setState(prevState=>{

                  return {
                        [NameConstants.NewPassword]:{...prevState.newPassword,error:false,errorMessage:""},
                        [NameConstants.ConfirmPassword]:{...prevState.confirmPassword,error:false,errorMessage:""},
                  }
            })
      }

      const resetPasswordHandler = (event)=>{
            event.preventDefault();
            const copy  = {
                  ...state
            }
            const errorStore = [];

            for(let key in state) {
                  const errorCheck = changePasswordValidationRules(state[key].value,key, state.newPassword.value);
                  if(!errorCheck.isValid){
                        copy[key] = {...copy[key],error:true,errorMessage:errorCheck.validationMessage}
                        errorStore.push(key);
                  }
            }

            if(errorStore.length > 0) {
                  setState(prevState=>{
                        return {
                              ...prevState,
                              ...copy
                        }
                  })

            } else {
                  const data = {password:state.newPassword.value,confirm_password:state.confirmPassword.value,code:ct_reset_password_code}
                  changePasswordSubmit(data)
            }

          

      }

      return (
                  <React.Fragment>
                        {/* <!-- REQUIRED wrapper div for parallax --> */}
                        <div className="scroll-animations position-relative" data-scroll-container>
                              <Helmet>
                                    <title>{MetaData.ResetPassword.title}</title>
                                    <meta name="description" content={MetaData.ResetPassword.description} />
                                    <meta name="keywords" content={MetaData.ResetPassword.keywords} />
                              </Helmet>
                              <main className="main__contact-us main__change-password">

                                    <section data-scroll-section className="section__contact-us py-0">
                                          {/* <!--
                                                NOTE: class "container__block" removes child row paddings and negative margins; and col-* paddings
                                          --> */}
                                          <div className="container-fluid container__block">
                                                <div className="row">
                                                      <div className="col-md-12">

                                                            <div className="content content__contact-us mx-auto">
                                                                  <div className="content__header content__header--template border-bottom text-center overflow-hidden">
                                                                        <div className="big-heading">
                                                                              <h1 className="h2 mb-0">{title.changePassword}</h1>
                                                                              
                                                                        </div>
                                                                  </div>
                                                                  {/* <!-- .content__header --> */}
                                                            </div>
                                                            {/* <!-- .content.content__contact-us --> */}

                                                      </div>
                                                      {/* <!-- .col-md-12 --> */}
                                                </div>
                                                {/* <!-- /.row --> */}
                                          </div>
                                          {/* <!-- /.container-fluid  --> */}

                                          <div className="container">
                                                <div className="row">
                                                      <div className="col-md-12">

                                                            <div className="content content-single-col content__contact-us mx-auto">
                                                                  <div className="content__body">

                                                                        <div className="content content__contact-form">
                                                                              <div className="content__body content__change-password">
                                                                                    <form>

                                                                                          <div className="form-group">
                                                                                                <label htmlFor="newPassword" className="form-label">{label.newPassword}</label>
                                                                                                <input type="password" className="form-control" name={NameConstants.NewPassword} id="newPassword" onChange={changeStateHandler} value={state.newPassword.value} onFocus={onfocusHandler}/>
                                                                                                {state.newPassword.error &&<label className='form-label-error'>{state.newPassword.errorMessage}</label>}
                                                                                          </div>
                                                                                          {/* <!-- /.form-group --> */}

                                                                                          <div className="form-group">
                                                                                                <label htmlFor="repeatNewPassword" className="form-label">{label.repeatNewPassword}</label>
                                                                                                <input type="password" className="form-control" name={NameConstants.ConfirmPassword} id="repeatNewPassword" onChange={changeStateHandler} value={state.confirmPassword.value} onFocus={onfocusHandler}/>
                                                                                                {state.confirmPassword.error && <label className='form-label-error'>{state.confirmPassword.errorMessage}</label>}
                                                                                          </div>
                                                                                          {/* <!-- /.form-group --> */}
                                                                                         
                                                                                          <div className="form-group form-group-button">
                                                                                                <button type="button" className="btn btn-xl-lg btn-primary" onClick={resetPasswordHandler} disabled={isLoading}>
                                                                                                      {actions.changePassword}
                                                                                                </button>
                                                                                          </div>
                                                                                          {/* <!-- /.form-group --> */}
                                                                                    </form>

                                                                              </div>
                                                                              {/* <!-- .content__body --> */}
                                                                        </div>
                                                                        {/* <!-- .content --> */}

                                                                  </div>
                                                                  {/* <!-- .content__body --> */}
                                                            </div>
                                                            {/* <!-- .content.content__contact-us --> */}

                                                      </div>
                                                      {/* <!-- .col-md-12 --> */}
                                                </div>
                                                {/* <!-- /.row --> */}
                                          </div>
                                          {/* <!-- /.container  --> */}
                                    </section>
                                    {/* <!-- /.section__contact-us --> */}

                              </main>

                              <Footer />

                        </div>
                        {/* <!-- /div[data-scroll-container] --> */}
                  </React.Fragment>
      )
}

export default ResetPassword;



