import { Config } from "./../Config/index";

import { createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { isMobile, browserName, browserVersion, isTablet, isDesktop } from "react-device-detect";
import { Mutex } from 'async-mutex'
import { logout, changeCtAcessRefreshToken } from "../Store/User";
import { reset as RegisterReset } from "../Store/Register"

const mutex = new Mutex()
const baseQuery = fetchBaseQuery({
      baseUrl: Config.API_URL,
      prepareHeaders: (headers, { getState }) => {
            
            
            const data =(getState());
            var token = null;
            if (data !== undefined && data.user !== undefined && 
                  (data.user.ct_access_token !== null || data.user.ct_register_token !== null ) && 
                  (data.user.ct_access_token !== undefined || data.user.ct_register_token !== undefined)
            ) {
                  token = data.user.ct_access_token || data.user.ct_register_token;
            }
           
            // If we have a token set in state, let's assume that we should be passing it.
            if (token != null) {
                  headers.set('token', `${token}`)
            }

            // if (token != null) {
            //       headers.set("Authorization", `Bearer ${token}`);
            // }

            // detect Device and Pass to headers
            let device = ''
            if(isMobile) {
                  device = 'mobile';
            } else if (isTablet) {
                  device = 'tablet';
            } else if (isDesktop) {
                  device = 'desktop';
            }
            headers.set('client', 'web')
            headers.set('platform',device)
            headers.set('browser_info',`${browserName} ${browserVersion}`)

            return headers
      }
  })
  

const baseQueryWithInterceptor = async (args, api, extraOptions) => {
      //console.log("args: " + JSON.stringify(args));
      //console.log("api: " + JSON.stringify(api));
      //console.log("extraOptions: " + JSON.stringify(extraOptions));
      if(api.endpoint === "getUserWalletBalance" && !api.getState().user.ct_access_token){
            return;
      }
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      let result = await baseQuery(args, api, extraOptions)

      if (result.error && result.error.status === 401) {

                 // checking whether the mutex is locked
                 if (!mutex.isLocked()) {
                       const release = await mutex.acquire()
                       const data =(api.getState());
                       const refreshToken = data.user.ct_refresh_token ? data.user.ct_refresh_token : data.user.ct_access_token
                       try {
                             const refreshResult = await baseQuery(
                                   { url: '/refreshToken', method: 'POST', body : { RefreshToken : refreshToken } },
                                   api,
                                   extraOptions)
                             if (refreshResult.data) {
                                   const refresh_token = refreshResult.data.data.refresh_token
                                   const token = refreshResult.data.data.token
                                   api.dispatch(changeCtAcessRefreshToken({ ct_access_token : token, ct_refresh_token : refresh_token}))
                                   // retry the initial query
                                   result = await baseQuery(args, api, extraOptions)
                             } else {
                                   api.dispatch(logout())
                                   api.dispatch(RegisterReset())
                             }
                       } finally {
                             // release must be called once the mutex should be released again.
                             release()
                       }
                 }
                 else {
                       // wait until the mutex is available without locking it
                       await mutex.waitForUnlock()
                       result = await baseQuery(args, api, extraOptions)
                 }
      }     
      

      return parseApiResponse(result)
}

export const api = createApi({
      baseQuery: baseQueryWithInterceptor,
      // global configuration for the api
      refetchOnFocus: false,
      endpoints: () => ({}),
  })


function parseApiResponse(result) {

     
      if (result.error && result.error.status === 401) {
          // here you can deal with 401 error
      }

      // if (result.error && result.error.status === 400) {
      //       // here you can deal with 401 error
      //       console.log("Result" + JSON.stringify(result));
      // }
  
      return result;
}
    
    
    
    
    
    