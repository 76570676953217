import React from 'react'

// external css and icons
import ticketHomeBigHeading1Red from "./../../assets/images/tickets/ticket-home-big-heading1-red.svg"

import ticketHomeBigHeading1Yellow from "./../../assets/images/tickets/ticket-home-big-heading1-yellow.svg"
import ticketHomeBigHeading1YellowSm from "./../../assets/images/tickets/ticket-home-big-heading1-yellow-sm.svg"

import ticketHomeBigHeading1Green from "./../../assets/images/tickets/ticket-home-big-heading1-green.svg"
import ticketHomeBigHeading1GreenSm from "./../../assets/images/tickets/ticket-home-big-heading1-green-sm.svg"

import ticketHomeBigHeading1Aquamarine from "./../../assets/images/tickets/ticket-home-big-heading1-aquamarine.svg"
import ticketHomeBigHeading1AquamarineSm from "./../../assets/images/tickets/ticket-home-big-heading1-aquamarine-sm.svg"

import ticketHomeBigHeading1Blue from "./../../assets/images/tickets/ticket-home-big-heading1-blue.svg"
import ticketHomeBigHeading1BlueSm from "./../../assets/images/tickets/ticket-home-big-heading1-blue-sm.svg"

import ticketForMembersAquamarine from "./../../assets/images/tickets/ticket-for-members-aquamarine.svg"
import ticketForMembersAquamarineSm from "./../../assets/images/tickets/ticket-for-members-aquamarine-sm.svg"

import ticketForMembersGreen from "./../../assets/images/tickets/ticket-for-members-green.svg"
import ticketForMembersGreenSm from "./../../assets/images/tickets/ticket-for-members-green-sm.svg"

import ticketForMembersYellow from "./../../assets/images/tickets/ticket-for-members-yellow.svg"
import ticketForMembersYellowSm from "./../../assets/images/tickets/ticket-for-members-yellow-sm.svg"

import ticketForMembersBlue from "./../../assets/images/tickets/ticket-for-members-blue.svg"
import ticketForMembersBlueSm from "./../../assets/images/tickets/ticket-for-members-blue-sm.svg"

import ticketForVenuesBlueSm from "./../../assets/images/tickets/ticket-for-venues-blue-sm.svg"

import ticketForVenuesYellow from "./../../assets/images/tickets/ticket-for-venues-yellow.svg"
import ticketForVenuesYellowSm from "./../../assets/images/tickets/ticket-for-venues-yellow-sm.svg"

import ticketForVenuesAquamarine from "./../../assets/images/tickets/ticket-for-venues-aquamarine.svg"
import ticketForVenuesAquamarineSm from "./../../assets/images/tickets/ticket-for-venues-aquamarine-sm.svg"

import ticketForVenuesGreen from "./../../assets/images/tickets/ticket-for-venues-green.svg"
import ticketForVenuesGreenSm from "./../../assets/images/tickets/ticket-for-venues-green-sm.svg"

// redux and react hooks
import { Link } from 'react-router-dom'

// child component
import Footer from '../../Layouts/Footer/Footer'
import ShowsTicker from '../Home/showTicker'

// packages
import { TickerText } from '../../Components'
import { Helmet } from 'react-helmet'

// Actions & RTK query or mutations
// Constants
import { catsOneText } from '../../resources/TextRollerConstants'
import { routesName } from '../../resources/RoutesName'
import { URL } from '../../resources/Url'
import { MetaData } from '../../resources/MetaData'

const NewLookPage = () => {
      return (
            <div className="scroll-animations position-relative" data-scroll-container>
                  <Helmet>
                        <title>{MetaData.NewLook.title}</title>
                        <meta name="description" content={MetaData.NewLook.description} />
                        <meta name="keywords" content={MetaData.NewLook.keywords} />
                  </Helmet>
                  <main className="main__home main__new-look">

                        <section data-scroll-section className="section__new-look">

                              <div className="container-fluid container__big-heading">
                                    <div className="row">
                                          <div className="col-md-12">
                                                <div className="content content__new-look content__what-we-do pb-0">
                                                      <div className="content__header">
                                                            <div className="big-heading text-center">
                                                                  <h1 className="heading mb-0">We've got a new look</h1>
                                                                  <img data-scroll className="ticket ticket-red" src={ticketHomeBigHeading1Red} alt=""/>
                                                                  <picture>
                                                                        <source media="(min-width: 992px)" srcSet={ticketHomeBigHeading1Yellow}/>
                                                                        <img data-scroll className="ticket ticket-yellow" src={ticketHomeBigHeading1YellowSm} alt=""/>
                                                                  </picture>
                                                                  <picture>
                                                                        <source media="(min-width: 992px)" srcSet={ticketHomeBigHeading1Green}/>
                                                                        <img data-scroll className="ticket ticket-green" src={ticketHomeBigHeading1GreenSm} alt=""/>
                                                                  </picture>
                                                                  <picture>
                                                                        <source media="(min-width: 992px)" srcSet={ticketHomeBigHeading1Aquamarine}/>
                                                                        <img data-scroll className="ticket ticket-aquamarine" src={ticketHomeBigHeading1AquamarineSm} alt=""/>
                                                                  </picture>
                                                                  <picture>
                                                                        <source media="(min-width: 992px)" srcSet={ticketHomeBigHeading1Blue}/>
                                                                        <img data-scroll className="ticket ticket-blue" src={ticketHomeBigHeading1BlueSm} alt=""/>
                                                                  </picture>
                                                            </div>
                                                      </div>
                                                      {/* <!-- .content__header --> */}
                                                      <div className="content__body text-center">
                                                            <p className="lead">It's simple and clean with pops of colour. <br/>Our core values and commitment to social reponsibility <br/>are taking centre stage.</p>
                                                      </div>
                                                      {/* <!-- .content__body --> */}
                                                </div>
                                                {/* <!-- .content --> */}
                                          </div>
                                          {/* <!-- .col-md-12 --> */}
                                    </div>
                                    {/* <!-- /.row --> */}
                              </div>
                              {/* <!-- /.container-fluid --> */}
                        </section>
                        {/* <!-- /.section__what-we-do --> */}

                        {/* <!-- Add Slider here with event name--> */}
                        <div data-scroll-section className="border-bottom border-top">
                              <TickerText id="tickerShowCatsOne" textData={catsOneText}/>
                        </div>

                        <ShowsTicker />
                        {/* <!-- Add Slider here with event name--> */}

                        <section data-scroll-section className="section__ticket-booking-steps">
                              <div className="container-fluid container__for-members py-0">
                                    <div className="row">
                                          <div className="col-md-6">
                                                <div className="content content__for-members">
                                                      <div className="content__header">
                                                            <div className="big-heading">
                                                                  <h2 className="heading mb-0">Connecting our audiences with the causes we support</h2>
                                                            </div>
                                                      </div>
                                                      {/* <!-- .content__header --> */}
                                                      <div className="content__body">
                                                            <p>Being a force for good is Central to everything we do. Whether that be working in support NHS Charities Together, tackling climate change or the work we’re doing to help connect underserved communities with the arts. Our new look
                                                            communicates our approach to social responsibility.</p>
                                                      </div>
                                                      {/* <!-- .content__body --> */}
                                                      <div className="content__cta">
                                                            <Link className="btn btn-xl-lg btn-primary" to={routesName.AboutUs}>about us</Link>
                                                      </div>
                                                      {/* <!-- .content__cta --> */}
                                                      <picture>
                                                            <source media="(min-width: 992px)" srcSet={ticketForMembersAquamarine}/>
                                                            <img data-scroll className="ticket ticket-aquamarine" src={ticketForMembersAquamarineSm} alt=""/>
                                                      </picture>
                                                      <picture>
                                                            <source media="(min-width: 992px)" srcSet={ticketForMembersGreen}/>
                                                            <img data-scroll className="ticket ticket-green" src={ticketForMembersGreenSm} alt=""/>
                                                      </picture>
                                                      <picture>
                                                            <source media="(min-width: 992px)" srcSet={ticketForMembersYellow}/>
                                                            <img data-scroll className="ticket ticket-yellow" src={ticketForMembersYellowSm} alt=""/>
                                                      </picture>
                                                      <picture>
                                                            <source media="(min-width: 992px)" srcSet={ticketForMembersBlue}/>
                                                            <img data-scroll className="ticket ticket-blue" src={ticketForMembersBlueSm} alt=""/>
                                                      </picture>
                                                </div>
                                                {/* <!-- .content --> */}
                                          </div>
                                          {/* <!-- .col-md-6 --> */}
                                          <div className="col-md-6">
                                                <div className="content content__image">
                                                      <div className="content__body d-flex flex-column">
                                                            <img data-scroll className="image1" src={require('../../assets/images/ticket-nhs-charities-together.png')} alt=""/>
                                                            <img data-scroll className="image2" srcSet="https://ticketsformedics.co.uk/static/media/iStock-465180779.b3d21ec151780103dd56.jpg 2x" src="https://ticketsformedics.co.uk/static/media/iStock-465180779.b3d21ec151780103dd56.jpg" alt=""/>
                                                            <img data-scroll className="image3 ms-auto"
                                                            srcSet={`${URL.Image_Base_Url}iStock-new_look.jpg`}
                                                            src={`${URL.Image_Base_Url}iStock-new_look.jpg`}
                                                            alt=""/>
                                                      </div>
                                                      {/* <!-- .content__body --> */}
                                                </div>
                                                {/* <!-- .content --> */}
                                          </div>
                                          {/* <!-- .col-md-6 --> */}
                                    </div>
                                    {/* <!-- /.row --> */}
                              </div>
                              {/* <!-- /.container-fluid.container__for-members  --> */}
                        </section>

                        <section data-scroll-section className="section__what-we-do bg-red overflow-hidden">
                              <div className="container-fluid container__for-venues">
                                    <div className="row">
                                          <div className="col-md-6 order-md-2">
                                                <div className="content content__for-venues">
                                                      <div className="content__header">
                                                            <div className="big-heading">
                                                            <h2 className="heading mb-0">Easier to book tickets and manage bookings</h2>
                                                            </div>
                                                      </div>
                                                      {/* <!-- .content__header --> */}
                                                      <div className="content__body">
                                                            <p>Part of taking the decision to rebrand is a decision to address member feedback by making it easier to browse listings and filter shows. Checking out, managing bookings or account settings is far easier in this new design.</p>
                                                      </div>
                                                      {/* <!-- .content__body --> */}
                                                      <div className="content__cta">
                                                            <a className="btn btn-xl-lg btn-primary" href={()=> false}>Take a look</a>
                                                      </div>
                                                      {/* <!-- .content__cta --> */}
                                                      <img data-scroll className="ticket ticket-blue d-lg-none" src={ticketForVenuesBlueSm} alt=""/>
                                                      <picture>
                                                            <source media="(min-width: 992px)" srcSet={ticketForVenuesYellow}/>
                                                            <img data-scroll className="ticket ticket-yellow" src={ticketForVenuesYellowSm} alt=""/>
                                                      </picture>
                                                      <picture>
                                                            <source media="(min-width: 992px)" srcSet={ticketForVenuesAquamarine}/>
                                                            <img data-scroll className="ticket ticket-aquamarine" src={ticketForVenuesAquamarineSm} alt=""/>
                                                      </picture>
                                                      <picture>
                                                            <source media="(min-width: 992px)" srcSet={ticketForVenuesGreen}/>
                                                            <img data-scroll className="ticket ticket-green" src={ticketForVenuesGreenSm} alt=""/>
                                                      </picture>
                                                </div>
                                                {/* <!-- .content --> */}
                                          </div>
                                          {/* <!-- .col-md-6 --> */}
                                          <div className="col-md-6 order-md-1">
                                                <div className="content content__image">
                                                      <div className="content__body">
                                                            <img data-scroll className="image3"
                                                            srcSet={`${URL.Image_Base_Url}feature-image-two-girls-mobile-phone@2x.webp 2x`}
                                                            src={`${URL.Image_Base_Url}feature-image-two-girls-mobile-phone.webp`}
                                                            // srcSet={`${require('../../assets/images/feature-image-two-girls-mobile-phone@2x.webp')} 2x`}
                                                            // src={require('../../assets/images/feature-image-two-girls-mobile-phone.webp')}
                                                            alt=""/>
                                                      </div>
                                                      {/* <!-- .content__body --> */}
                                                </div>
                                                {/* <!-- .content --> */}
                                          </div>
                                          {/* <!-- .col-md-6 --> */}
                                    </div>
                                    {/* <!-- /.row --> */}
                              </div>
                              {/* <!-- /.container-fluid.container__for-venues  --> */}
                        </section>

                        <section data-scroll-section className="section__ticket-booking-steps">
                              <div className="container-fluid container__for-venues">
                                    <div className="row">
                                          <div className="col-md-6 order-md-2">
                                                <div className="content content__for-venues">
                                                      <div className="content__header">
                                                            <div className="big-heading">
                                                                  <h2 className="heading mb-0"> Clean, simple design with pops of color.</h2>
                                                            </div>
                                                      </div>
                                                      {/* <!-- .content__header --> */}
                                                      <div className="content__body">
                                                            <p>
                                                                  {/* Out with the old and in with the new. Our new design featuring bold typefaces, pops of colour against clean white space is a better visual representation of who we are and what we offer. it's less busy and feels lighter to navigate. */}
                                                                  Out with the old and in with the new. Our new design featuring bold typefaces, pops of colour against clean white space is a better visual representation of who we are and what we offer. it's less busy and it’s lighter to navigate.
                                                            </p>
                                                      </div>
                                                      {/* <!-- .content__body --> */}
                                                      <div className="content__cta">
                                                            <a className="btn btn-xl-lg btn-primary" href={()=> false}>Take a look</a>
                                                      </div>
                                                      {/* <!-- .content__cta --> */}
                                                      <img data-scroll className="ticket ticket-blue d-lg-none" src={ticketForVenuesBlueSm} alt=""/>
                                                      <picture>
                                                            <source media="(min-width: 992px)" srcSet={ticketForVenuesYellow}/>
                                                            <img data-scroll className="ticket ticket-yellow" src={ticketForVenuesYellowSm} alt=""/>
                                                      </picture>
                                                      <picture>
                                                            <source media="(min-width: 992px)" srcSet={ticketForVenuesAquamarine}/>
                                                            <img data-scroll className="ticket ticket-aquamarine" src={ticketForVenuesAquamarineSm} alt=""/>
                                                      </picture>
                                                      <picture>
                                                            <source media="(min-width: 992px)" srcSet={ticketForVenuesGreen}/>
                                                            <img data-scroll className="ticket ticket-green" src={ticketForVenuesGreenSm} alt=""/>
                                                      </picture>
                                                </div>
                                                {/* <!-- .content --> */}
                                          </div>
                                          {/* <!-- .col-md-6 --> */}
                                          <div className="col-md-6 order-md-1">
                                                <div className="content content__image">
                                                      <div className="content__body">
                                                            <img data-scroll className="image1"
                                                            src={require('../../assets/images/ticket-uk-wide-green.png')}  alt=""/>
                                                            <img data-scroll className="image2" srcSet="https://upload.centraltickets.co/rebranding/assets/images/stock-image-08.jpg 2x" src="https://upload.centraltickets.co/rebranding/assets/images/stock-image-08.jpg" alt=""/>
                                                            <img data-scroll className="image3" srcSet="https://ticketsformedics.co.uk/static/media/stock-image-05@2x.693a36f48cc26725dfdc.jpg 2x" src="https://ticketsformedics.co.uk/static/media/stock-image-05@2x.693a36f48cc26725dfdc.jpg" alt=""/>
                                                      </div>
                                                      {/* <!-- .content__body --> */}
                                                </div>
                                                {/* <!-- .content --> */}
                                          </div>
                                          {/* <!-- .col-md-6 --> */}
                                    </div>
                                    {/* <!-- /.row --> */}
                              </div>
                              {/* <!-- /.container-fluid.container__for-venues  --> */}
                        </section>

                        <section data-scroll-section="" className="section__faq">
                              <div className="container-fluid container__big-heading">
                                    <div className="row">
                                          <div className="col-md-12">
                                                <div className="content content__questions">
                                                      <div className="content__header ">
                                                            <div className="big-heading text-center">
                                                                  <h2 className="h1 mb-0 responsive_question">Questions?</h2>
                                                                  <img data-scroll className="ticket ticket-red" src={ticketHomeBigHeading1Red} alt=""/>
                                                                  <picture>
                                                                        <source media="(min-width: 992px)" srcSet={ticketHomeBigHeading1Yellow}/>
                                                                        <img data-scroll className="ticket ticket-yellow" src={ticketHomeBigHeading1YellowSm} alt=""/>
                                                                  </picture>
                                                                  <picture>
                                                                        <source media="(min-width: 992px)" srcSet={ticketHomeBigHeading1Green}/>
                                                                        <img data-scroll className="ticket ticket-green" src={ticketHomeBigHeading1GreenSm} alt=""/>
                                                                  </picture>
                                                                  <picture>
                                                                        <source media="(min-width: 992px)" srcSet={ticketHomeBigHeading1Aquamarine}/>
                                                                        <img data-scroll className="ticket ticket-aquamarine" src={ticketHomeBigHeading1AquamarineSm} alt=""/>
                                                                  </picture>
                                                                  <picture>
                                                                        <source media="(min-width: 992px)" srcSet={ticketHomeBigHeading1Blue}/>
                                                                        <img data-scroll className="ticket ticket-blue" src={ticketHomeBigHeading1BlueSm} alt=""/>
                                                                  </picture>
                                                            </div>
                                                      </div>
                                                      {/* <!-- .content__header --> */}
                                                </div>
                                                {/* <!-- .content --> */}
                                          </div>
                                          {/* <!-- .col-md-12 --> */}
                                    </div>
                                    {/* <!-- /.row --> */}
                              </div>
                              {/* <!-- /.container-fluid --> */}
                              <div className="container-fluid container__block">
                                    <div className="row">
                                          <div className="col-md-12">
                                                <div className="content content__faq mx-auto">

                                                      <div className="content__body">

                                                            <div className="accordion accordion-line-separated" id="accordionFAQ">

                                                                  {/* <!-- item 1 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading1">
                                                                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse1" aria-expanded="false" aria-controls="faqCollapse1">
                                                                              <span className="btn__text">I liked the way it was, why you have changed it?</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse1" className="accordion-collapse collapse" aria-labelledby="faqHeading1" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                              <p>The old look didn’t do a great job of communicating the good work we do. It was outdated and clunky. It’s time for a change that better reflects our company culture and our focus on continual innovation.</p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                                  {/* <!-- /.accordion-item --> */}

                                                                  {/* <!-- item 2 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading2">
                                                                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse2" aria-expanded="false" aria-controls="faqCollapse2">
                                                                              <span className="btn__text">What’s changed?</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse2" className="accordion-collapse collapse" aria-labelledby="faqHeading2" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                              <p>We’ve updated the way we look, feel and sound by making sure the tone of voice accurately reflects our core values and what we set out to achieve. We’ve also made the platform easier to navigate.</p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                                  {/* <!-- /.accordion-item --> */}
                                                                  {/* <!-- item 3 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading3">
                                                                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse3" aria-expanded="false" aria-controls="faqCollapse3">
                                                                              <span className="btn__text">Are there changes to the service?</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse3" className="accordion-collapse collapse" aria-labelledby="faqHeading3" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                              <p>The service hasn’t changed, but you’ll find it easier to move around and book tickets.</p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                                  {/* <!-- /.accordion-item --> */}
                                                                  {/* <!-- item 4 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading4">
                                                                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse4" aria-expanded="false" aria-controls="faqCollapse4">
                                                                              <span className="btn__text">Where did the inspiration come from?</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse4" className="accordion-collapse collapse" aria-labelledby="faqHeading4" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                              <p>
                                                                                    {/* We wanted something that is clean, bold and fun that gives our members a better experience and gives us greater flexibility to make design changes. */}
                                                                                    We wanted something that is clean, bold and fun that gives our members a better experience.  We also wanted something that gives us greater flexibility to make design changes.  The image of red theatre chairs was quite restrictive and difficult to place text around.
                                                                              </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                                  {/* <!-- /.accordion-item --> */}

                                                                  {/* <!-- item 5 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading5">
                                                                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse5" aria-expanded="false" aria-controls="faqCollapse5">
                                                                              <span className="btn__text">Can I have the old look back?</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse5" className="accordion-collapse collapse" aria-labelledby="faqHeading5" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                              <p>There’s no going back. It was great whilst it lasted, but it’s time to move on.</p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                                  {/* <!-- /.accordion-item --> */}

                                                                  {/* <!-- item 6 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading6">
                                                                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse6" aria-expanded="false" aria-controls="faqCollapse6">
                                                                              <span className="btn__text">I’ve spotted something from your previous look</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse6" className="accordion-collapse collapse" aria-labelledby="faqHeading6" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                              <p>Oops. Please <a href="https://upload.centraltickets.co/rebranding/contact-us-4.html">contact us</a> to let us know so we can get that changed on the double.</p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                                  {/* <!-- /.accordion-item --> */}

                                                            </div>
                                                            {/* <!-- .accordion --> */}

                                                      </div>
                                                      {/* <!-- .content__body --> */}
                                                </div>
                                                {/* <!-- .content.content__faq --> */}

                                          </div>
                                          {/* <!-- .col-md-12 --> */}
                                    </div>
                                    {/* <!-- /.row --> */}
                              </div>
                              {/* <!-- /.container-fluid  --> */}
                        </section>

                  </main>

                  <Footer />
            </div>
      )
}

export default NewLookPage
