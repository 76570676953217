import React, { useEffect } from 'react'

// external css

// small class
import ticketFlexiGreen1 from "./../../../assets/images/tickets/ticket-uptake-venues-green1.svg";

import ticketFlexiRed from "./../../../assets/images/tickets/ticket-faq-big-heading1-red.svg";
import ticketFlexiAquamarineSm from "./../../../assets/images/tickets/ticket-uptake-venues-aquamarine-sm.svg";

import ticketFlexiOrange1 from "./../../../assets/images/tickets/ticket-faq-big-heading1-orange.svg";
import ticketFlexiBlue1 from "./../../../assets/images/tickets/ticket-my-bookings-big-heading1-blue.svg";

// redux and react hooks
// child component
// packages
// Actions & RTK query or mutations

// Constants
import { URL } from '../../../resources/Url';

const GiftVoucherTerms = ({termsHandler}) => {


      useEffect(()=>{
            // getFlexi();
      },[]);

      return (

                  <React.Fragment>
                        <div className="modal modal__fw modal__terms-and-conditions modal__gift-voucher-terms slideInDown show" id="modalFlexiTermsAndConditions" data-bs-backdrop="static" tabIndex="-1" aria-hidden="false">
                              <div className="modal-dialog">
                                    <div className="modal-content">
                                          <div className="modal-body">
                                                <button type="button" className="btn-close btn-close__custom btn-close__absolute" 
                                                // data-bs-dismiss="modal" aria-label="Close" 
                                                onClick={termsHandler}>
                                                </button>
                                                <div className="content d-flex flex-column flex-xl-row"  data-scroll-container>
                                                      {/* <!-- /.content__body --> */}
                                                      {/* Added class  (content-wallet-terms-modal)*/}
                                                      <div className="content__body content-wallet-terms-modal">
                                                            <h2 className="heading" style={{"textAlign":"center"}}>Gift Vouchers Terms <br />and Conditions</h2>
                                                            {/* Added pictures  */}
                                                            <picture>
                                                                  <img data-scroll className="ticket ticket-blue" src={ticketFlexiBlue1} alt=""/>
                                                            </picture>
                                                            <picture>
                                                                  <img data-scroll className="ticket ticket-green1" src={ticketFlexiGreen1} alt=""/>
                                                            </picture>
                                                            <picture> 
                                                                  <img data-scroll className="ticket ticket-AquamarineSm" src={ticketFlexiAquamarineSm} alt=""/>
                                                            </picture>
                                                            <picture>
                                                                  <img data-scroll="" className="ticket ticket-red d-lg-block" src={ticketFlexiRed} alt=""/>
                                                            </picture>
                                                            <picture>
                                                                  <img data-scroll="" className="ticket ticket-orange1 d-lg-block" src={ticketFlexiOrange1} alt=""/>
                                                            </picture>
                                                            {/* Added pictures  */}
                                                            {/* {data && <div dangerouslySetInnerHTML={{ __html: data.data }}></div> } */}
                                                            <div>
                                                            <div className="terms_web">
   
                                                                  <ol style={{ listStyle :"none"}}>
                                                                  <li>
                                                                        <ol>
                                                                              <li>
                                                                                    <span>
                                                                                          <span>
                                                                                                <span>
                                                                                                      Central Tickets Limited offers a gift voucher credit system that enables members to gift wallet credit to friends and family. Members have the option to purchase gift voucher credits for fellow members or non-members, which are then stored within the recipient's designated credit wallet. However, once the credit is issued, refunds are not available except in instances where a gift voucher credit remains unclaimed by a non-member. In such cases, the member who purchased the gift can request the full amount to be returned to their own credit wallet within their Central Tickets account.
                                                                                                      <br />
                                                                                                      <br />
                                                                                                </span>
                                                                                          </span>
                                                                                    </span>
                                                                              </li>
                                                                              <li>
                                                                                    <span>
                                                                                          <span>
                                                                                                <span>
                                                                                                      Gift voucher credits can be purchased using a credit/debit card or from an existing wallet credit balance. All purchased gift credits are deposited into the recipient’s credit wallet on the specified date chosen by the gifting member. If the recipient is not a Central Tickets member, they will receive the gifting member’s personalised message, details of the gift, and an invitation to register for a free Central Tickets account to access the gift voucher credit. When gifting, please provide the full name and email address of the recipient, as well as the delivery date for the gift voucher and a personalised message. We use this information solely for the purpose of delivering your gift, and we will confirm the successful delivery via email to the member who provided the gift.
                                                                                                      <br />
                                                                                                      <br />
                                                                                                </span>
                                                                                          </span>
                                                                                    </span>
                                                                              </li>
                                                                              <li>
                                                                                    <span>
                                                                                          <span>
                                                                                                <span>
                                                                                                      Gift voucher credits are applicable towards the cost of tickets for any events listed under the 
                                                                                                      <a href={`${URL.SITE_BASE_URL}current-listings`} target="_blank" rel='noreferrer'>“Shows”</a> tab that are directly managed by Central Tickets. It is important to note that gift voucher credits cannot be used to purchase tickets for events fulfilled by third-party companies partnered with us. For clarity, gift credits are valid for any shows directly managed by Central Tickets but are not applicable for <a href="https://centraltickets.tixculture.com/" target="_blank" rel='noreferrer'>TTG Encore bookings</a> or those made via <a href="https://centraltickets.ticketswitch.com/" target="_blank" rel='noreferrer'>Ingresso</a>.
                                                                                                      <br />
                                                                                                      <br />
                                                                                                </span>
                                                                                          </span>
                                                                                    </span>
                                                                              </li>
                                                                              <li>
                                                                                    <span>
                                                                                          <span>
                                                                                                <span>
                                                                                                      *We have included links to assist in visually distinguishing between the shows directly managed by Central Tickets and those by third-party sites that are excluded from Gift Credit Vouchers.
                                                                                                      <br />
                                                                                                      <br />
                                                                                                </span>
                                                                                          </span>
                                                                                    </span>
                                                                              </li>
                                                                              <li>
                                                                                    <span>
                                                                                          <span>
                                                                                                <span>
                                                                                                      Please be aware that the promotional offer of a 10% wallet credit bonus on gifts exceeding £50 is exclusively available for new transactions made with a credit or debit card (wallet credit is excluded from the offer). This promotional offer cannot be used in conjunction with other offers, such as the 10% extra credit for adding funds to your own wallet at Central Tickets. Members can track their history of gifts given through the wallet function within their Central Tickets account.
                                                                                                      <br />
                                                                                                      <br />
                                                                                                </span>
                                                                                          </span>
                                                                                    </span>
                                                                              </li>


                                                                        </ol>
                                                                  </li>
                                                                  </ol>
                                                                  <p className="spacing_manage" style={{"marginLeft": "42.55pt"}}>&nbsp;</p>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                      {/* <!-- /.content__body --> */}
                                                </div>
                                                {/* <!-- /.content --> */}

                                          </div>
                                          {/* <!-- /.modal-body --> */}
                                    </div>
                                    {/* <!-- /.modal-content --> */}
                              </div>
                              {/* <!-- /.modal-dialog --> */}
                        </div>
                        {/* <!-- modal #modalTermsAndConditions --> */}
                        <div className="modal-backdrop fade show" style={{"top": "91px"}}></div>
                  </React.Fragment>
      )
}

export default GiftVoucherTerms;
