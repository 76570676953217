import React from 'react'

// external css
// redux and react hooks
// child component

// packages
import { LazyLoadImage } from "react-lazy-load-image-component";

// Actions & RTK query or mutations
// Constants


const Step1 = ({ stepChange, afterLoadHandler }) => {


      return (
            <>
                  <div className="card__event-image">
                        <LazyLoadImage className="event-image w-200" style={{ maxWidth: "400px" }}
                        srcSet="https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/working_in_partnership.jpg 2x"
                        src="https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/working_in_partnership.jpg"
                        afterLoad={afterLoadHandler} />
                  </div>
                  <div className="card-body p-0">
                        <div className="form-group">
                              <h3 className="form-group__heading">
                                    Can we ask you to rate Central Tickets out of 10?
                              </h3>
                              <p>takes 15 seconds</p>
                        </div>

                        <div className="list__btn-checks flex-column flex-md-row justify-content-center mx-auto">
                              <div className="btn-check-wrapper mb-0">
                                    {/* <input
                                          className="form-check-input btn-check"
                                          type="radio"
                                          name="radioPostEventDiscuss"
                                          id="radioPostEventDiscuss2"
                                    /> */}
                                    <button className="btn btn-xl-lg btn-primary" onClick={()=>stepChange('step2')}>
                                          Start
                                    </button>
                              </div>
                        </div>
                  </div>  
            </>
      )
}

export default Step1
