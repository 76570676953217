export const faq =  build =>
      build.query({
            query: (body) =>  ({
                  url: 'contents?type=faq',
                  method: 'GET',
                  body: body
            }),
      })


      //changed as per new