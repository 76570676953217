import React, { useState, useEffect } from 'react'

// external css
// redux and react hooks
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// child component
import Footer from '../../Layouts/Footer/Footer';

// packages
import { Helmet } from 'react-helmet';

// Actions & RTK query or mutations
import { useSubmitAccessNeedsMutation } from '../../Services/modules/AccessNeeds';
import { setAlert } from '../../Store/UI';
import { accessNeedsValidationRules } from "./../../Services";

// Constants
import { routesName } from '../../resources/RoutesName';
import { actions, errors, title, placeholders, messages } from '../../resources/en';
import { NameConstants } from '../../resources/NameConstants';
import { MetaData } from '../../resources/MetaData';

const AccessNeeds = () => {

      const [state,setState]= useState({
            [NameConstants.Name] : {value:"",error:false,errorMessage:""},
            [NameConstants.Email]:{value:"",error:false,errorMessage:""},
            [NameConstants.AccessNeedMessage]:{value:"",error:false,errorMessage:""},
      })

      const user        =     useSelector(state => state.user)
      const dispatch    =     useDispatch();
      const navigate    =     useNavigate();

      const [submitAccessNeeds, { data, isSuccess, error, isLoading  }] = useSubmitAccessNeedsMutation();

      useEffect(()=>{
            document.title = "Access Needs";
      },[])

      useEffect(()=>{

            setState(prevState=>{
                  return {
                        ...prevState,
                        [NameConstants.Name]:{...prevState[NameConstants.Name],value : user.ct_access_name},
                        [NameConstants.Email]:{...prevState[NameConstants.Email],value : user.ct_access_email},
                  }
            })

      },[user.ct_access_name, user.ct_access_email])

      useEffect(() => {

            if(isSuccess){
                  dispatch(setAlert({model:true,title:messages.modalSuccessTitle,message:data.error || data.errors || data.message || errors.NotFound404}));
                  navigate(routesName.CurrentListing);
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}));
            }

      }, [ data, isSuccess, error, isLoading, dispatch, navigate ])

      const changeStateHandler = (event)=>{
            event.preventDefault();
            const key = event.target.name;
            const value = event.target.value;
            setState(prevState=>{
                  return {
                        ...prevState,
                        [key]:{...prevState[key], value:value}
                  }
            })
      }

      const onfocusHandler = ()=>{
            setState(prevState=>{

                  return {
                        ...prevState,
                        [NameConstants.Name] : {...prevState[NameConstants.Name],error:false,errorMessage:""},
                        [NameConstants.Email]:{...prevState[NameConstants.Email],error:false,errorMessage:""},
                        [NameConstants.AccessNeedMessage]:{...prevState[NameConstants.AccessNeedMessage],error:false,errorMessage:""},
                  }
            })
      }

      const submitAccessNeedsHandler = (event)=>{
            event.preventDefault();
            const copy  = {
                  ...state
            }
            const errorStore = [];

            for(let key in state) {
                  const errorCheck = accessNeedsValidationRules(state[key].value,key);
                  if(!errorCheck.isValid){
                        copy[key] = {...copy[key],error:true,errorMessage:errorCheck.validationMessage}
                        errorStore.push(key);
                  }
            }

            if(errorStore.length > 0) {
                  setState(prevState=>{
                        return {
                              ...prevState,
                              ...copy
                        }
                  })

            } else {
                  submitAccessNeeds({access_needs : state[NameConstants.AccessNeedMessage].value})
            }
      }
      
      return (
                  <React.Fragment>
                        {/* <!-- REQUIRED wrapper div for parallax --> */}
                        <div className={["scroll-animations position-relative"].join(" ")} data-scroll-container>
                              <Helmet>
                                    <title>{MetaData.AccessBooking.title}</title>
                                    <meta name="description" content={MetaData.AccessBooking.description} />
                                    <meta name="keywords" content={MetaData.AccessBooking.keywords} />
                              </Helmet>
                              <main className="main__contact-us access-needs">      

                                    <section data-scroll-section className="section__contact-us py-0">
                                        
                                          <div className="container-fluid container__block">
                                                <div className="row">
                                                      <div className="col-md-12">

                                                            <div className="content content__contact-us mx-auto">
                                                                  <div className="content__header content__header--template border-bottom text-center overflow-hidden">
                                                                        <div className="big-heading">
                                                                              <h1 className="h2 mb-0">{title.AccessNeeds}</h1>
                                                                        </div>
                                                                  </div>
                                                                  {/* <!-- .content__header --> */}
                                                            </div>

                                                      </div>
                                                      {/* <!-- .col-md-12 --> */}
                                                </div>
                                                {/* <!-- /.row --> */}
                                          </div>
                                          {/* <!-- /.container-fluid  --> */}

                                          <div className="container">
                                                <div className="row">
                                                      <div className="col-md-12">
                                                           
                                                            <div className="content content-single-col content__contact-us mx-auto">
                                                                  <div className="content__body">
                                                                        <div className='access-needs-message'>
                                                                              <p>Please tell us about your access requirements. We'll then store these so that each time you make a booking our admin team are alerted to make the necessary arrangements for you.</p>
                                                                        </div>
                                                                       
                                                                        <div className="content content__contact-form">
                                                                              <div className="content__body content__change-password">
                                                                                    <form>

                                                                                          <div className="form-group">
                                                                                                <input type="text" className="form-control" name={NameConstants.Name} id="name" onChange={changeStateHandler} value={state[NameConstants.Name].value} onFocus={onfocusHandler}
                                                                                                placeholder={placeholders[NameConstants.Name]} disabled/>
                                                                                                {state[NameConstants.Name].error &&<label className='form-label-error'>{state[NameConstants.Name].errorMessage}</label>}
                                                                                          </div>

                                                                                          <div className="form-group">
                                                                                                <input type="email" className="form-control" name={NameConstants.Email} id="name" onChange={changeStateHandler} value={state[NameConstants.Email].value} onFocus={onfocusHandler} placeholder={placeholders[NameConstants.Email]} disabled/>
                                                                                                {state[NameConstants.Email].error &&<label className='form-label-error'>{state[NameConstants.Email].errorMessage}</label>}
                                                                                          </div>

                                                                                          <div className="form-group">
                                                                                                <textarea className="form-control" 
                                                                                                      name={NameConstants.AccessNeedMessage}
                                                                                                      placeholder="Enter your Access Needs*"
                                                                                                      value={state[NameConstants.AccessNeedMessage].value}
                                                                                                      onChange={changeStateHandler}
                                                                                                      onFocus={onfocusHandler}
                                                                                                      disabled={isLoading}
                                                                                                      >
                                                                                                </textarea>
                                                                                                {state[NameConstants.AccessNeedMessage].error &&<label className='form-label-error'>{state[NameConstants.AccessNeedMessage].errorMessage}</label>}
                                                                                          </div>
                                                                                         
                                                                                          <div className="form-group form-group-button">
                                                                                                <button type="button" className="btn btn-xl-lg btn-primary" onClick={submitAccessNeedsHandler} disabled={isLoading}
                                                                                                >
                                                                                                      {actions.submit}
                                                                                                </button>
                                                                                          </div>
                                                                                          {/* <!-- /.form-group --> */}
                                                                                    </form>

                                                                              </div>
                                                                              {/* <!-- .content__body --> */}
                                                                        </div>
                                                                        {/* <!-- .content --> */}

                                                                  </div>
                                                                  {/* <!-- .content__body --> */}
                                                            </div>
                                                            {/* <!-- .content.content__contact-us --> */}

                                                      </div>
                                                      {/* <!-- .col-md-12 --> */}
                                                </div>
                                                {/* <!-- /.row --> */}
                                          </div>
                                          {/* <!-- /.container  --> */}
                                    </section>
                                    {/* <!-- /.section__contact-us --> */}

                              </main>

                              <Footer />

                        </div>
                        {/* <!-- /div[data-scroll-container] --> */}
                  </React.Fragment>
      )
}

export default AccessNeeds;



